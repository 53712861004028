import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import en_US from '@uppy/locales/lib/en_US';
import es_ES from '@uppy/locales/lib/es_ES';

import { initializeUppy, resetUppy } from '../helpers/uppy';

const DEFAULT_OPTIONS = {};

export function useUppy({ uploadPath, onUploaded, onAdded, options = DEFAULT_OPTIONS, unique = false }) {
  const { i18n } = useTranslation();

  const [uppy, setUppy] = useState(null);

  if (!uploadPath) throw new Error('uploadPath is required');

  const uppyLocale = getUppyLocale(i18n);

  useEffect(() => {
    setUppy(
      initializeUppy(uploadPath, onUploaded, onAdded, unique, {
        ...options,
        locale: uppyLocale,
      })
    );
    return () => {
      resetUppy(uppy);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadPath, onUploaded, uppyLocale, options]);

  return uppy;
}
export function getUppyLocale(i18n) {
  const language = i18n?.resolvedLanguage ?? i18n?.language ?? 'en';
  if (language === 'es') return es_ES;
  else return en_US;
}
