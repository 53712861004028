import { Button } from '@wonderschool/common-base-ui';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Card, Icon, Image, Modal, Segment } from 'semantic-ui-react';

// Import asset
import wsLogo from '../../styles/img/wonderschool/logo-base.png';

const ResponsiveQRCodeSVG = (args) => (
  <>
    <QRCodeSVG {...args} size={256} className="sm:hidden" />
    <QRCodeSVG {...args} size={512} className="hidden sm:inline-block" />
  </>
);

const OrganizationQRCode = ({ currentOrganization: { id } }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // When the modal opens, wait for a
    // second and then trigger a browser window.print().
    if (open) {
      setTimeout(function () {
        window?.print();
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (window) {
      window.onafterprint = () => {
        setOpen(false);
      };
    }
  }, []);
  return (
    <Modal
      className="qr-code"
      size="small"
      centered={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeOnDimmerClick={true}
      trigger={
        <Button primary preIcon={<Icon name="qrcode" />}>
          {t('View / print QR code')}
        </Button>
      }
    >
      <Modal.Content>
        <Card centered fluid>
          <Segment basic textAlign="center">
            <ResponsiveQRCodeSVG
              value={id}
              fgColor="#28325A"
              marginSize={4}
              // TODO: Adding a logo in the middle of the QR code will be cool in the future
              // imageSettings={{
              //   src: wsLogo,
              //   excavate: true,
              //   height: 100,
              //   width: 100,
              // }}
            />
          </Segment>
          <Card.Content style={{ background: '#28325A' }}>
            {/*  eslint-disable-next-line i18next/no-literal-string */}
            <Card.Header textAlign="center" style={{ fontSize: '2em', color: '#fff' }}>
              Scan me to check-in and check-out
            </Card.Header>
          </Card.Content>

          <Card.Content extra textAlign="center">
            <Image src={wsLogo} size="small" centered />
          </Card.Content>
        </Card>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps, null)(OrganizationQRCode);
