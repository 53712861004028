import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Popup } from 'semantic-ui-react';

const EnrollmentsTooltip: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Popup
      content={
        <ul>
          <li>{t('onboarding.tooltipEnrollmentsWebsite')}</li>
          <li>{t('onboarding.tooltipEnrollmentsFamilies')}</li>
          <li>{t('onboarding.tooltipEnrollmentsTours')}</li>
        </ul>
      }
      trigger={<div>{children}</div>}
      on="hover"
      wide="very"
    />
  );
};

const FamiliesTooltip: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Popup
      content={
        <ul>
          <li>{t('onboarding.tooltipFamiliesActivities')}</li>
          <li>{t('onboarding.tooltipFamiliesPhotos')}</li>
          <li>{t('onboarding.tooltipFamiliesMessage')}</li>
        </ul>
      }
      trigger={<div>{children}</div>}
      on="hover"
      wide="very"
    />
  );
};

const PaymentsTooltip: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Popup
      content={
        <ul>
          <li>{t('onboarding.tooltipPaymentsCharge')}</li>
          <li>{t('onboarding.tooltipPaymentsGetPaid')}</li>
          <li>{t('onboarding.tooltipPaymentsSetup')}</li>
        </ul>
      }
      trigger={<div>{children}</div>}
      on="hover"
      wide="very"
    />
  );
};

const OperationsTooltip: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Popup
      content={
        <ul>
          <li>{t('onboarding.tooltipOperationsManage')}</li>
          <li>{t('onboarding.tooltipOperationsAttendance')}</li>
        </ul>
      }
      trigger={<div>{children}</div>}
      on="hover"
      wide="very"
    />
  );
};

export { EnrollmentsTooltip, FamiliesTooltip, OperationsTooltip, PaymentsTooltip };
