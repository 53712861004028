import classnames from 'classnames/bind';
import { useCallback } from 'react';
import AppStoreLinksContainer from '../Components/Dashboard/AppStoreLinksContainer';
import DashboardHeader from '../Components/Dashboard/DashboardHeader';
import Invitations from '../Components/Dashboard/Invitations';
import RecentPhotos from '../Components/Dashboard/RecentPhotos';
import StatSummary from '../Components/Dashboard/StatSummary';
import TodaysActivityFeed from '../Components/Dashboard/TodaysActivityFeed';
import { OnboardingBanner } from '../Components/Onboarding';
import { useOnboardingBannerState } from '../Components/Onboarding/onboardingHooks';
import PageHeader from '../Components/Shared/PageHeader';
import { useOrganization, useOrganizationContactsListener } from '../hooks/useOrganizations';
import { useUser } from '../hooks/useUser';
import { PAGE_NAMES, useSegmentPage } from '../segment';
import StudentsGalleryParent from '../students/components/gallery/StudentsGalleryParent';

import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './Dashboard.module.scss';

const cx = classnames.bind(styles);

export default function Dashboard() {
  const FLORIDA_ID = process.env.REACT_APP_FLORIDA_NETWORK_ID;
  const FLORIDA_URL = process.env.REACT_APP_FLORIDA_SCHOOLS_LOGIN_URL;
  const organization = useOrganization();
  useOrganizationContactsListener(organization.id);

  useSegmentPage(PAGE_NAMES.dashboard);

  const { isParent } = useUser();
  const { isFloridaNetworkEnabled } = useFlags();

  const { collapseOnboardingBanner, expandOnboardingBanner, isCollapsed } = useOnboardingBannerState();

  const onClickUpDown = useCallback(
    (_isCollapsed) => {
      _isCollapsed ? collapseOnboardingBanner() : expandOnboardingBanner();
    },
    [collapseOnboardingBanner, expandOnboardingBanner]
  );

  if (isFloridaNetworkEnabled && organization?.networks?.length === 1 && organization?.networks?.includes(FLORIDA_ID)) {
    window.location.href = FLORIDA_URL!;
  }

  return (
    <div className={cx({ withBackground: isParent })}>
      <PageHeader pageName={'Dashboard'} classes="dashboard" />
      <DashboardHeader />
      <div className="px-4 py-4">
        <div className="container mx-auto">
          <div className="flex flex-col gap-y-6">
            <OnboardingBanner collapsed={isCollapsed} onClickUpDown={onClickUpDown} />
            {isParent && (
              <>
                <AppStoreLinksContainer />
                <div className="w-full">
                  <StudentsGalleryParent />
                </div>
              </>
            )}
            <StatSummary />
            <Invitations />
            <RecentPhotos />
            <TodaysActivityFeed />
          </div>
        </div>
      </div>
    </div>
  );
}
