// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFinanceAtGlance } from '../../../hooks/useFinanceAtGlance';
import { selectFirstLocation } from '../../DateTime/DateTime';
import FinanceAtGlanceHeader from './FinanceAtGlanceHeader';
import InvoiceSection from './InvoiceSection';

function InvoicesAtGlance() {
  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';
  const [selectedMonth, setSelectedMonth] = useState(moment().tz(timezone, true));
  const [isRefreshToggle, setIsRefreshToggle] = useState(false);
  const refreshHandler = () => {
    setIsRefreshToggle(!isRefreshToggle);
  };
  const { isLoadingInvoices, invoicesData } = useFinanceAtGlance(selectedMonth, 'invoices', isRefreshToggle);
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col gap-3 rounded-md border border-gray-300 px-4 py-2">
      <FinanceAtGlanceHeader
        title={t('financeAtGlanceInvoicesHeader')}
        selectedMonth={setSelectedMonth}
        refreshHandler={refreshHandler}
        isLoading={isLoadingInvoices}
      />
      <InvoiceSection
        upcoming={invoicesData.statusUpcoming}
        processing={invoicesData.statusProcessing}
        transferring={invoicesData.statusTransferring}
        depositedPaid={invoicesData.statusDepositedOrPaid}
        pastDue={invoicesData.statusPastDue}
        failed={invoicesData.statusFailed}
      />
    </div>
  );
}

export default InvoicesAtGlance;
