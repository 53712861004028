import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Input as SemanticInput } from 'semantic-ui-react';

import useGoogleMapsApi from '../../../hooks/useGoogleMapsApi';

import { formatLocationPlaceObject } from '../../../helpers/utils';

const LocationInput = ({ onLocationSelected, label, name, value = '', onChange, innerLabel, required, ...rest }) => {
  const inputRef = useRef();
  const autocompleteRef = useRef();
  const googleMapsApi = useGoogleMapsApi();
  const { t } = useTranslation();

  useEffect(() => {
    if (googleMapsApi) {
      autocompleteRef.current = new googleMapsApi.places.Autocomplete(inputRef.current, {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components', 'geometry', 'utc_offset_minutes'],
        types: ['address'],
      });

      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        // if a place is found, reshape it and return it
        if (onLocationSelected && place?.address_components) {
          formatLocationPlaceObject(place).then((reshaped) => onLocationSelected(reshaped));
        }
      });

      return () => {
        if (googleMapsApi) {
          googleMapsApi.event?.clearListeners(autocompleteRef.current, 'place_changed');
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMapsApi]);

  return (
    <div className={required && `required field`}>
      {label && <label>{label}</label>}
      <SemanticInput
        {...rest}
        // override the name, id, and placeholder attributes
        // to ensure chrome actually applies autoComplete=off
        id={'address-1'}
        name={'address-1'}
        placeholder={t('Type a Location')}
        value={value}
        onChange={(e, x) => onChange(e, { ...x, name: name })}
      >
        <input
          ref={inputRef}
          autoComplete="off"
          // prevent submit on enter
          onKeyDown={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        />
      </SemanticInput>
    </div>
  );
};

export default LocationInput;
