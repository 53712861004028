import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Container, Segment } from 'semantic-ui-react';
import { OnboardingBanner } from '../Components/Onboarding';

import { getTitlePrefix } from '../config/env';
import { PAGE_NAMES, useSegmentPage } from '../segment';

const Welcome = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.welcome);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Welcome') })}
      </title>
      <body className="dashboard" />
    </Helmet>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        <OnboardingBanner collapsed={false} />
      </Segment>
    </Container>
  );
};
export default Welcome;
