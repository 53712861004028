import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  FileCategory,
  FileDocument,
  MalwareStatus,
  OrganizationProgramTypeEnum,
  USStateCodeEnum,
} from '@wonderschool/common-base-types';
import { MainContentLayout, PageLoading, ToastTypeEnum, WidgetSizeEnum, showToast } from '@wonderschool/common-base-ui';
import {
  useChangeFileState,
  useDownloadFiles,
  useGetAdminFiles,
  useUploadAdminFile,
} from '@wonderschool/file-service-client';

import { getTitlePrefix } from '../../../config/env';
import { logError } from '../../../rollbar';
import PageTitle from '../../Shared/PageTitle';
import { AdminDocumentsFilters } from './AdminDocumentsFilters';
import { AdminDocument, AdminDocumentsTable } from './AdminDocumentsTable';
import { AdminDocumentFormData, UploadAdminDocument } from './UploadAdminDocument';

// TODO: FileStatus should be exported in common-base-types or replaced with the existing MalwareStatus from there

export const AdminDocuments: React.FC = () => {
  const { t } = useTranslation();
  const [statesFilter, setStatesFilter] = useState<USStateCodeEnum[]>([]);
  const [programTypesFilter, setProgramTypesFilter] = useState<OrganizationProgramTypeEnum[]>([]);

  const {
    data,
    refetch: refetchAdminFiles,
    isLoading: isLoadingAdminFiles,
  } = useGetAdminFiles({
    states: statesFilter,
    programTypes: programTypesFilter,
  });
  const { uploadFile, reset: resetUploadHook } = useUploadAdminFile();

  // TODO: show download error [CCMS-5901]
  const { downloadFiles } = useDownloadFiles();

  const { deleteFile: deleteFileFn } = useChangeFileState();

  const adminDocumentsList: AdminDocument[] = useMemo(() => {
    const docs = data?.pages[0]?.documents || [];
    return docs.map((doc) => ({
      id: doc.id,
      name: doc.name,
      fileCategory: doc.fileCategory as FileCategory,
      fileStatus: doc.fileStatus as MalwareStatus,
      updatedAt: doc.updatedAt,
      updatedBy: doc.updatedBy,
      states:
        doc.tags?.filter((tag) => tag.startsWith('state:')).map((tag) => tag.split(':')[1] as USStateCodeEnum) || [],
      programTypes:
        doc.tags
          ?.filter((tag) => tag.startsWith('programType:'))
          .map((tag) => tag.split(':')[1] as OrganizationProgramTypeEnum) || [],
    }));
  }, [data]);

  const onFileDownload = (id: FileDocument['id']) => {
    downloadFiles([id]);
  };

  const deleteFile = (id: FileDocument['id']) => {
    deleteFileFn(id)
      .then(() => {
        showToast(ToastTypeEnum.Success, t('admin.wonderschoolDocuments.deletedSuccess'));
        refetchAdminFiles();
      })
      .catch((error) => {
        let errorMessage = t('admin.wonderschoolDocuments.deletedError');
        if (error.status === 403) {
          errorMessage = t('admin.wonderschoolDocuments.deletedErrorForbidden');
        }
        showToast(ToastTypeEnum.Error, errorMessage);
        logError('Admin Documents - Delete error: ', {
          clientError: error,
        });
      });
  };

  const onStateChange = (states: USStateCodeEnum[]) => {
    setStatesFilter(states);
  };

  const onProgramTypeChange = (programTypes: OrganizationProgramTypeEnum[]) => {
    setProgramTypesFilter(programTypes);
  };

  const uploadAdminDocument = (data: AdminDocumentFormData) => {
    try {
      const stateTags: string[] = data.states.length ? data.states.map((state) => `state:${state}`) : ['state:ALL'];
      const programTypeTags: string[] = data.programTypes.length
        ? data.programTypes.map((programType) => `programType:${programType}`)
        : ['programType:ALL'];

      uploadFile(
        {
          file: data.file,
          category: data.category,
          tags: stateTags.concat(programTypeTags),
        },
        {
          onSuccess: () => {
            showToast(ToastTypeEnum.Success, t('admin.wonderschoolDocuments.uploadedSuccess'));
            resetUploadHook();
            refetchAdminFiles();
          },
          onError: (error) => {
            showToast(ToastTypeEnum.Error, t('admin.wonderschoolDocuments.uploadedError'));
            logError('Admin Documents - Upload error: ', {
              clientError: error,
            });
            resetUploadHook();
          },
        }
      );
    } catch (error) {
      // log it with rollbar
      logError('Admin Documents - Upload error: ', {
        clientError: error,
      });
    }
  };

  return (
    <MainContentLayout>
      <Helmet>
        <title>
          {getTitlePrefix()}
          {t('{{pageName}} - Wonderschool', { pageName: t('admin.wonderschoolDocuments') })}
        </title>
        <body />
      </Helmet>

      <PageTitle title={t('admin.wonderschoolDocuments')} />
      <UploadAdminDocument onUploadFile={uploadAdminDocument} />
      <AdminDocumentsFilters onStateChange={onStateChange} onProgramTypeChange={onProgramTypeChange} />
      {isLoadingAdminFiles ? (
        <PageLoading size={WidgetSizeEnum.X_SMALL} extraClasses="mt-4" />
      ) : (
        <AdminDocumentsTable data={adminDocumentsList} onFileDownload={onFileDownload} onFileDelete={deleteFile} />
      )}
    </MainContentLayout>
  );
};
