import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Grid, Header, Input } from 'semantic-ui-react';

import RoomPicker from '../../../Rooms/RoomPicker';
import { EmployeesList } from './EmployeesList';

import './SelectEmployees.styles.css';

const SelectEmployees = ({ employeesList, selectedEmployees, room, onRoomChange, onSearch, onSelect }) => {
  const { t } = useTranslation();

  const validStudentsLength = employeesList.filter(({ preventInvoiceReason }) => !preventInvoiceReason).length;

  return (
    <Grid stackable>
      <Grid.Row>
        <div className="select-employees-row">
          <Input data-testid="search-employees" onChange={onSearch} placeholder={t('Search...')} />
          <RoomPicker selection search onChange={onRoomChange} placeholder={t('Room')} name={'room'} value={room} />
        </div>
      </Grid.Row>
      <Grid.Row style={{ zIndex: 1 }}>
        <div className="select-employees-row">
          <Header as="h2" data-testid="students-count-header">
            {t('{{studentsCount}} Students', {
              studentsCount: employeesList.length,
            })}
          </Header>
          {validStudentsLength > 0 && (
            <Button data-testid="select-all-button" type="button" onClick={() => onSelect(null, true)}>
              {selectedEmployees.length !== validStudentsLength ? t('Select All') : t('Unselect All')}
            </Button>
          )}
        </div>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <EmployeesList selectedEmployees={selectedEmployees} onSelect={onSelect} employeesList={employeesList} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SelectEmployees.propTypes = {
  employeesList: PropTypes.array.isRequired,
  selectedEmployees: PropTypes.array.isRequired,
  roomsList: PropTypes.array.isRequired,
  room: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onRoomChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectEmployees;
