import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useOrganization } from '../hooks/useOrganizations';
import { RELATIONSHIP_TYPES } from '../students/consts';
import { CONTACT_STATUS, CONTACT_TYPES } from './enums';

const selectContactsList = createSelector([(state) => state.contacts], (contacts) => contacts?.list ?? []);

export const useContacts = () => {
  const contacts = useSelector(selectContactsList);
  const organization = useOrganization();

  const parentContacts = useMemo(() => {
    return contacts?.filter(
      (contact) =>
        contact.type === CONTACT_TYPES.family &&
        contact.relationship === RELATIONSHIP_TYPES.parent &&
        contact.status !== CONTACT_STATUS.deleted
    );
  }, [contacts]);

  const familyContacts = useMemo(() => {
    return contacts?.filter((contact) => {
      return contact.type === CONTACT_TYPES.family && contact.status !== CONTACT_STATUS.deleted;
    });
  }, [contacts]);

  // This will return only staff contacts that are not deleted. Active staffs and disabled staffs will be returned.
  const staffContacts = useMemo(() => {
    return contacts?.filter(
      (contact) => contact.type === CONTACT_TYPES.staff && contact.status !== CONTACT_STATUS.deleted
    );
  }, [contacts]);

  // This will return all the staffs. Active staffs, disabled staffs, and deleted staffs will be returned.
  const allStaffContacts = useMemo(() => {
    return contacts?.filter((contact) => contact.type === CONTACT_TYPES.staff);
  }, [contacts]);

  const directorContact = useMemo(() => {
    return staffContacts?.find(
      (contact) => contact.uid === organization.createdBy && contact.status !== CONTACT_STATUS.deleted
    );
  }, [organization.createdBy, staffContacts]);

  return { contacts, parentContacts, familyContacts, staffContacts, directorContact, allStaffContacts };
};
