import { Badge, Button, ButtonLink, ColorPaletteEnum, Dialog, Label } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';
import { RouteNameEnum, useRoutes } from '../../../navigation';

type ParentHandbookModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
};

const ParentHandbookModal = ({ isModalOpen, closeModal }: ParentHandbookModalProps) => {
  const { t } = useTranslation();
  const newLabel = `${t('common.new')}!`.toUpperCase();
  const { gotoRouteByName } = useRoutes();

  const handleGetStarted = () => {
    gotoRouteByName(RouteNameEnum.PARENT_HANDBOOK);
  };

  return (
    <Dialog isOpen={isModalOpen} onClose={closeModal} fullScreenOnMobile={true}>
      <Dialog.Title>
        <div className="flex flex-col gap-2">
          <div>
            <img src="/images/parent-handbook.svg" alt="parent handbook" />
          </div>
          <div className="flex flex-col gap-2 p-4 text-xl font-bold">
            <div>
              <Badge color={ColorPaletteEnum.YELLOW} label={newLabel} extraContainerClasses="rounded-3xl !px-3" />
            </div>
            <div>{t('parentHandbook.modal.title')}</div>
          </div>
        </div>
      </Dialog.Title>
      <Dialog.Description>
        <div className="px-4">
          <Label>{t('parentHandbook.modal.description')}</Label>
        </div>
      </Dialog.Description>
      <Dialog.Actions>
        <div className="fixed bottom-0 flex w-11/12 flex-col gap-4 space-x-2 md:relative md:bottom-auto md:w-full md:pt-10">
          <Button primary onClick={handleGetStarted} data-testid="get-started-btn">
            {t('common.getStarted')}
          </Button>
          <ButtonLink
            onClick={closeModal}
            primary={false}
            content={t('parentHandbook.modal.cta')}
            extraClasses="md:px-4"
          />
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default ParentHandbookModal;
