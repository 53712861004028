// TODO: Lodash should no longer be used here
import { Invoice } from '@wonderschool/common-base-types';
// eslint-disable-next-line no-restricted-imports
import { omit } from 'lodash';
import { InvoiceV2 } from '../../../helpers/invoices';
import {
  INVOICES_FETCHED,
  INVOICE_PLANS_FETCHED,
  INVOICE_REFRESHED,
  INVOICE_REMOVE_FROM_LIST,
  INVOICE_SELECTED,
  INVOICE_SELECTION_CLEARED,
  INVOICE_UPDATED,
} from '../../actions/types';

export enum InvoiceType {
  ONE_TIME = 'oneTime',
  RECURRING = 'recurring',
  ALL = 'all',
}

export type InvoiceListData = Record<string, Invoice | InvoiceV2>;
// Define the shape of the invoice data
export interface InvoiceTypeData {
  list: Record<string, Invoice | InvoiceV2>;
  last: Invoice | null;
  count: number;
  selected?: Invoice | null;
}

// Define the shape of the entire state
export interface InvoicesState {
  invoiceType: InvoiceType;
  isLoaded: boolean;
  [InvoiceType.ONE_TIME]: InvoiceTypeData;
  [InvoiceType.RECURRING]: InvoiceTypeData;
  [InvoiceType.ALL]: InvoiceTypeData;
}

// Define the initial state
const initialState: InvoicesState = {
  isLoaded: false,
  invoiceType: InvoiceType.ALL,
  [InvoiceType.ONE_TIME]: {
    list: [],
    last: null,
    count: 0,
  },
  [InvoiceType.RECURRING]: {
    list: [],
    last: null,
    count: 0,
  },
  [InvoiceType.ALL]: {
    list: [],
    last: null,
    count: 0,
  },
};

// Define action types
interface InvoicesFetchedAction {
  type: typeof INVOICES_FETCHED;
  invoices: { list: Invoice[] | Record<string, Invoice>; count: number };
  invoiceType: InvoiceType;
}

interface InvoicePlansFetchedAction {
  type: typeof INVOICE_PLANS_FETCHED;
  invoices: { list: Invoice[]; lastDocument: Invoice | null; count: number };
  invoiceType: InvoiceType;
}

interface InvoiceSelectedAction {
  type: typeof INVOICE_SELECTED;
  invoice: Invoice;
  invoiceType: InvoiceType;
}

interface InvoiceSelectionClearedAction {
  type: typeof INVOICE_SELECTION_CLEARED;
  invoiceType: InvoiceType;
}

interface InvoiceRefreshedAction {
  type: typeof INVOICE_REFRESHED;
  invoice: Invoice;
  invoiceType: InvoiceType;
}

interface InvoiceRemovedFromListAction {
  type: typeof INVOICE_REMOVE_FROM_LIST;
  id: string;
  invoiceType: InvoiceType;
}

interface InvoiceUpdatedAction {
  type: typeof INVOICE_UPDATED;
  invoice: Invoice;
  invoiceType: InvoiceType;
}

type InvoiceActionTypes =
  | InvoicesFetchedAction
  | InvoicePlansFetchedAction
  | InvoiceSelectedAction
  | InvoiceSelectionClearedAction
  | InvoiceRefreshedAction
  | InvoiceRemovedFromListAction
  | InvoiceUpdatedAction;

// Helper functions
function createNewInvoicesStateFromArray(state: InvoicesState, action: InvoicesFetchedAction): InvoicesState {
  const list = action.invoices.list as Invoice[];
  const last = list.length ? list[list.length - 1] : null;
  const invoiceType = action.invoiceType;

  return {
    ...state,
    [invoiceType]: {
      ...state[invoiceType],
      list: list.reduce((acc, invoice) => ({ ...acc, [invoice.id!]: invoice }), {}),
      last,
      count: action.invoices.count,
    },
    invoiceType: invoiceType,
    isLoaded: true,
  };
}

function createNewInvoicesStateFromObject(state: InvoicesState, action: InvoicesFetchedAction): InvoicesState {
  const list = action.invoices.list as Record<string, Invoice>;
  const last = Object.values(list).pop() || null;
  const invoiceType = action.invoiceType;

  return {
    ...state,
    [invoiceType]: {
      ...state[invoiceType],
      list,
      last,
      count: Object.keys(list).length,
    },
    invoiceType: invoiceType,
    isLoaded: true,
  };
}

function createNewInvoicesState(state: InvoicesState, action: InvoicesFetchedAction): InvoicesState {
  const isArray = Array.isArray(action.invoices.list);
  const isObject = !isArray && typeof action.invoices.list === 'object';

  if (isArray) {
    return createNewInvoicesStateFromArray(state, action);
  } else if (isObject) {
    return createNewInvoicesStateFromObject(state, action);
  } else {
    return { ...state };
  }
}

// Reducer
const invoices = (state = initialState, action: InvoiceActionTypes): InvoicesState => {
  switch (action.type) {
    case INVOICES_FETCHED:
      return createNewInvoicesState(state, action);

    case INVOICE_PLANS_FETCHED: {
      const { list, lastDocument: last, count } = action.invoices;
      const invoiceType = action.invoiceType;

      return {
        ...state,
        [invoiceType]: {
          ...state[invoiceType],
          list: list.reduce((acc, invoice) => ({ ...acc, [invoice.id!]: invoice }), {}),
          last,
          count,
          selected: null,
        },
      };
    }

    case INVOICE_SELECTED: {
      return {
        ...state,
        [action.invoiceType]: {
          ...state[action.invoiceType],
          selected: action.invoice,
        },
      };
    }

    case INVOICE_SELECTION_CLEARED: {
      return {
        ...state,
        [action.invoiceType]: {
          ...state[action.invoiceType],
          selected: null,
        },
      };
    }

    case INVOICE_REFRESHED: {
      const { invoice, invoiceType } = action;
      if (invoice && invoice.id) {
        return {
          ...state,
          [invoiceType]: {
            ...state[invoiceType],
            list: { ...state[invoiceType].list, [invoice.id]: invoice },
            selected: null,
          },
        };
      }
      return state;
    }

    case INVOICE_REMOVE_FROM_LIST: {
      return {
        ...state,
        [action.invoiceType]: {
          ...state[action.invoiceType],
          list: omit(state[action.invoiceType].list, [action.id]),
        },
      };
    }

    case INVOICE_UPDATED: {
      return {
        ...state,
        [action.invoiceType]: {
          ...state[action.invoiceType],
          list: {
            ...state[action.invoiceType].list,
            [action.invoice.id!]: action.invoice,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default invoices;

// Action creators
export const invoicesFetched = (
  invoices: { list: Invoice[] | Record<string, Invoice>; count: number },
  invoiceType: InvoiceType
): InvoicesFetchedAction => ({
  type: INVOICES_FETCHED,
  invoices,
  invoiceType,
});

export const invoicePlansFetched = (
  invoices: { list: Invoice[]; lastDocument: Invoice | null; count: number },
  invoiceType: InvoiceType
): InvoicePlansFetchedAction => ({
  type: INVOICE_PLANS_FETCHED,
  invoices,
  invoiceType,
});

export const invoiceSelected = (invoice: Invoice, invoiceType: InvoiceType): InvoiceSelectedAction => ({
  type: INVOICE_SELECTED,
  invoice,
  invoiceType,
});

export const invoiceSelectionCleared = (invoiceType: InvoiceType): InvoiceSelectionClearedAction => ({
  type: INVOICE_SELECTION_CLEARED,
  invoiceType,
});

export const invoiceRefreshed = (invoice: Invoice, invoiceType: InvoiceType): InvoiceRefreshedAction => ({
  type: INVOICE_REFRESHED,
  invoice,
  invoiceType,
});

export const invoiceRemovedFromList = (id: string, invoiceType: InvoiceType): InvoiceRemovedFromListAction => ({
  type: INVOICE_REMOVE_FROM_LIST,
  id,
  invoiceType,
});

export const invoiceUpdated = (invoice: Invoice, invoiceType: InvoiceType): InvoiceUpdatedAction => ({
  type: INVOICE_UPDATED,
  invoice,
  invoiceType,
});
