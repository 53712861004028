import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Divider, Form } from 'semantic-ui-react';

import InvoiceItemEditor from './InvoiceItemEditor';
import InvoiceTotal from './InvoiceTotal';

import './InvoiceItemsEditor.scss';

const InvoiceItemsEditor = (props) => {
  const { t } = useTranslation();

  let { onAddInvoiceItem, invoiceItemList, total, validation } = props;
  const displayNoNegativeInvoiceError = validation[0] && validation.length > 0 && validation[0].totalError && total < 0;

  return (
    <>
      <Form.Group widths={'equal'} className={'grid-header hide-headers-mobile'}>
        <Form.Field data-testid="invoice-item-category" width={4} required label={t('Category')} />
        <Form.Field data-testid="invoice-item-description" width={4} required label={t('Description')} />
        <Form.Field data-testid="invoice-item-quantity" width={5} label={t('Notes')} />
        <Form.Field data-testid="invoice-item-amount" width={2} required label={t('Amount')} />
        <Form.Field data-testid="invoice-item-discount" width={1}>
          &nbsp;
        </Form.Field>
      </Form.Group>
      {invoiceItemList &&
        invoiceItemList.map((invoiceItem, index) => {
          return (
            <InvoiceItemEditor
              categoryError={validation[index] && validation.length > 0 ? validation[index].categoryError : null}
              itemError={validation[index] && validation.length > 0 ? validation[index].itemError : null}
              discountTypeErrors={
                validation[index] && validation.length > 0 ? validation[index].discountTypeErrors : []
              }
              key={`invoiceRow${index}`}
              onAddition={props.onAddition}
              onAdditionItemOptions={props.onAdditionItemOptions}
              onChangeItemOptions={props.onChangeItemOptions}
              onChange={props.onChange}
              onAddDiscount={props.onAddDiscount}
              onDeleteDiscount={props.onDeleteDiscount}
              onChangeDiscount={props.onChangeDiscount}
              onDeleteInvoiceItem={props.onDeleteInvoiceItem}
              categoryOptions={props.categoryOptions}
              discountOptions={props.discountOptions}
              index={index}
              isSubmitDetails={props.isSubmitDetails}
              {...invoiceItem}
            />
          );
        })}
      <Divider />
      <Form.Group>
        <Form.Field width={4}>
          <a
            data-testid="add-another-item"
            href="#add-item"
            onClick={(e) => {
              e.preventDefault();
              console.log('e');
              onAddInvoiceItem();
            }}
          >
            {t('Add Another Item')}
          </a>
        </Form.Field>
        <Form.Field width={8} className="text-center">
          {displayNoNegativeInvoiceError && (
            <div className="invoice-total-error">{t('billing.invoice.negativeError')}</div>
          )}
        </Form.Field>
        <Form.Field width={4}>
          <InvoiceTotal total={total} />
        </Form.Field>
      </Form.Group>
    </>
  );
};

InvoiceItemsEditor.propTypes = {
  categoryOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.array,
  onChangeDiscount: PropTypes.func.isRequired,
  onAddition: PropTypes.func.isRequired,
  onAdditionItemOptions: PropTypes.func.isRequired,
  onChangeItemOptions: PropTypes.func.isRequired,
  onAddDiscount: PropTypes.func.isRequired,
  onDeleteDiscount: PropTypes.func.isRequired,
  onAddInvoiceItem: PropTypes.func.isRequired,
  onDeleteInvoiceItem: PropTypes.func.isRequired,
  invoiceItemList: PropTypes.array,
};

export default InvoiceItemsEditor;
