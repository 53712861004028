import { InvoicePlan } from '@wonderschool/common-base-types';
import { getDocuments, setDocument, startCollectionListener } from './firestore';

import { v4 as uuidv4 } from 'uuid';

const paths = {
  charge: (organizationId, chargeId) => `organizations/${organizationId}/charges/${chargeId}`,
  charges: (organizationId) => `organizations/${organizationId}/charges`,
};

export async function fetchCharges(organizationId, conditions, orderBy = [], limit = null, startAfter = null) {
  return getDocuments({
    path: paths.charges(organizationId),
    conditions,
    orderBy: [{ field: 'name', direction: 'asc' }, ...orderBy],
    limit,
    startAfter,
  });
}

export function chargesOnListen(
  organizationId: string,
  next: (...args: any[]) => void,
  error: (error: any) => void,
  complete?: () => void
) {
  return startCollectionListener(
    {
      path: paths.charges(organizationId),
      conditions: [],
      orderBy: [{ field: 'category', direction: 'asc' }],
    },
    next,
    error,
    complete
  );
}

export async function saveCharge(organizationId: string, charge: InvoicePlan) {
  if (!charge.id) charge.id = uuidv4();

  await setDocument({
    path: paths.charge(organizationId, charge.id),
    data: charge,
  });
  return charge;
}
