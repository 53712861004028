import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '@wonderschool/ccms-ui';
import { EditFormSection, InputData, Label, usStateOptions } from '@wonderschool/common-base-ui';
import { useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { userHasPermission } from '../../../api/firebase/account';
import { FoodProgramTierEnum, GenderEnum } from '../../../common';
import { ethnicityOptions, raceOptions } from '../../../config';
import { dateFormatter, toDateObject } from '../../../helpers/dates';
import { KinderSystems } from '../../types';
// eslint-disable-next-line no-restricted-imports
import { Image } from 'semantic-ui-react';
import { showErrorToast } from '../../../Components/Shared/showToast';
import ProfilePictureUploader from '../../../Components/Upload/ProfilePictureUploader';
import { formatFullName, phoneNumberFormat } from '../../../helpers/utils';
import { useOrganization } from '../../../hooks/useOrganizations';
import { organizationAddStudent, organizationUpdateStudent } from '../../studentsRedux';
import { getRandomStudentPicture } from '../../studentsUtils';

interface StudentPersonalInformationProps {
  selectedStudent: {
    id: string;
    displayName: string;
    firstName: string;
    lastName: string;
    nickName: string;
    birthday: number | { seconds: number; nanoseconds: number };
    gender: string;
    allergies: string;
    medications: string;
    doctorName: string;
    doctorPhone: string;
    notes: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    race: string;
    ethnicity: string;
    photosAllowed: boolean;
    foodProgramTier: FoodProgramTierEnum;
    kinderSystems: KinderSystems;
    picture?: string;
  };
}

type StudentFormType = Omit<
  StudentPersonalInformationProps['selectedStudent'],
  'id' | 'displayName' | 'foodProgramTier' | 'kinderSystems'
>;

function StudentPersonalInformation(studentPersonalInformationProps: StudentPersonalInformationProps) {
  const {
    selectedStudent: {
      id,
      firstName,
      lastName,
      nickName,
      // TODO: this should be calculated during submission
      // displayName,
      birthday,
      gender,
      allergies,
      medications,
      doctorName,
      doctorPhone,
      notes,
      address1,
      address2,
      city,
      state,
      zipcode,
      race,
      ethnicity,
      photosAllowed,
      picture,
    },
  } = studentPersonalInformationProps;
  const [editMode, setEditMode] = useState(false);
  const [pictureUrl, setPictureUrl] = useState<string | undefined>(picture);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const canRoleEditStudentPersonal = userHasPermission('can_edit_student_personal');
  const currentOrganization = useOrganization();

  const translatedRaceOptions = useMemo(
    () => raceOptions.map((race) => ({ label: t(race.text), value: race.value })),
    [t]
  );

  const translatedEthnicityOptions = useMemo(
    () => ethnicityOptions.map((ethnicity) => ({ label: t(ethnicity.text), value: ethnicity.value })),
    [t]
  );

  const translatedGenderOptions = useMemo(
    () =>
      Object.values(GenderEnum).map((gender) => ({
        label: capitalizeFirstLetter(t(gender)),
        value: capitalizeFirstLetter(gender),
      })),
    [t]
  );

  const memoizedInitialValues: StudentFormType = useMemo(() => {
    const {
      selectedStudent: {
        firstName,
        lastName,
        nickName,
        birthday,
        gender,
        allergies,
        medications,
        doctorName,
        doctorPhone,
        notes,
        address1,
        address2,
        city,
        state,
        zipcode,
        race,
        ethnicity,
        photosAllowed,
      },
    } = studentPersonalInformationProps;

    return {
      firstName,
      lastName,
      nickName,
      birthday,
      gender,
      allergies,
      medications,
      doctorName,
      doctorPhone,
      notes,
      address1,
      address2,
      city,
      state,
      zipcode,
      race,
      ethnicity,
      photosAllowed,
    };
  }, [studentPersonalInformationProps]);

  if (!id) return null;

  function handleEditModeChanged(newEditModeValue: boolean) {
    setEditMode(newEditModeValue);
  }

  // TODO: use Student Type
  const inputs: InputData<StudentFormType>[] = [
    {
      id: 'firstName',
      label: t('common.firstName'),
      inputType: 'text' as const,
      required: true,
      value: firstName,
    },
    {
      id: 'lastName',
      label: t('common.lastName'),
      inputType: 'text' as const,
      required: true,
      value: lastName,
    },
    {
      id: 'nickName',
      label: t('NickName'),
      inputType: 'text' as const,
      value: nickName,
    },
    {
      id: 'birthday',
      label: t('Birthday'),
      name: 'birthday',
      inputType: 'date' as const,
      value: dateFormatter(birthday) || t('No birthday'),
    },
    {
      id: 'gender',
      label: t('Gender'),
      inputType: 'select' as const,
      defaultValue: translatedGenderOptions.find((option) => option.value === gender),
      options: translatedGenderOptions,
    },
    {
      id: 'allergies',
      label: t('Allergies'),
      inputType: 'text' as const,
      value: allergies,
    },
    {
      id: 'medications',
      label: t('Medications'),
      inputType: 'text' as const,
      value: medications || t('No medications added'),
    },
    {
      id: 'doctorName',
      label: t('Doctor'),
      inputType: 'text' as const,
      value: doctorName,
    },
    {
      id: 'doctorPhone',
      label: t(`Doctor's phone number`),
      inputType: 'phone' as const,
      value: doctorPhone,
      'data-testid': 'doctorPhone',
    },
    {
      id: 'notes',
      label: t(`Notes`),
      inputType: 'text' as const,
      value: notes || t('There are no notes added'),
    },
    {
      id: 'address1',
      label: t(`Address1`),
      inputType: 'text' as const,
      value: address1,
    },
    {
      id: 'address2',
      label: t(`Address2`),
      inputType: 'text' as const,
      value: address2,
    },
    {
      id: 'city',
      label: t(`City`),
      inputType: 'text' as const,
      value: city,
    },
    {
      id: 'state',
      label: t(`State`),
      inputType: 'us-state-select' as const,
      defaultValue: usStateOptions.find((stateOption) => stateOption.value === state),
    },
    {
      id: 'zipcode',
      label: t(`Zipcode`),
      inputType: 'number' as const,
      value: Number(zipcode),
    },
    {
      id: 'race',
      label: t('Race'),
      inputType: 'select' as const,
      defaultValue: translatedRaceOptions.find((option) => option.value === race),
      options: translatedRaceOptions,
    },
    {
      id: 'ethnicity',
      label: t('Ethnicity'),
      inputType: 'select' as const,
      defaultValue: translatedEthnicityOptions.find((option) => option.value === ethnicity),
      options: translatedEthnicityOptions,
    },
    {
      id: 'photosAllowed',
      label: t('Photos Allowed'),
      inputType: 'checkbox',
      value: photosAllowed,
    },
  ];

  async function onSubmit(data: StudentFormType) {
    // TODO: probably not needed, test it later
    if (!(currentOrganization && currentOrganization.id)) {
      return;
    }

    const { doctorPhone, birthday, ...rest } = data;

    // TODO: race/ethnicity/gender cannot be null it seems, track those down
    const formData = {
      birthday: toDateObject(birthday),
      displayName: formatFullName(rest),
      doctorPhone: phoneNumberFormat(doctorPhone),
      picture: pictureUrl || getRandomStudentPicture(),
      ...rest,
      // TODO: remove this when studentsRedux.js is converted to typescript
      combinedFamily: null,
    };

    // pass it to the API
    // - write to Firebase
    // - write to Postgres
    // - we need a GraphQL mutation
    // - we need to check if this all blows up (ApolloQueryProvider is working all good?)

    if (id) {
      try {
        // Update
        dispatch(
          organizationUpdateStudent(currentOrganization.id, {
            id,
            ...formData,
            organization: currentOrganization.id,
          })
        );
      } catch (error: unknown) {
        showErrorToast(t('Unable to Update'), (error as Error).message);
      }

      return;
    }

    // New entry
    try {
      dispatch(
        organizationAddStudent(currentOrganization.id, {
          ...formData,
          organization: currentOrganization.id,
        })
      );
    } catch (error: any) {
      showErrorToast(t('Unable to Add'), error?.message);
    }
  }

  return (
    <>
      <EditFormSection
        header={t('Personal Info')}
        onSubmit={onSubmit}
        inputs={inputs}
        values={memoizedInitialValues}
        onEditModeChanged={handleEditModeChanged}
        dataTestId="student-personal-info-editor"
      />
      {/* note: Depending on "editMode" is a bit hacky thing, but we didn't have time to handle "photo" inputType in EditFormSection */}
      {editMode && canRoleEditStudentPersonal && (
        <div className="rounded-lg bg-purple-50">
          {userHasPermission('can_edit_student') && id && currentOrganization.id && (
            <Label name={t('Student Photo')} extraClasses="p-4">
              <ProfilePictureUploader
                title="Uploading Student Picture"
                onUploaded={(imageUrl: string) => {
                  setPictureUrl(imageUrl);
                }}
                uploadPath={`organizations/${currentOrganization.id}/students/${id}`}
                data-testid="upload-photo"
              />
            </Label>
          )}
          {pictureUrl && <Image src={pictureUrl} size="medium" className="p-4" rounded data-testid="picture" />}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedStudent: state.students.selectedStudent,
});

export default connect(mapStateToProps)(StudentPersonalInformation);
