import { useTranslation } from 'react-i18next';
import { WsCheckSuccessCircle, WsClock, WsCrossCircle, WsPending } from '../icons';
import styles from './contacts.module.scss';
import { CONTACT_STATUS } from './enums';

export default function ContactStatusLabel({ contact }) {
  const { t } = useTranslation();

  switch (contact.status) {
    case CONTACT_STATUS.new:
      return (
        <span data-testid="not-invited-label" className={`${styles.pendingTag} ${styles.tag}`}>
          <WsPending /> {t('Not Invited')}
        </span>
      ); // color: default - a light "grey", (NOT gray)

    case CONTACT_STATUS.inviting:
      return (
        <span data-testid="inviting-label" className={`${styles.invitingTag} ${styles.tag}`}>
          <WsClock />
          {t('Inviting')}
        </span>
      );

    case CONTACT_STATUS.verified:
      return (
        <span data-testid="verified-label" className={`${styles.successTag} ${styles.tag}`}>
          <WsCheckSuccessCircle /> {t('Verified')}
        </span>
      );

    case CONTACT_STATUS.invited:
      return (
        <span data-testid="invited-label" className={`${styles.invitedTag} ${styles.tag}`}>
          <WsClock />
          {t('Invited')}
        </span>
      );

    default:
      return (
        <span data-testid="disabled-label" className={`${styles.pendingTag} ${styles.tag}`}>
          <WsCrossCircle /> {t('Disabled')}
        </span>
      );
  }
}
