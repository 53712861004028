import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, CheckboxProps, Form } from 'semantic-ui-react';

import { CONTACT_STATUS } from '../../contacts';
import { useContacts } from '../../contacts/contactsHooks';

import { FormComponentProps /*, omit */ } from '../../common';
import { DEFAULT_PARENT } from '../consts';
import { ParentType } from '../types';

import ParentEditForm from './ParentEditForm';

enum NewOrExistingEnum {
  NEW = 'new',
  EXISTING = 'existing',
}

// Prop drilling at its finest. Ugh.
type ParentFormProps = FormComponentProps<ParentType> & {
  parent: ParentType;
  parents?: ParentType[]; // pass this to ParentEditForm to check for duplicate emails
  onChangeParent?: (parent: ParentType) => void;
};

function ParentForm({ isSaving, onSave, parent: parentParam, parents = [], onChangeParent }: ParentFormProps) {
  const { t } = useTranslation();
  const { familyContacts } = useContacts();

  const [selectedContact, setSelectedContact] = useState<any>();
  const [parent, setParent] = useState<ParentType>(parentParam ?? { ...DEFAULT_PARENT });
  const [newOrExisting, setNewOrExisting] = useState<NewOrExistingEnum>(NewOrExistingEnum.NEW);

  useEffect(() => {
    if (!parentParam) return;
    setNewOrExisting(parentParam.contact ? NewOrExistingEnum.EXISTING : NewOrExistingEnum.NEW);
    setParent({ ...parentParam });
  }, [parentParam]);

  const contactsNotInParents = useMemo(() => {
    return familyContacts.filter(
      (contact: any) =>
        contact.status !== CONTACT_STATUS.disabled &&
        contact.status !== CONTACT_STATUS.deleted &&
        !parents.find((parent) => parent.email === contact.email || parent.contact?.id === contact.id)
    );
  }, [familyContacts, parents]);

  const selectContactOptions = useMemo(() => {
    const options = contactsNotInParents.map((contact: any) => ({
      key: contact.id,
      text: contact.displayName,
      value: contact,
    }));
    return options;
  }, [contactsNotInParents]);

  const onChangeSelectedContact = useCallback(
    (e: any, { value }: { value?: any }) => {
      e.preventDefault();
      setSelectedContact(value);
      if (!value) return;

      const selectedParent = {
        ...parent,
        id: value.id,
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phone: value.phone,
        contact: value,
      } as ParentType;
      setParent(selectedParent);
    },
    [parent]
  );

  const onChangeNewOrExisting = useCallback((e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    e.preventDefault();
    setSelectedContact(null);
    setParent({ ...DEFAULT_PARENT });
    const { value } = data; // data.value is a string, not a NewOrExistingEnum
    if (value) setNewOrExisting(value as NewOrExistingEnum);
  }, []);

  return (
    <>
      <div className="parent-form">
        <Form.Group>
          <Form.Field>
            <Checkbox
              disabled={!!parent.isInterestedFamily}
              radio
              label={t('students.newParentLabel')}
              name="newOrExisting"
              value={NewOrExistingEnum.NEW}
              checked={newOrExisting === NewOrExistingEnum.NEW}
              onChange={onChangeNewOrExisting}
              data-testid="checkbox-newparent"
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              disabled={parent.isInterestedFamily}
              radio
              label={t('students.existingParentLabel')}
              name="newOrExisting"
              value={NewOrExistingEnum.EXISTING}
              checked={newOrExisting === NewOrExistingEnum.EXISTING}
              onChange={onChangeNewOrExisting}
              data-testid="checbox-existingparent"
            />
            {newOrExisting === NewOrExistingEnum.EXISTING && !!selectedContact && !parent.isInterestedFamily && (
              <>
                <span> (</span>
                <a href="#" onClick={() => setSelectedContact(null)} data-testid="parentSelectDifferentParentLabel">
                  {t('students.parentSelectDifferentParentLabel')}
                </a>
                <span>) </span>
              </>
            )}
          </Form.Field>
        </Form.Group>

        {newOrExisting === NewOrExistingEnum.EXISTING && !selectedContact && !parent.isInterestedFamily ? (
          <Form.Field error={false}>
            <Form.Dropdown
              fluid
              search
              selection
              clearable
              required
              // Without "selectOnBlur={false}" Dropdown will have a second selection that defaults to the first item!
              selectOnBlur={false}
              id="users"
              name="users"
              placeholder={t('common.selectContact')}
              options={selectContactOptions}
              value={selectedContact?.id ?? ''}
              onChange={onChangeSelectedContact}
              data-testid="select-contact"
            />
          </Form.Field>
        ) : (
          <ParentEditForm
            hasTitle={false}
            isSaving={isSaving}
            onChangeParent={onChangeParent}
            onSave={onSave}
            readonly={newOrExisting === NewOrExistingEnum.EXISTING || parent.isInterestedFamily}
            parent={parent}
            parents={parents}
          />
        )}
      </div>
    </>
  );
}

export default ParentForm;
