import { Button, CalendarIcon, Dialog, SuccessIcon } from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { getNextInvoiceDate } from '../../enrollmentsUtils';
import { InvitationRecipientType } from '../../types';

type EnrollmentInvitationConfirmationModalProps = {
  student;
  invitationRecipients: InvitationRecipientType[];
  isOpen: boolean;
  onClose: () => void;
};

const EnrollmentInvitationConfirmationModal = ({
  student,
  invitationRecipients = [],
  isOpen,
  onClose,
}: EnrollmentInvitationConfirmationModalProps) => {
  const tuitionAndFees = student?.enrollment?.tuitionAndFees;
  const { t } = useTranslation();
  const nextInvoiceDate =
    tuitionAndFees && Object.keys(tuitionAndFees).length > 0 && tuitionAndFees.tuition
      ? getNextInvoiceDate(
          tuitionAndFees.tuition.dueDate as unknown as Timestamp,
          tuitionAndFees.tuition.billingInterval,
          tuitionAndFees.tuition.proratedAmount,
          tuitionAndFees.tuition.detailedTuition?.chargeDate,
          tuitionAndFees.tuition.billFor,
          student?.enrollmentDate
        )
      : null;

  const getInvitationRecipientsNames = () => {
    if (invitationRecipients.length > 1) {
      const displayNames = invitationRecipients.map((contact: InvitationRecipientType) => contact.displayName);
      return displayNames.join(',');
    }

    if (invitationRecipients.length > 0) {
      return invitationRecipients[0].displayName;
    }

    return '';
  };

  const getEnrollmentText = (): string => {
    const studentInfo = {
      name: student.displayName,
      date: dayjs.utc(student.enrollmentDate).format('MM/DD'),
    };
    if (dayjs.utc(student.enrollmentDate).isBefore(dayjs())) {
      return t('enrollments.childPastEnrolled', studentInfo);
    }
    if (student.enrollment?.automaticallyEnroll) {
      return t('enrollments.childAutomaticallyEnrolled', studentInfo);
    }
    return t('enrollments.childEnrolled', studentInfo);
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} hasCloseButton={false}>
      <div className="px-4 pb-4 pt-10">
        <h1 className="pb-8 text-center text-xl">
          {invitationRecipients.length > 1
            ? t('enrollments.invitationModalTitle.multiple', {
                names: getInvitationRecipientsNames(),
              })
            : t('enrollments.invitationModalTitle.single', {
                name: getInvitationRecipientsNames(),
              })}
        </h1>
        <div className="flex flex-col gap-4">
          {student.enrollment?.documents?.length > 0 && (
            <div className="flex items-center gap-2">
              <SuccessIcon className="h-10 w-10 text-blue-900" /> {t('enrollments.documentsSent')}
            </div>
          )}
          <div className="flex items-center gap-2">
            <div>
              <SuccessIcon className="h-10 w-10 text-blue-900" />
            </div>
            <div>
              {t('enrollments.studentAdded', {
                name: student.displayName,
                status: student.enrollment?.status,
              })}
            </div>
          </div>
          {nextInvoiceDate && (
            <div className="flex items-center gap-2">
              <div>
                <SuccessIcon className="h-10 w-10 text-blue-900" />
              </div>
              <div>{t('enrollments.recurringPlanSet', { date: dayjs(nextInvoiceDate).format('MM/DD') })}</div>
            </div>
          )}
          {student.enrollment?.tuitionAndFees?.tuition?.proratedAmount > 0 && (
            <div className="flex items-center gap-2">
              <SuccessIcon className="h-10 w-10 text-blue-900" />{' '}
              {t('enrollments.oneTimeInvoiceSet', {
                date: dayjs.unix(student.enrollment?.tuitionAndFees?.tuition?.dueDate.seconds).utc().format('MM/DD'),
              })}
            </div>
          )}
          <div className="flex items-center gap-2">
            <CalendarIcon className="h-10 w-10 text-blue-900" /> {getEnrollmentText()}
          </div>
        </div>
        <div>
          <Button extraClasses="mt-10 w-full" primary onClick={onClose}>
            {t('common.done')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EnrollmentInvitationConfirmationModal;
