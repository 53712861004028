import { CLAIMS_ENUM, isUsersHighestClaimOneOf } from '../../helpers/utils';
import store from '../store';

export const getUsersRoomsOrLocations = (desiredUserPropKey) => {
  const userStore = store.getState()?.user || [];
  const { claims } = userStore;
  const targetClaims = [CLAIMS_ENUM.locationAdmin, CLAIMS_ENUM.teacher, CLAIMS_ENUM.supportStaff];

  if (isUsersHighestClaimOneOf(claims, targetClaims)) {
    const results = Object.keys(userStore[desiredUserPropKey]);
    return results.length > 0 ? results : ['-1']; // a fake room id to make sure we don't elevate permissions for users without rooms or locations
  }

  return [];
};
