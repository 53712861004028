import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoomDropdown, WsInputEventType } from '../../common';
import { logError } from '../../rollbar';

import { TimecardStatusEnum } from '../enums';
import { TimecardType } from '../types';

import { Button } from '@wonderschool/common-base-ui';
import { DatepickerDateType, WsAvatar, WsDatepicker, WsSpinner, WsValidationError } from '../../common';
import { parseDateString, toDisplayTime } from '../timecardsUtils';
import { TimeInput } from './widgets';

type TimecardEditModalProps = {
  timecard?: TimecardType;
  staff?: any;
  onClose?: () => void;
  onSave: (timecard: TimecardType) => void;
};

type FormDataType = {
  roomId: string;
  clockedInDate: Date;
  clockedOutDate?: Date;
  clockedInTime: string;
  clockedOutTime?: string;
};
export default function TimecardEditModalOld({ timecard, staff, onClose, onSave }: TimecardEditModalProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormDataType>({} as FormDataType);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [_errors, _setErrors] = useState<any>({});

  const onChange = useCallback(({ name, value }: WsInputEventType<DatepickerDateType | string>) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onCloseLocal = useCallback(() => {
    setIsSaving(false);
    onClose?.();
  }, [onClose]);

  const onSaveLocal = useCallback(() => {
    setIsSaving(true);

    const { clockedInDate, clockedInTime, clockedOutDate, clockedOutTime } = formData;
    const clockedIn = moment(clockedInDate).format('MM/DD/YYYY') + ' ' + clockedInTime;
    try {
      const timecardLocal = {
        ...timecard,
        roomId: formData.roomId,
        clockedIn: parseDateString(clockedIn),
      } as TimecardType;

      if (clockedOutDate) {
        const clockedOut =
          moment(clockedOutDate).format('MM/DD/YYYY') + (clockedOutTime ? ' ' + clockedOutTime : ' 00:00 am');
        timecardLocal.clockedOut = parseDateString(clockedOut);
        timecardLocal.status = TimecardStatusEnum.CLOCKED_OUT;
      }

      onSave?.(timecardLocal);
      onCloseLocal();
    } catch (error) {
      logError('TimecardEditModal: failed saving timecard', timecard, error);
    } finally {
      setIsSaving(false);
    }
  }, [formData, onCloseLocal, onSave, timecard]);

  useEffect(() => {
    if (!timecard) return;
    const { clockedIn, clockedOut } = timecard;
    const { clockedInTime, clockedOutTime } = toDisplayTime(timecard, true);
    setFormData({
      roomId: timecard.roomId,
      clockedInDate: clockedIn,
      clockedOutDate: clockedOut ?? '',
      clockedInTime: clockedInTime ?? '',
      clockedOutTime: clockedOutTime ?? '',
    } as FormDataType);
  }, [timecard]);

  return (
    <Transition.Root show={!!timecard} as={Fragment}>
      <Dialog as="div" open={!!timecard} onClose={onCloseLocal} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white p-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                {isSaving && <WsSpinner hasOverlay={true} />}

                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => onClose?.()}
                    data-testid="timecard-edit-close"
                  >
                    <span className="sr-only">{t('Close')}</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="div"
                  className="text-left text-lg font-semibold leading-6 text-gray-500"
                  data-testid="timecard-edit-title"
                >
                  {t('timecards.updateTimecardTitle')}
                </Dialog.Title>
                <div className="items mt-4 flex flex-col items-center justify-center gap-3">
                  <WsAvatar photoURL={staff?.photoURL} size="large" data-testid="timecard-edit-avatar" />
                  <div className="text-lg font-semibold text-gray-500" data-testid="timecard-edit-name">
                    {staff?.displayName ?? 'unknown'}
                  </div>
                  <div className="mt-4 flex flex-row justify-between gap-2 sm:flex-col md:flex-row">
                    <RoomDropdown
                      name="roomId"
                      value={formData?.roomId ?? ''}
                      onChange={onChange}
                      data-testid="timecard-edit-room"
                    />
                    <WsDatepicker
                      name="clockedInDate"
                      label={t('timecards.clockedInLabel')}
                      value={formData.clockedInDate}
                      onChange={onChange}
                      data-testid="timecard-edit-clocked-in-date"
                    />
                    <TimeInput
                      name="clockedInTime"
                      label="&nbsp;"
                      value={formData.clockedInTime}
                      onChange={onChange}
                      data-testid="timecard-edit-clocked-in-time"
                    />
                    <WsDatepicker
                      name="clockedOutDate"
                      label={t('timecards.clockedOutLabel')}
                      value={formData.clockedOutDate}
                      onChange={onChange}
                      data-testid="timecard-edit-clocked-out-date"
                    />
                    <TimeInput
                      name="clockedOutTime"
                      label="&nbsp;"
                      value={formData.clockedOutTime}
                      onChange={onChange}
                      data-testid="timecard-edit-clocked-out-time"
                    />
                  </div>
                  <WsValidationError data-testid="timecard-edit-error" />
                </div>
                <Button
                  primary
                  onClick={onSaveLocal}
                  disabled={isSaving}
                  extraClasses="w-full text-center mt-4"
                  data-testid="timecard-edit-save"
                >
                  {t('timecards.saveAndUpdateButton')}
                </Button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
