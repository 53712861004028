/* eslint-disable i18next/no-literal-string */
import { AddIcon, Button, ButtonLink, DeleteIcon, Input, showToast, ToastTypeEnum } from '@wonderschool/common-base-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getChildListFromKinderSystems } from '../../../api/firebase/kinderSystems';
import { updateOrganizationLocations } from '../../../api/firebase/locations';
import { updateOrganization } from '../../../api/firebase/organizations';
import { usePrimaryLocation } from '../../../hooks/useLocations';
import { useOrganization } from '../../../hooks/useOrganizations';

const selectUserState = createSelector([(state) => state.user], (user) => user ?? {});

type FormInputs = {
  providerIDs: { id: string }[];
  password: string;
  agencyID: string;
  isEnabled: boolean;
};
interface KinderConnectCredentialsProps {
  onSave: (boolean) => void;
}

const KinderConnectCredentials: React.FC<KinderConnectCredentialsProps> = ({ onSave }) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const location = usePrimaryLocation();
  const user = useSelector(selectUserState);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      providerIDs: [{ id: '' }],
    },
  });
  const { isDirty, dirtyFields } = useFormState({ control });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'providerIDs',
  });

  const password = watch('password');

  useEffect(() => {
    const fetchCredentials = () => {
      try {
        const credentials = location?.integrations?.kinderSystems || null;
        if (credentials) {
          setValue('providerIDs', credentials.providerIDs?.map((id) => ({ id })) || [{ id: '' }]);
          setValue('password', credentials.password);
          setValue('agencyID', credentials.agencyID);
          setValue('isEnabled', credentials.isEnabled);
        }
      } catch (error) {
        console.error('Error fetching KinderSystems credentials:', error);
      } finally {
        setLoading(false);
      }
    };

    if (organization?.isKinderSystemsEnabled) {
      fetchCredentials();
    } else {
      setLoading(false);
    }
  }, [organization.id, user.defaultLocation, setValue, location, organization]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      setLoading(true);
      location.integrations = {
        ...location.integrations,
        kinderSystems: {
          ...location?.integrations?.kinderSystems,
          providerIDs: data.providerIDs.map((field) => field.id),
          password: data.password,
          agencyID: data.agencyID || '1',
          isEnabled: data.isEnabled ?? true,
        },
      };
      try {
        await getChildListFromKinderSystems({
          locationId: location.id,
          organizationId: organization.id,
          kinderSystemsConfig: { ...location.integrations.kinderSystems },
        });

        await updateOrganizationLocations(organization.id, [location]);
        await updateOrganization(organization.id, {
          isKinderSystemsEnabled: location?.integrations?.kinderSystems?.isEnabled ?? false,
        });
        showMessage('success');
        const providerOrPasswordFieldEdited =
          isDirty && Object.keys(dirtyFields).some((field) => field.includes('providerIDs') || field === 'password');
        onSave(providerOrPasswordFieldEdited); // Close the modal
        console.log('Credentials updated successfully');
      } catch (error) {
        console.error('Invalid credentials for KinderSystems:', error);
        showToast(ToastTypeEnum.Error, t('apps.kinderConnect.invalidCredentials'));
        setLoading(false);
        // set form errors
        data.providerIDs.forEach((_, index) => {
          setError(`providerIDs.${index}.id`, { type: 'custom', message: t('apps.kinderConnect.invalidCredentials') });
        });
        setError('password', { type: 'custom', message: t('apps.kinderConnect.invalidCredentials') });
      }
    } catch (error) {
      showMessage('error');
      console.error('Error updating KinderSystems credentials:', error);
    }
  };

  const showMessage = useCallback(
    (messageType) => {
      showToast(
        messageType === 'success' ? ToastTypeEnum.Success : ToastTypeEnum.Error,
        messageType === 'success'
          ? t('KinderSystems credentials updated successfully.')
          : t('Error updating KinderSystems credentials.')
      );
      setLoading(false);
    },
    [t]
  );

  return (
    <>
      <div className="max-w-md">
        <form className="space-y-4">
          {fields.map((field, index) => (
            <div key={field.id} className="flex items-center">
              <div className="basis-full">
                <Input
                  label={index === 0 ? t('Provider ID') : ''}
                  type="text"
                  placeholder={t('Provider ID')}
                  {...register(`providerIDs.${index}.id`, {
                    required: t('Provider ID is required'),
                    pattern: {
                      value: /^\d+$/,
                      message: t('Provider ID should only contain numbers'),
                    },
                  })}
                  error={errors.providerIDs?.[index]?.id?.message}
                />
              </div>
              {fields.length > 1 && (
                <ButtonLink
                  icon={<DeleteIcon className="h-5 w-5" />}
                  onClick={() => remove(index)}
                  aria-label={t('Remove Provider ID')}
                  extraClasses={`basis-1/12 p-0 align-middle ${index === 0 ? 'mt-7' : ''} ${errors.providerIDs?.[index]?.id?.message ? 'mb-7' : ''}`}
                />
              )}
            </div>
          ))}
          <ButtonLink
            onClick={() => append({ id: '' })}
            icon={<AddIcon className="h-4 w-4" />}
            content={t('apps.kinderConnect.addProviderId')}
            id="addProviderId"
          />
          <div>
            <Input
              label={t('Agency ID')}
              type="text"
              placeholder={t('Agency ID')}
              {...register('agencyID', {
                required: t('Agency ID is required'),
                pattern: {
                  value: /^\d+$/,
                  message: t('Agency ID should only contain numbers'),
                },
              })}
              error={errors.agencyID?.message}
            />
          </div>

          <div>
            <Input
              label={t('common.passwordLabel')}
              type={showPassword ? 'text' : 'password'}
              placeholder={t('common.passwordLabel')}
              {...register('password', { required: t('Password is required') })}
              error={errors.password?.message}
            />
            {password && (
              <div>
                <ButtonLink
                  onClick={() => setShowPassword(!showPassword)}
                  content={showPassword ? t('Hide Password') : t('Show Password')}
                  id="showPassword"
                />
              </div>
            )}
          </div>

          <Button
            primary
            loading={loading}
            disabled={loading}
            data-testid="kinderSystem-button"
            label={t('common.login')}
            onClick={handleSubmit(onSubmit)}
            extraClasses="w-full"
          />
        </form>
      </div>
    </>
  );
};

export default KinderConnectCredentials;
