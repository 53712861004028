import { Trans, useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Modal } from 'semantic-ui-react';
import { RouteNameEnum, useRoutes } from '../../../navigation';

import { ModalProps } from '../../../common';

type StudentAddedModalProps = ModalProps & {
  student: any;
};

export default function StudentAddedModal({ student, isOpen, onClose }: StudentAddedModalProps) {
  const { t } = useTranslation();
  const { gotoRouteByName } = useRoutes();

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose} closeOnDimmerClick={false} closeOnEscape={false}>
      <Modal.Header data-testid="student-added-modal-header">{t('students.studentAddedModalHeader')}</Modal.Header>
      <Modal.Content>
        <Trans
          i18nKey="students.studentAddedModalContent"
          values={{ studentName: student?.displayName ?? '' }}
          components={{ strong: <strong /> }}
        />
      </Modal.Content>
      <Modal.Actions>
        <div>
          <Button
            fluid
            primary
            icon="checkmark"
            labelPosition="right"
            content={t('students.studentAddedModalYesButton')}
            onClick={() => {
              if (onClose) onClose();
              gotoRouteByName(RouteNameEnum.STUDENT_ENROLLMENT, [{ name: 'studentId', value: student.id }]);
            }}
            data-testid="continue-to-digital-enrollment-btn"
          />
          <br />
          <Button
            fluid
            content={t('students.studentAddedModalNoButton')}
            onClick={() => {
              if (onClose) onClose();
              gotoRouteByName(RouteNameEnum.STUDENTS_FILTER, [{ name: 'filter', value: 'all' }]);
            }}
            data-testid="return-to-student-list-btn"
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
}
