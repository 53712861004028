import IframeResizer from 'iframe-resizer-react';
import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Container } from 'semantic-ui-react';
import { getTitlePrefix } from '../config/env';
import { channelIdSelected, useMarketplaceHeadless } from '../integrations';
import { RouteNameEnum, useRoutes } from '../navigation';
import { default as ErrorComponent } from './Error';

type RedirectMessage = {
  type: string;
  to: string;
  params: { [key: string]: string };
};

type IFrameMessage = 'request_tokens' | RedirectMessage;

type MarketplaceExternalProps = {
  url: string;
  routeName: RouteNameEnum;
};

const MarketplaceExternal = (props: MarketplaceExternalProps) => {
  const { gotoRouteByName } = useRoutes();
  const dispatch = useDispatch();
  const headlessConfig = useMarketplaceHeadless();
  const { t } = useTranslation();
  const iframeRef = useRef<any>(null);

  if (!headlessConfig?.refreshToken) return <ErrorComponent />;

  const url = createMarketplaceURLWithAuth(props.url, headlessConfig.refreshToken);

  const onMessage = ({ message }: { message: IFrameMessage }) => {
    if (message === 'request_tokens') {
      iframeRef.current.sendMessage(headlessConfig);
      return;
    }

    if (message && message.type === 'redirect' && message.to === 'messages') {
      const channelId = message.params.channel_id
        ? message.params.channel_id.replace('sendbird_group_channel_', '')
        : '';

      dispatch(channelIdSelected(channelId));

      gotoRouteByName(RouteNameEnum.MESSAGES);
      return;
    }
  };

  const renderPageHead = () => {
    const translatedPage = t(props.routeName);
    return (
      <Helmet>
        <title>
          {getTitlePrefix()}
          {`${translatedPage} - Wonderschool`}
        </title>
        <body className="organizations" />
      </Helmet>
    );
  };

  return (
    <Container>
      {renderPageHead()}
      <IframeResizer
        src={url}
        style={{ border: 'none' }}
        scrolling="omit"
        // WXGA resolution
        minHeight={800}
        // UHD resolution
        maxHeight={2160}
        heightCalculationMethod="lowestElement"
        forwardRef={iframeRef}
        onMessage={onMessage}
        width="100%"
      />
    </Container>
  );

  function createMarketplaceURLWithAuth(route: string, token: string) {
    if (!route) throw new Error('No URL provided');
    if (!token) throw new Error('No token provided');

    const marketplaceURL = new URL(route);

    marketplaceURL.searchParams.append('token', token);

    return marketplaceURL.toString();
  }
};

export default MarketplaceExternal;
