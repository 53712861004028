import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../helpers/utils';
import './FinancialSummary.scss';
import { ReportTitleEnum } from './enums';
import { FinancialSummaryOverviewCardProps } from './types';
const FinancialSummaryOverviewCard: React.FC<FinancialSummaryOverviewCardProps> = ({ amount, label }) => {
  const { t } = useTranslation();
  const cardClass =
    label === ReportTitleEnum.TOTAL_PROFIT
      ? amount < 0
        ? 'negativeCard'
        : amount === 0
          ? 'normalCard'
          : 'positiveCard'
      : 'normalCard';
  return (
    <>
      <div data-testid={label} className={cardClass}>
        <span className="card-amount">{formatCurrency(amount, { precision: 2 })}</span>
        <span className="card-label">{t(label)}</span>
      </div>
    </>
  );
};
export default FinancialSummaryOverviewCard;
