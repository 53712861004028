import { NoSymbolIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { WsCheckmarkCircle } from '../../../icons';
import { HealthCheckTooltip } from './HealthCheckTooltip';

import { ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon } from '@wonderschool/common-base-ui';

import { HealthCheckType } from '../types';

type AttendanceBadgeProps = {
  time?: string;
  isAbsent?: boolean;
  emptyText?: string;
  healthCheck?: HealthCheckType;
};
export function CheckInBadge({ time, isAbsent = false, emptyText = '-', healthCheck }: AttendanceBadgeProps) {
  const { t } = useTranslation();

  const justify = healthCheck ? 'justify-between' : 'justify-start';

  const badgeClasses = `flex flex-row ${justify} bg-slate-200 rounded-full px-2 py-2 ${healthCheck ? 'w-28' : 'w-24'}`;

  return (
    <div className={!time ? '' : badgeClasses}>
      {isAbsent ? (
        <>
          <NoSymbolIcon className="stroke-4 h-5 w-5 text-red-600" />
          <span className="pl-1" data-testid="badge-label">
            {t('Absent')}
          </span>
        </>
      ) : !time ? (
        <span data-testid="badge-label">{emptyText}</span>
      ) : (
        <>
          <ArrowRightOnRectangleIcon className="stroke-4 h-5 w-5 text-green-700" />
          <span className="pl-1" data-testid="badge-label">
            {time || emptyText}
          </span>
          {!!healthCheck && (
            <HealthCheckTooltip healthCheck={healthCheck}>
              <WsCheckmarkCircle className="ml-1 h-5 w-5 text-green-700" data-testid="badge-health-check-icon" />
            </HealthCheckTooltip>
          )}
        </>
      )}
    </div>
  );
}

export function CheckOutBadge({ time, isAbsent = false, emptyText = '-' }: AttendanceBadgeProps) {
  return isAbsent || !time ? (
    <span data-testid="badge-label">{emptyText}</span>
  ) : (
    <div className="w-24 rounded-full bg-slate-200 px-2 py-2">
      <ArrowLeftOnRectangleIcon className="stroke-4 h-5 w-5 text-green-700" />
      <span className="pl-1" data-testid="badge-label">
        {time || emptyText}
      </span>
    </div>
  );
}
