import { STATS_FETCHED } from '../actions/types';

export type StatsReducerState = {
  selectedStat: {
    id?: string;
  };
  count: number;
  list: {
    id: string;
  }[];
};

const initialState: StatsReducerState = {
  selectedStat: {},
  count: 0,
  list: [],
};

const stats = (state = initialState, action) => {
  switch (action.type) {
    case STATS_FETCHED:
      return {
        ...state,
        count: action.stats.count,
        list: action.stats.list,
      };

    default:
      return state;
  }
};

export default stats;
