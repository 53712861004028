import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewComponentProps } from '../../../common';
import { dateFormatter } from '../../../helpers/dates';
import { capitalize, currencyFormatter } from '../../../helpers/utils';
import { TuitionAndFeesType } from '../../types';

import { SummaryViewTitle } from '../widgets';

type EnrollmentInfoViewProps = ViewComponentProps & {
  student: any;
  tuitionAndFees: TuitionAndFeesType;
};

const EnrollmentInfoView: React.FC<EnrollmentInfoViewProps> = ({ student, tuitionAndFees, title, hasTitle = true }) => {
  const { t } = useTranslation();
  const viewTitle = hasTitle ? t(title || 'enrollments.enrollmentInfoTitle') : '';

  const tuition = useMemo(() => {
    if (!tuitionAndFees.tuition.amount) return t('N/A');
    return currencyFormatter(tuitionAndFees.tuition.amount / 100, { precision: 0 });
  }, [tuitionAndFees.tuition.amount, t]);

  const billingInterval = useMemo<string>(() => {
    return tuitionAndFees.tuition.billingInterval ? t(capitalize(tuitionAndFees.tuition.billingInterval)) : t('N/A');
  }, [t, tuitionAndFees.tuition.billingInterval]);

  return (
    <div className="enrollment-info-view" data-testid="enrollment-info-view">
      <SummaryViewTitle title={viewTitle} />
      <div className="ws-view-form">
        <div className="row">
          <label>{t('enrollments.enrollmentInfoStartDateLabel')}</label>
          <div className="value">{dateFormatter(student.enrollment?.startDate) ?? t('N/A')}</div>
        </div>
        <div className="row">
          <label>{t('enrollments.enrollmentInfoBillingIntervalLabel')}</label>
          <div className="value">{billingInterval}</div>
        </div>
        <div className="row">
          <label>{t('enrollments.enrollmentInfoTuitionLabel')}</label>
          <div className="value">{tuition}</div>
        </div>
        <div className="row">
          <label>{t('enrollments.enrollmentInfoFeesLabel')}</label>
          <div className="value">
            {tuitionAndFees.fees.map((fee, index) => {
              const feeAmount =
                fee?.amount !== undefined ? currencyFormatter(fee.amount / 100, { precision: 0 }) : t('N/A');
              return <div key={index}>{`${feeAmount} ${fee.name}`}</div>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EnrollmentInfoView;
