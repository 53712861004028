import { Button, CurrencyInput, Dialog } from '@wonderschool/common-base-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BillingIntervalEnum } from '../../enums';

type EditProrateAmountModalProps = {
  amount: number;
  billingInterval: string;
  enrollmentDate: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (amount) => void;
};

const EditProrateAmountModal = ({
  amount,
  billingInterval,
  enrollmentDate,
  isOpen,
  onClose,
  onSave,
}: EditProrateAmountModalProps) => {
  const { t } = useTranslation();
  const [fixedAmount, setFixedAmount] = useState<number>(amount);

  useEffect(() => {
    setFixedAmount(amount);
  }, [amount]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave(fixedAmount);
  };

  const handleAmountChange = ({ target }) => {
    const value = target.value.replace(',', '');
    if (value) {
      setFixedAmount(parseFloat(value));
    }
  };

  const getIntervalText = () => {
    switch (billingInterval) {
      case BillingIntervalEnum.WEEKLY:
        return t('enrollments.billingPeriod.week');
      case BillingIntervalEnum.BIWEEKLY:
        return t('enrollments.billingPeriod.biweek');
      case BillingIntervalEnum.MONTHLY:
        return t('enrollments.billingPeriod.month');
    }
    return '';
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} hasCloseButton>
      <div className="px-4 pb-4 pt-10">
        <h1 className="pb-8 text-center text-xl">{t('enrollments.editProrateAmount.header')}</h1>
        <div className="flex flex-col gap-4">
          <div className="text-base">
            {t('enrollments.editProrateAmount.content', {
              period: getIntervalText(),
              date: enrollmentDate,
            })}
          </div>
          <div>
            <CurrencyInput label="Prorated amount" value={fixedAmount.toString()} onChange={handleAmountChange} />
          </div>
        </div>
        <div className="mb-4 mt-8 flex justify-center gap-6">
          <Button onClick={handleClose}>{t('common.cancel')}</Button>
          <Button primary onClick={handleSave}>
            {t('common.update')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditProrateAmountModal;
