import firebase from 'firebase/compat/app';
import { StudentsWeeklyMealsType } from '../../common';
import { ReportTypeEnum } from '../enums';
import { ActivitiesFiltersType, ActivitiesReportType, ActivitiesTableProps, ReportType } from '../types';

export type AttendanceFiltersType = ActivitiesFiltersType & {
  isEnrolledInFoodProgram: boolean;
};

export type AttendanceTableProps = ActivitiesTableProps<AttendanceFiltersType>;

export type AttendanceActivityType = any; // yes, this is BS

export type HealthCheckType = {
  wasExposed?: boolean;
  symptoms?: string;
  notes?: string;
  formUrl?: string;
};

export type AttendanceType = {
  activityId: string;
  studentId: string;
  studentName: string;
  roomName: string;
  checkInDate?: string;
  checkInTime?: string;
  checkInTimeSort?: string;
  checkInName: string;
  checkInSignatureUri: string;
  checkOutDate?: string;
  checkOutTime?: string;
  checkOutTimeSort: string;
  checkOutName: string;
  checkOutSignatureUri: string;
  isAbsent?: boolean;
  healthCheck?: HealthCheckType;
  note?: string;
  kinderSystemsTransferred?: KinderSystemsType;
};
export type StudentAttendanceTotalsType = {
  studentId: string;
  studentName: string;
  roomName: string;
  daysPresent: number;
  daysAbsent: number;
  totalMinutesAttended: number;
};

export type DailyAttendanceReportType = ActivitiesReportType<AttendanceType>;
export type AttendanceTotalsReportType = ActivitiesReportType<StudentAttendanceTotalsType>;
export type FoodProgramReportType = ReportType & {
  reportType: ReportTypeEnum.FOOD_PROGRAM_ATTENDANCE;
  studentsWeeklyMeals: StudentsWeeklyMealsType;
  students: any[];
  weeks: string[];
};

export enum KINDERSYSTEMS_REPORTS_STATUS {
  TRANSFERRED = 'Transferred',
  ERROR = 'Error',
  OUT_OF_SYNC = 'Out of Sync',
}

export type KinderSystemsType = {
  transferredAt: firebase.firestore.Timestamp;
  kinderSystemsReportId?: string | null;
  status: KINDERSYSTEMS_REPORTS_STATUS;
  statusReason?: string | null;
  transferHistory?: {
    status: KINDERSYSTEMS_REPORTS_STATUS;
    transferredAt: firebase.firestore.Timestamp;
    statusReason?: string | null;
    kinderSystemsReportId?: string | null;
  }[];
};
