import classNames from 'classnames/bind';
import fStyle from './PaymentTooltip.scss';

const fx = classNames.bind(fStyle);
function PaymentTooltip({ title, tooltipText }) {
  return (
    <span className={fx('tooltiptext')} data-testid={title}>
      <span>
        {title && (
          <span>
            <strong>{title}</strong>
            <br />
          </span>
        )}
        {tooltipText}
      </span>
    </span>
  );
}

export default PaymentTooltip;
