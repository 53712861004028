import { Redirect } from 'react-router-dom';
import WSA from '../../../WSA';
import { paths } from '../../paths';
import LoadingRoute from './LoadingRoute';

const GuestRoute = ({ redirect, children }) => {
  const { isAuthenticated } = WSA.Components.useWSAuth();

  return <LoadingRoute>{!isAuthenticated ? children : <Redirect to={redirect || paths.home} />}</LoadingRoute>;
};
export default GuestRoute;
