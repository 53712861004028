import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment-timezone';

import { logError } from '../../../rollbar';
import { getSignatureImage } from '../attendanceUtils';
import { AttendanceType, DailyAttendanceReportType } from '../types';
import { initializeAttendancePDFDoc } from './pdfUtils';

const COLUMNS = [
  { header: 'Student Name', dataKey: 'studentName' },
  { header: 'Room', dataKey: 'roomName' },
  { header: 'Date', dataKey: 'checkInDate' },
  { header: 'Check in', dataKey: 'checkInTime' },
  { header: 'Signee', dataKey: 'checkInName' },
  { header: 'Signature', dataKey: 'checkInSignature' },
  { header: 'Date', dataKey: 'checkOutDate' },
  { header: 'Check out', dataKey: 'checkOutTime' },
  { header: 'Signee', dataKey: 'checkOutName' },
  { header: 'Signature', dataKey: 'checkOutSignature' },
];

export async function generateDailyAttendancePDF(
  dailyAttendanceReport: DailyAttendanceReportType
): Promise<{ filename: string; pdf: jsPDF }> {
  const { location, items: dailyAttendance, organization } = dailyAttendanceReport;

  const startDate = moment(dailyAttendanceReport.startDate).format('DD_MM_YYYY');
  const endDate = moment(dailyAttendanceReport.endDate).format('DD_MM_YYYY');

  const filename = `Attendance Daily (${startDate} - ${endDate}).pdf`;
  const title = `Check in/Check out Report for ${location?.name || organization?.name || 'Unknown'}`;
  const pdf = initializeAttendancePDFDoc(title, dailyAttendanceReport);

  const body = await createPDFBody(dailyAttendance);

  let prevColor = 248;
  let prevIndex = 0;
  autoTable(pdf, {
    columns: COLUMNS,
    body: body,
    startY: 65,
    margin: { left: 6 },
    columnStyles: {
      studentName: { cellWidth: 18 },
      roomName: { cellWidth: 20 },
      checkInDate: { cellWidth: 22 },
      checkInTime: { cellWidth: 18 },
      checkInName: { cellWidth: 20 },
      checkInSignature: { cellWidth: 20 },
      checkOutDate: { cellWidth: 22 },
      checkOutTime: { cellWidth: 18 },
      checkOutName: { cellWidth: 20 },
      checkOutSignature: { cellWidth: 20 },
    },
    didParseCell: (data) => {
      if (data.section === 'body') {
        if (prevIndex == data.row.index) {
          data.cell.styles.fillColor = prevColor;
        } else {
          prevIndex = data.row.index;
          if (data.row.raw['studentName']) {
            prevColor = prevColor === 255 ? 248 : 255;
          }
          data.cell.styles.fillColor = prevColor;
        }
      }
    },
    didDrawCell: (data) => {
      if (data.section === 'body' && data.cell.raw && data.cell.raw !== '-') {
        if (data?.column?.dataKey === 'checkOutSignature' || data?.column?.dataKey === 'checkInSignature') {
          try {
            // * Convert the canvas to a JPEG data URL
            const canvas = data.cell.raw as HTMLCanvasElement;
            const jpegDataUrl = canvas.toDataURL('image/jpeg', 0.75);
            pdf.addImage(jpegDataUrl, 'JPEG', data.cell.x, data.cell.y, data.cell.width, data.cell.height);
          } catch (error) {
            const signatureKey = data?.column?.dataKey;
            logError(`Unable to render ${signatureKey}`, error, {
              ...data.row.raw,
              [signatureKey]: data.row.raw[signatureKey].toString(),
            });
          }
        }
      }
    },
  });
  return { filename, pdf };
}

async function createPDFBody(dailyAttendance: AttendanceType[]): Promise<any[]> {
  const pdfBody: any[] = [];

  for (const attendance of dailyAttendance) {
    const doc = {
      studentName: attendance.studentName || '-',
      roomName: attendance.roomName || '-',
      checkInDate: attendance.checkInDate || '-',
      checkInTime: attendance.isAbsent ? 'Absent' : attendance.checkInTime || '-',
      checkInName: attendance.checkInName || '',
      checkInSignature: attendance.checkInSignatureUri ? await getSignatureImage(attendance.checkInSignatureUri) : '-',
      checkOutDate: attendance.checkOutDate || '-',
      checkOutTime: attendance.isAbsent ? 'Absent' : attendance.checkOutTime || '-',
      checkOutName: attendance?.checkOutName || '-',
      checkOutSignature: attendance?.checkOutSignatureUri
        ? await getSignatureImage(attendance.checkOutSignatureUri)
        : '-',
    };
    pdfBody.push(doc);

    if (attendance.healthCheck) {
      const symptoms = attendance.healthCheck?.symptoms || 'No Symptoms';
      const notes = attendance.healthCheck?.notes;
      const content = !notes ? symptoms : `${symptoms}\n${notes}`;
      pdfBody.push([
        {
          content: '',
          colSpan: 1,
        },
        {
          content: 'Health Check:',
          colSpan: 2,
          styles: { fontStyle: 'bold', halign: 'right' },
        },
        {
          content: content,
          colSpan: 6,
          styles: { fontStyle: 'normal' },
        },
      ]);
    }

    if (attendance.note) {
      pdfBody.push([
        {
          content: '',
          colSpan: 1,
        },
        {
          content: 'Note:',
          colSpan: 2,
          styles: { fontStyle: 'bold', halign: 'right' },
        },
        {
          content: attendance.note,
          colSpan: 6,
          styles: { fontStyle: 'normal' },
        },
      ]);
    }
  }
  return pdfBody;
}
