import { getDocumentsAsArray } from '../../../../api/firebase/firestore';
import { logError } from '../../../../rollbar';
import { FinancialSummaryFiltersType } from './types';
const paths = {
  transactions: (organizationId: string) => `organizations/${organizationId}/transactions`,
};
export async function fetchTransactionsForFinancialSummary(
  filters: FinancialSummaryFiltersType
): Promise<{ id: string }[]> {
  try {
    const transactions = await getDocumentsAsArray({
      path: paths.transactions(filters.organizationId),
      conditions: filters.searchConditions,
      orderBy: [{ field: 'date', direction: 'desc' }],
    });
    return transactions.list ?? [];
  } catch (error) {
    logError(error);
    throw error;
  }
}
