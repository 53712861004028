import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Button, Form, Grid } from 'semantic-ui-react';

import { DefaultDateFormat, formatLocaleDate } from '../../helpers/dates';
import LocationPicker from '../Locations/LocationPicker';
import RoomPicker from '../Rooms/RoomPicker';
import { DatePicker } from '../Shared/DatePicker';

const useStudentList = ({ filters }) => {
  let students = useSelector((state) => state.students?.list) || [];
  // Filter students by room.
  if (filters.room) {
    students = students.filter((student) => student?.rooms?.includes(filters.room));
  }
  return { students };
};

const AttendanceFilters = ({ generateReport, loading }) => {
  const { t } = useTranslation();

  const RESET_FILTERS = {
    timePeriod: {
      from: null,
      to: null,
    },
    room: null,
    student: null,
    reportType: null,
    location: null,
  };

  const [filters, setFilters] = useState(RESET_FILTERS);
  const [enableButton, setEnableButton] = useState(false);
  const { students } = useStudentList({ filters });

  const reportsType = [
    { text: t('Daily Check in/Check out'), value: 'attendance_report' },
    { text: t('Total Attendance'), value: 'total_attendance_report' },
  ];

  const handleResetFilters = () => {
    setFilters(RESET_FILTERS);
  };

  const from = formatLocaleDate(filters.timePeriod.from);
  const to = formatLocaleDate(filters.timePeriod.to);

  // when the location changes, the room filter needs to be reset
  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      room: null,
    }));
  }, [filters.location, setFilters]);
  // when the room changes, the students filter needs to be reset
  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      student: null,
    }));
  }, [filters.room, setFilters]);

  useEffect(() => {
    const disable = filters.timePeriod.to && filters.timePeriod.from && filters.reportType && filters.location;
    setEnableButton(!!disable);
  }, [filters]);

  return (
    <Grid stackable columns={4}>
      <Grid.Row>
        <Grid.Column>
          <Form.Field>
            <DatePicker
              fluid
              id="from"
              name="from"
              value={from}
              onChange={(_, { value }) =>
                setFilters((prev) => ({
                  ...prev,
                  timePeriod: {
                    ...prev.timePeriod,
                    from: moment(value, DefaultDateFormat),
                  },
                }))
              }
              label={t('Start Date')}
              placeholder={t('Start Date')}
              maxDate={filters.timePeriod.to ? filters.timePeriod.to.clone().subtract(1, 'day') : moment()}
              closable
              disabled={loading}
              data-testid="att-from-date"
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <DatePicker
              fluid
              id="to"
              name="to"
              value={to}
              onChange={(_, { value }) =>
                setFilters((prev) => ({
                  ...prev,
                  timePeriod: {
                    ...prev.timePeriod,
                    to: moment(value, DefaultDateFormat),
                  },
                }))
              }
              label={t('End Date')}
              placeholder={t('End Date')}
              minDate={filters.timePeriod.from}
              maxDate={moment()}
              closable
              disabled={loading}
              data-testid="att-to-date"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Dropdown
            id="report_type"
            name="report"
            label={t('Report Type')}
            placeholder={t('Select Report')}
            value={filters.reportType}
            clearable={false}
            selection
            search
            required
            fluid
            onChange={(_, { value }) => setFilters((prev) => ({ ...prev, reportType: value }))}
            disabled={loading}
            options={reportsType}
            selectOnBlur={false}
            renderLabel={(label) => ({
              color: 'yellow',
              content: label.text,
            })}
            data-testid="att-report-type"
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <LocationPicker
              id="location"
              name="location"
              label={t('Location')}
              placeholder={t('Select location')}
              value={filters.location}
              clearable={false}
              selection
              search
              required
              fluid
              onChange={(_, { value }) => setFilters((prev) => ({ ...prev, location: value }))}
              disabled={loading}
              data-testid="att-location"
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <RoomPicker
              fluid
              selection
              search
              clearable
              label={t('Room')}
              value={filters.room}
              location={filters.location}
              placeholder={t('Select room')}
              onChange={(value) => setFilters((prev) => ({ ...prev, room: value }))}
              disabled={loading || !filters?.location}
              data-testid="att-room-picker"
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field>
            <Form.Dropdown
              id="student"
              name={'student'}
              label={t('Student')}
              placeholder={t('All students')}
              value={filters.student}
              selection
              search
              required
              fluid
              selectOnBlur={false}
              clearable
              options={students.map((student) => ({
                text: student.displayName,
                value: student.id,
              }))}
              onChange={(_, { value }) => setFilters((prev) => ({ ...prev, student: value }))}
              disabled={loading || students?.length === 0}
              data-testid="att-select-students"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column relaxed="very">
          <Button
            floated="left"
            onClick={() => generateReport(filters)}
            primary={enableButton}
            disabled={!enableButton}
            data-testid="att-create-report-btn"
          >
            {loading ? t('Loading...') : t('Create Report')}
          </Button>
          {enableButton ? (
            <Button
              floated="left"
              color="blue"
              onClick={handleResetFilters}
              basic
              disabled={!enableButton}
              data-testid="att-basicclear-all-btn"
            >
              {loading ? t('Loading...') : t('Clear All')}
            </Button>
          ) : (
            <Button floated="left" onClick={handleResetFilters} disabled data-testid="att-clear-all-btn">
              {loading ? t('Loading...') : t('Clear All')}
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AttendanceFilters;
