import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Modal } from 'semantic-ui-react';

export default function ExistingStaffDialog({ existingContact, isOpen, onClose }) {
  const { t } = useTranslation();

  if (!existingContact) return null;

  if (!onClose) throw new Error('onClose is required');

  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Header data-testid="existing-staff-member-found-title">{t('Existing Staff Member Found')}</Modal.Header>
      <Modal.Content>
        <p>
          {t('A staff member with the email {{email}} is already a member of this organization.', {
            email: existingContact.email,
          })}
        </p>
        <p>{t('They cannot be added as a family member.')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onClose} data-testid="ok-btn">
          {t('Okay')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
