import { Component } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Container, Segment, Sidebar } from 'semantic-ui-react';

import FooterNavigation from '../../navigation/components/FooterNavigation';
import MobileNavigation from '../../navigation/components/MobileNavigation';

class MobileContainer extends Component {
  state = {};

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher dimmed={sidebarOpened} onClick={this.handlePusherClick} className="mobile-container">
          <Segment vertical className="navbar no-padding">
            <Container className="no-padding no-margin">
              <MobileNavigation handleToggle={this.handleToggle} />
            </Container>
          </Segment>

          <div id="content">{children}</div>

          <FooterNavigation viewport="mobile" />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
export default MobileContainer;
