import { Dispatch } from 'redux';
import * as devices from '../../api/firebase/devices';
import {
  DEVICES_FETCHED,
  DEVICE_ADDED,
  DEVICE_ADDED_TO_LIST,
  DEVICE_REFRESHED,
  DEVICE_REMOVE_FROM_LIST,
  DEVICE_SELECTED,
  DEVICE_SELECTION_CLEARED,
  DEVICE_SET,
  DEVICE_UPDATED,
} from './types';

export const devicesFetched = (devices) => ({
  type: DEVICES_FETCHED,
  devices,
});

export const deviceRefreshed = (device) => ({
  type: DEVICE_REFRESHED,
  device,
});

export const deviceAdded = () => ({
  type: DEVICE_ADDED,
});

export const deviceSet = () => ({
  type: DEVICE_SET,
});

export const deviceUpdated = () => ({
  type: DEVICE_UPDATED,
});

export const deviceSelected = (device) => ({
  type: DEVICE_SELECTED,
  device,
});

export const deviceSelectionCleared = () => ({
  type: DEVICE_SELECTION_CLEARED,
});

export const deviceAddedToList = (device) => ({
  type: DEVICE_ADDED_TO_LIST,
  device,
});

export const deviceRemovedFromList = (id: string) => ({
  type: DEVICE_REMOVE_FROM_LIST,
  id,
});

export const getOrganizationDevices =
  (organizationId: string, orderBy: string[] = [], limit: number = 0, last?: number) =>
  async (dispatch: Dispatch) => {
    try {
      const devicesData = await devices.getOrganizationDevices(organizationId, orderBy, limit, last);
      return dispatch(devicesFetched(devicesData));
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      throw Error(errorMessage);
    }
  };

export const newOrganizationDevice = (organizationId: string) => (dispatch: Dispatch) => {
  try {
    const device = devices.newOrganizationDevice(organizationId);
    // Preselect device.
    return dispatch(deviceSelected({ id: device.id }));
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    throw Error(errorMessage);
  }
};

export const setOrganizationDevice = (organizationId: string, data: unknown) => async (dispatch: Dispatch) => {
  try {
    await devices.setOrganizationDevice(organizationId, data);
    await getOrganizationDevices(organizationId);
    return dispatch(deviceSet());
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    throw Error(errorMessage);
  }
};

export const deleteOrganizationDevice = (organizationId: string, deviceId: string) => async (dispatch: Dispatch) => {
  try {
    // Immediately remove from Redux store.
    dispatch(deviceRemovedFromList(deviceId));
    await getOrganizationDevices(organizationId);
    return devices.deleteOrganizationDevice(organizationId, deviceId);
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    throw Error(errorMessage);
  }
};

export const refreshOrganizationDevice = (organizationId: string, deviceId: string) => async (dispatch: Dispatch) => {
  try {
    const device = await devices.getOrganizationDevice(organizationId, deviceId);
    return dispatch(deviceRefreshed(device));
  } catch (error: unknown) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    throw Error(errorMessage);
  }
};
