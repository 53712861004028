import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTACT_STATUS } from '@wonderschool/ws-types';
import { SortDirectionEnum, SortDirectionType, WsAvatar, WsSortButton, sortCollection } from '../../common';
import { TotalHoursType } from '../types';

type TotalHoursTableProps = {
  totalHours: TotalHoursType[];
};

const TotalHoursTable: React.FC<TotalHoursTableProps> = ({ totalHours }) => {
  const { t } = useTranslation();

  const [totalHoursSorted, setTotalHoursSorted] = useState<TotalHoursType[]>(totalHours);

  const [sortDirection, setSortDirection] = useState<SortDirectionType>({
    name: '',
    direction: SortDirectionEnum.NONE,
  });

  useEffect(() => {
    if (!totalHours) return;
    setTotalHoursSorted(sortCollection(totalHours, sortDirection) as TotalHoursType[]);
  }, [totalHours, sortDirection]);

  const onSort = useCallback((name: string, direction: SortDirectionEnum) => {
    setSortDirection({ name, direction: direction });
  }, []);

  const headerClasses = 'px-6 py-3 text-left text-base font-medium text-gray-800 tracking-wider';
  type WsTableHeaderProps = {
    sortKey?: string;
    label?: string;
  };

  const WsTableHeader = useCallback(
    ({ sortKey, label }: WsTableHeaderProps) => {
      return (
        <th scope="col" className={headerClasses}>
          <div className="flex flex-row items-center">
            {!!label && t(label)}{' '}
            {!!sortKey && <WsSortButton name={sortKey} onSort={onSort} sortDirection={sortDirection} />}
          </div>
        </th>
      );
    },
    [onSort, sortDirection, t, headerClasses]
  );
  return (
    <div className="mt-4 w-full overflow-x-scroll sm:overflow-x-hidden">
      <table className="w-full" data-testid="total-hours-table">
        <thead className="bg-gray-100" data-testid="total-hours-thead">
          <tr className="border-y-2 border-gray-300">
            <WsTableHeader />
            <WsTableHeader label="Name" sortKey="displayName" data-testid="sort-name" />
            <WsTableHeader label="Rooms" sortKey="rooms" data-testid="sort-room" />
            <WsTableHeader label="timecards.startDateLabel" sortKey="startDate" data-testid="sort-start-date" />
            <WsTableHeader label="timecards.endDateLabel" sortKey="endDate" data-testid="sort-end-date" />
            <WsTableHeader label="timecards.totalHoursLabel" sortKey="totalHoursSort" data-testid="total-hours-sort" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-300 overflow-x-scroll">
          {totalHoursSorted.map((totalHoursRow) => {
            return (
              <tr
                key={totalHoursRow.contactId}
                id={totalHoursRow.contactId}
                data-testid={`total-hours-tr-${totalHoursRow.contactId}`}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  <WsAvatar photoURL={totalHoursRow?.photoURL} size="small" />
                </td>
                <td
                  className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                  data-testid={`total-hours-name-${totalHoursRow.contactId}`}
                >
                  {totalHoursRow.displayName}
                  {totalHoursRow.status === CONTACT_STATUS.deleted && (
                    <>
                      <br />
                      <span className="italic text-gray-600">({t('common.deactivated')})</span>
                    </>
                  )}
                </td>
                <td
                  className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                  data-testid={`total-hours-rooms-${totalHoursRow.contactId}`}
                >
                  {totalHoursRow.rooms}
                </td>
                <td
                  className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                  data-testid={`total-hours-start-date-${totalHoursRow.contactId}`}
                >
                  {totalHoursRow.startDate}
                </td>
                <td
                  className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                  data-testid={`total-hours-end-date-${totalHoursRow.contactId}`}
                >
                  {totalHoursRow.endDate}
                </td>
                <td
                  className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                  data-testid={`total-hours-${totalHoursRow.contactId}`}
                >
                  {totalHoursRow.totalHours}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TotalHoursTable;
