import { createMedia } from '@artsy/fresnel';
import PropTypes from 'prop-types';

import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';

// Replace deprecated <Responsive> component
// https://github.com/Semantic-Org/Semantic-UI-React/pull/4008

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

// https://github.com/artsy/fresnel#basic-example
const ResponsiveContainer = ({ children }) => (
  <div>
    <style>{mediaStyles}</style>
    <MediaContextProvider>
      <Media at="mobile">
        <MobileContainer>{children}</MobileContainer>
      </Media>
      <Media greaterThanOrEqual="tablet">
        <DesktopContainer>{children}</DesktopContainer>
      </Media>
    </MediaContextProvider>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveContainer;
