import { Dispatch } from 'redux';
import { organizationUsersOnListen } from '../../api/firebase/users';
import { ORGANIZATION_USERS_FETCHED } from './types';

export const organizationUsersFetched = (users: unknown) => ({
  type: ORGANIZATION_USERS_FETCHED,
  users,
});

export const startOrganizationUsersListener = (organizationId: string) => (dispatch: Dispatch) => {
  return organizationUsersOnListen(
    organizationId,
    (users) => {
      if (users) {
        users.list?.forEach((user) => {
          if (!user.uid) user.uid = user.id;
        });
        dispatch(organizationUsersFetched(users));
      }
    },
    (error) => console.log(error.message)
  );
};
