import { OrganizationProgramTypeEnum } from '@wonderschool/common-base-types';
import {
  MultiSelectProps,
  Option,
  Select,
  SingleSelectProps,
  isMultiSelect,
  isSingleSelect,
} from '@wonderschool/common-base-ui';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type OrganizationProgramTypeSelectProps = Omit<MultiSelectProps | SingleSelectProps, 'options'>;

export const OrganizationProgramTypeSelect: FC<OrganizationProgramTypeSelectProps> = (selectProps) => {
  const { t } = useTranslation();

  const programTypeOptions: Option[] = useMemo(
    () => [
      ...Object.values(OrganizationProgramTypeEnum).map((programType) => ({
        label: t(`common.organizationProgramType.${programType}`),
        value: programType,
      })),
    ],
    [t]
  );

  if (isMultiSelect(selectProps)) {
    return (
      <Select
        {...selectProps}
        multiple
        label={t('common.organizationProgramType', { count: 100 })}
        placeholder={t('common.all')}
        options={programTypeOptions}
      />
    );
  }

  if (isSingleSelect(selectProps)) {
    return (
      <Select
        {...selectProps}
        label={t('common.organizationProgramType', { count: 100 })}
        placeholder={t('common.all')}
        options={programTypeOptions}
      />
    );
  }

  return null;
};

export default OrganizationProgramTypeSelect;
