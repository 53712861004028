import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useStudents } from '../../../students/studentsHooks';

import Avatar from 'react-avatar';
// eslint-disable-next-line no-restricted-imports

import { formatFullName } from '../../../helpers/utils';

import { FileDocument } from '@wonderschool/common-base-types';
import { Button, Dialog } from '@wonderschool/common-base-ui';
import styles from '../../../Components/Stats/CheckedInStudentsModal.module.scss';
import useRooms from '../../../hooks/useRooms';
import { documentTestIds } from '../../dictionary';

interface IProps {
  selectedFile?: FileDocument | null;
  isOpen: boolean;
  closeModal: () => void;
}

// TODO:
// test scroll behavior for big list of users shared with
// should overflow with ability to scroll

const MAX_NUM_OF_STUDENTS_ON_LOAD = 10;

const SharedWithModal: React.FC<IProps> = ({ selectedFile, isOpen = false, closeModal }) => {
  const {
    sharedFor,
    // updatedAt
  } = selectedFile ?? {};
  const { list } = useStudents();
  const rooms = useRooms();
  const { t } = useTranslation();
  const [shouldShowAll, setShouldShowAll] = useState(false);
  const [studentListToRender, setStudentListToRender] = useState<any[]>([]);
  const [roomsSharedFor, setRoomsSharedFor] = useState<any[]>([]);

  useEffect(() => {
    if (sharedFor && Object.keys(sharedFor).length) {
      const sharedForKeys = Object.keys(sharedFor);

      const studentsSharedWith = list.filter((student: any) => {
        return sharedForKeys.some((key) => student.id === key.split('student-')[1]);
      });
      const roomsSharedFor = rooms.filter((room: any) => {
        return sharedForKeys.includes(`room-${room.id}`);
      });

      if (shouldShowAll) {
        setStudentListToRender(studentsSharedWith);
        setRoomsSharedFor(roomsSharedFor);
      } else {
        setStudentListToRender(studentsSharedWith.slice(0, MAX_NUM_OF_STUDENTS_ON_LOAD));
        setRoomsSharedFor(roomsSharedFor);
      }
    }
  }, [selectedFile, shouldShowAll, sharedFor, list, rooms]);

  const resetModal = () => {
    setStudentListToRender([]);
    setRoomsSharedFor([]);
    setShouldShowAll(false);
  };

  const close = () => {
    resetModal();
    closeModal();
  };

  const renderFileNotSharedContent = () => (
    <div data-testid={documentTestIds.sharedForModal.unsharedMsg}>{t('This document hasn’t been shared yet.')}</div>
  );

  const renderShowAllContent = () => {
    const numOfShares = sharedFor ? Object.keys(sharedFor).length : 0;
    if (numOfShares > MAX_NUM_OF_STUDENTS_ON_LOAD && !shouldShowAll) {
      return (
        <Button
          primary
          onClick={() => setShouldShowAll(!shouldShowAll)}
          data-testid={documentTestIds.sharedForModal.btns.showAll}
          label={t('+ {{extraStudents}} more students', {
            extraStudents: numOfShares - MAX_NUM_OF_STUDENTS_ON_LOAD,
          })}
        />
      );
    }

    return null;
  };

  const renderFileSharedContent = () => (
    <div className="max-h-[50vh] space-y-4 overflow-y-auto">
      <div className="space-y-4" data-testid={documentTestIds.sharedForModal.roomsList}>
        {roomsSharedFor.map((room) => (
          <div key={room.id} className="flex items-center space-x-3">
            <Avatar name={room.name} round size={'28'} alt={room.name} className={styles.avatar} />
            <div className="font-medium">{room.name}</div>
          </div>
        ))}
      </div>
      <div className="mt-4 space-y-4" data-testid={documentTestIds.sharedForModal.studentsList}>
        {studentListToRender.map((student: any) => (
          <div key={student.id} className="flex items-center space-x-3">
            <Avatar
              name={formatFullName(student, true)}
              round
              size={'28'}
              alt={formatFullName(student, true)}
              src={student.picture}
              className={styles.avatar}
            />
            <div>
              <div className="font-medium">{formatFullName(student, true)}</div>
            </div>
          </div>
        ))}
      </div>

      {renderShowAllContent()}
    </div>
  );

  return (
    <Dialog onClose={close} isOpen={isOpen}>
      <Dialog.Panel>
        <Dialog.Title>{t('Shared with')}</Dialog.Title>

        {studentListToRender.length || roomsSharedFor.length ? renderFileSharedContent() : renderFileNotSharedContent()}
      </Dialog.Panel>
      <Dialog.Actions data-testid={documentTestIds.sharedForModal.btns.done}>
        <Button primary onClick={close} label={t('Done')} />
      </Dialog.Actions>
    </Dialog>
  );
};

export default SharedWithModal;
