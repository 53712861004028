import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Form, Icon, Label } from 'semantic-ui-react';

import { DiscountAmountType } from '../../../../../helpers/invoices';
import { Dropdown } from '../../../../Shared/Dropdown';
import { CurrencyInput, PercentInput } from '../../../../Shared/NumericInput';
import './InvoiceItemsEditor.scss';

const getInputType = (amountType) => {
  switch (amountType) {
    case DiscountAmountType.PERCENT:
      return PercentInput;
    default:
      return CurrencyInput;
  }
};

const DiscountRowEditor = ({
  amount,
  amountType,
  discountType,
  index,
  discountIndex,
  onChange,
  onDeleteDiscount,
  discountOptions,
  discountTypeError,
}) => {
  const { t } = useTranslation();
  const isCurrency = amountType === DiscountAmountType.CURRENCY;
  const isPercent = amountType === DiscountAmountType.PERCENT;

  const handleAmountTypeChange = (e, type) => {
    e.preventDefault();
    onChange(e, { name: 'amountType', value: type }, index, discountIndex);
  };

  const addCustomDiscountTypeMaybe = (discountType) => {
    if (!discountType) return;

    const option = discountOptions.find((option) => option.value === discountType);
    if (!option) {
      discountOptions.push({
        key: discountType,
        value: discountType,
        text: discountType,
      });
    }
  };
  return (
    <>
      <Form.Group className={'discount-row'}>
        <Form.Field width={8} error={discountTypeError}>
          <Label basic className="label-required" style={{ border: 'none', backgroundColor: 'transparent' }}>
            {t('Discount or Subsidy')}
          </Label>
          <Dropdown
            data-testid={`discount-type-${index}-${discountIndex}`}
            allowAdditions
            search
            clearable
            required
            placeholder={t('Select or type new')}
            name="discountType"
            value={discountType}
            onAddItem={(_e, { value }) => {
              addCustomDiscountTypeMaybe(value);
            }}
            onChange={(e, data) => onChange(e, data, index, discountIndex)}
            translator={t}
            options={discountOptions}
          />
        </Form.Field>

        <Form.Field width={5}>
          <Label basic className="label-required" style={{ border: 'none', backgroundColor: 'transparent' }}>
            {t('Amount')}
          </Label>
          <Form.Input
            data-testid={`discount-amount-${index}-${discountIndex}`}
            type="text"
            required
            name={'amount'}
            onBlur={(e) => onChange(e, { name: e.target.name, value: e.target.value }, index, discountIndex)}
            control={getInputType(amountType)}
            value={amount}
            placeholder={t('Amount')}
          />
        </Form.Field>

        <Form.Field width={2}>
          <label>&nbsp;</label>
          <Button.Group>
            <Button
              data-testid={`discount-amount-type-currency-${index}-${discountIndex}`}
              icon
              className={isCurrency ? 'active' : ''}
              onClick={(e) => handleAmountTypeChange(e, DiscountAmountType.CURRENCY)}
            >
              <Icon name="dollar" size="small" />
            </Button>
            <Button
              data-testid={`discount-amount-type-percent-${index}-${discountIndex}`}
              icon
              className={isPercent ? 'active' : ''}
              onClick={(e) => handleAmountTypeChange(e, DiscountAmountType.PERCENT)}
            >
              <Icon name="percent" size="small" />
            </Button>
          </Button.Group>
        </Form.Field>

        <Form.Field width={1} className={'discount-delete-button'}>
          <label>&nbsp;</label>
          <Button
            data-testid={`discount-delete-${index}-${discountIndex}`}
            basic
            icon="trash"
            type="button"
            onClick={() => onDeleteDiscount(index, discountIndex)}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

DiscountRowEditor.propTypes = {
  discountOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onDeleteDiscount: PropTypes.func.isRequired,
};

export default DiscountRowEditor;
