import { logError } from '../../rollbar';
import marketplaceGraphql from './marketplaceGraphql';

const FEATURE_FLAGS_QUERY = `
query schoolFeatureFlags($schoolId: Int!) {
  school(id: $schoolId) {
    id
    featureFlags {
      id
      enabled
    }
  }
}
`;

function parseResponse(data: any): { tourRequests: boolean; messaging: boolean } {
  const tourRequest = data.school.featureFlags.find((ff) => ff.id === 'request_a_tour');
  const messaging = data.school.featureFlags.find((ff) => ff.id === 'messaging');
  return {
    tourRequests: !!tourRequest.enabled,
    messaging: !!messaging.enabled,
  };
}

function handleError(err: any) {
  logError(`Failed to make Feature Flags request`, err, { err });
}

export function featureFlags(schoolId: number): Promise<any | null> {
  return marketplaceGraphql(FEATURE_FLAGS_QUERY, { schoolId }).then(parseResponse).catch(handleError);
}
