import { BillingIntervalEnum, HourEnum, WEEKDAYS, WeekNumberEnum } from './enums';
import {
  BusinessHoursType,
  DocumentType,
  EnrollmentType,
  InvitationRecipientType,
  ScheduledDayType,
  TuitionAndFeesType,
} from './types';

import { DayEnum } from '@wonderschool/common-base-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Timestamp } from 'firebase/firestore';
import { DiscountAmountType } from '../helpers/invoices';
import {
  DAY_ENUM_TO_DAYJS_DAY,
  DAY_ENUM_TO_LEGACY_MAP,
  DOCUMENTS_EMPTY,
  INVITATION_RECIPIENTS_EMPTY,
  LEGACY_TO_DAY_ENUM_MAP,
  SCHEDULED_DAYS_EMPTY,
  TUITION_AND_FEES_EMPTY,
} from './consts';

dayjs.extend(utc);

function createInvitationRecipientsFromFamily(student: any): InvitationRecipientType[] {
  const family = student?.family;
  if (!family) return INVITATION_RECIPIENTS_EMPTY;

  const invitationRecipients: InvitationRecipientType[] = [];

  Object.keys(family).forEach((key: string) => {
    const contact = family[key];
    invitationRecipients.push({
      contactId: key,
      uid: contact.uid ?? null,
      email: contact.email ?? null,
      displayName: contact.displayName ?? null,
      phone: contact.phone ?? null,
    });
  });
  return invitationRecipients;
}

// if the recipients are already defined on the enrollment, use those
// otherwise, create them from the family
function getInvitationRecipients(student: any): InvitationRecipientType[] {
  return student?.enrollment?.invitationRecipients ?? INVITATION_RECIPIENTS_EMPTY;
}

function getDocuments(student: any): DocumentType[] {
  return student?.enrollment?.documents ?? DOCUMENTS_EMPTY;
}

function getScheduledDays(student: any, defaultBusinessHours: BusinessHoursType): ScheduledDayType[] {
  const enrollment: EnrollmentType = student?.enrollment;
  const studentSchedule: string[] = student?.schedule;

  if (enrollment?.scheduledDays) {
    return enrollment.scheduledDays;
  } else if (studentSchedule && Array.isArray(studentSchedule)) {
    // legacy case
    return studentSchedule.map((day: string) => {
      return {
        day: LEGACY_TO_DAY_ENUM_MAP[day],
        startTime: defaultBusinessHours.startTime,
        endTime: defaultBusinessHours.endTime,
      };
    });
  }
  return SCHEDULED_DAYS_EMPTY;
}

function getScheduledDaysAbbreviated(student: any): string[] {
  if (!student.enrollment?.scheduledDays) {
    return student.schedule ?? [];
  } else if (student.enrollment?.scheduledDays) {
    return student.enrollment.scheduledDays.map(
      (scheduledDay: ScheduledDayType) => DAY_ENUM_TO_LEGACY_MAP[scheduledDay.day]
    );
  } else return [];
}

function getTuitionAndFees(student: any): TuitionAndFeesType {
  const enrollment = student?.enrollment;
  const studentTuitionAndFees = student?.tuitionAndFees;

  if (enrollment?.tuitionAndFees) {
    return enrollment.tuitionAndFees;
  } else if (studentTuitionAndFees) {
    // legacy case
    return studentTuitionAndFees;
  } else {
    return TUITION_AND_FEES_EMPTY;
  }
}

function formatHourForDisplay(hour: HourEnum): string {
  const hourSplit: string[] = hour.split(':');
  const hours = parseInt(hourSplit[0]);
  const minutes = parseInt(hourSplit[1]);
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hours12 = hours % 12;
  const hours12Display = hours12 === 0 ? 12 : hours12;
  const minutesDisplay = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours12Display}:${minutesDisplay} ${ampm}`;
}

function calculateNextInvoiceDate(
  firstInvoiceDate: Timestamp | undefined,
  interval: BillingIntervalEnum | undefined,
  chargeDate: string,
  billFor: 'Prior' | 'Next',
  startDate: number
): Date | undefined {
  if (!firstInvoiceDate || !interval) return undefined;
  const firstInvoiceDay = dayjs.unix(firstInvoiceDate.seconds).utc();
  const enrollmentDay = dayjs.utc(startDate);
  if (interval === BillingIntervalEnum.WEEKLY) {
    const selectedDayIndex = WEEKDAYS.indexOf(chargeDate as DayEnum);
    const convertedDayIndex = convertWeekdayToDayjsDay(selectedDayIndex);
    const chargeDayThisWeek = firstInvoiceDay.day(convertedDayIndex);
    if (billFor === 'Next') {
      return chargeDayThisWeek.isSameOrBefore(firstInvoiceDay)
        ? chargeDayThisWeek.add(1, 'week').toDate()
        : chargeDayThisWeek.toDate();
    } else {
      return chargeDayThisWeek.add(1, 'week').toDate();
    }
  } else if (interval === BillingIntervalEnum.BIWEEKLY) {
    const chargeDay = dayjs(chargeDate, 'YYYY-MM-DD');
    const weekOfMonth = Math.ceil(chargeDay.date() / 7);
    const weekNumber = weekOfMonth % 2 === 0 ? WeekNumberEnum.EVEN : WeekNumberEnum.ODD;

    const { relevantDays } = getRelevantDays(enrollmentDay, chargeDate);

    // Find the next billing date after firstInvoiceDay
    let nextBillingDate;
    if (billFor === 'Next') {
      nextBillingDate = relevantDays.find((date) => date && date.isAfter(firstInvoiceDay));
      if (!nextBillingDate) {
        // If no dates found in current month, get the first occurrence in next month
        const nextMonth = firstInvoiceDay.add(1, 'month').startOf('month');
        nextBillingDate = nextMonth.day(chargeDay.day());
        if (weekNumber === WeekNumberEnum.EVEN) {
          nextBillingDate = nextBillingDate.add(1, 'week');
        }
      }
    } else {
      nextBillingDate = relevantDays.find((date) => date && date.isAfter(firstInvoiceDay));
      if (!nextBillingDate) {
        nextBillingDate = firstInvoiceDay.add(2, 'week');
      }
    }

    return nextBillingDate.toDate();
  } else {
    const chargeDay = firstInvoiceDay.set('date', parseInt(chargeDate));

    if (billFor === 'Next') {
      return chargeDay.isSameOrBefore(firstInvoiceDay) ? chargeDay.add(1, 'month').toDate() : chargeDay.toDate();
    } else {
      return chargeDay.isSameOrBefore(firstInvoiceDay)
        ? chargeDay.add(1, 'month').toDate()
        : chargeDay.subtract(1, 'day').toDate();
    }
  }
}

function getNextInvoiceDate(
  dueDate: Timestamp | undefined,
  billingInterval: BillingIntervalEnum | undefined,
  proratedAmount = 0,
  chargeDate: string,
  billFor: 'Prior' | 'Next',
  startDate: number
) {
  if (!dueDate) return undefined;
  const nextInvoiceDate =
    proratedAmount > 0
      ? calculateNextInvoiceDate(dueDate, billingInterval, chargeDate, billFor, startDate)
      : dayjs.unix(dueDate.seconds).utc().toDate();

  return nextInvoiceDate;
}

function countSessions(periodStart: Date, periodEnd: Date, weekdays: number[]) {
  const start = dayjs(periodStart);
  const end = dayjs(periodEnd);

  // Get zero-based day of the week for start and end dates
  const firstWeekday = start.day();
  const lastWeekday = end.day();

  // Calculate the difference in days and add 1 for inclusive count
  const days = end.diff(start, 'day') + 1;

  // Calculate the number of complete weeks
  const completeWeeks = Math.floor((days - (7 - firstWeekday) - (lastWeekday + 1)) / 7);

  // Calculate sessions in the first and last weeks
  const firstWeekSessions = weekdays.filter((day) => day >= firstWeekday).length || 0;
  const lastWeekSessions = weekdays.filter((day) => day <= lastWeekday).length || 0;

  return Math.trunc(completeWeeks * weekdays.length + firstWeekSessions + lastWeekSessions);
}

function calculateProrateAmount(
  weekdays: number[],
  billBeginsOn: Date | null,
  billEndsOn: Date | null,
  rangeBeginsOn: Date | null,
  rangeEndsOn: Date | null,
  amount: number
) {
  if (weekdays.length === 0 || !billBeginsOn || !billEndsOn || !rangeBeginsOn || !rangeEndsOn || !amount) return 0;
  const schoolSessions = countSessions(billBeginsOn, billEndsOn, weekdays);
  const attendedSessions = countSessions(rangeBeginsOn, rangeEndsOn, weekdays);

  const ratio = attendedSessions === 0 ? 0 : attendedSessions / schoolSessions;
  return Math.round(amount * ratio);
}

function convertWeekdayToDayjsDay(weekdayIndex: number): number {
  return weekdayIndex === 6 ? 0 : weekdayIndex + 1;
}

function isEnrollmentDateSameAsChargeDate(
  chargeDate: string,
  billingInterval: BillingIntervalEnum,
  enrollmentDate: Date
): boolean {
  const enrollmentDay = dayjs(enrollmentDate);

  if (billingInterval === BillingIntervalEnum.MONTHLY) {
    // For monthly, check if enrollment day matches charge date
    const chargeDayOfMonth = parseInt(chargeDate);
    return enrollmentDay.date() === chargeDayOfMonth;
  } else if (billingInterval === BillingIntervalEnum.BIWEEKLY) {
    const { relevantDays } = getRelevantDays(enrollmentDay, chargeDate);

    return relevantDays.some((day) => day.toString() === enrollmentDay.toString());
  } else {
    // For weekly, check if enrollment weekday matches charge weekday
    const selectedDayIndex = WEEKDAYS.indexOf(chargeDate as DayEnum);
    return enrollmentDay.day() === convertWeekdayToDayjsDay(selectedDayIndex);
  }
}

function calculateFirstInvoiceDue(
  chargeDate: string,
  billingInterval: BillingIntervalEnum,
  enrollmentDate: Date,
  billFor: 'Prior' | 'Next'
): dayjs.Dayjs {
  let enrollmentDay = dayjs(enrollmentDate);

  if (billFor === 'Next' && !enrollmentDay.isBefore(dayjs())) {
    return enrollmentDay;
  }

  if (enrollmentDay.isBefore(dayjs())) {
    enrollmentDay = dayjs();
  }

  if (billingInterval === BillingIntervalEnum.MONTHLY) {
    const chargeDay = enrollmentDay.set('date', parseInt(chargeDate));

    return chargeDay.isSameOrBefore(enrollmentDay) ? chargeDay.add(1, 'month') : chargeDay;
  } else if (billingInterval === BillingIntervalEnum.BIWEEKLY) {
    const { relevantDays, targetDays } = getRelevantDays(enrollmentDay, chargeDate);

    // Find the next billing date from the relevant days
    const nextBillingDate = relevantDays.find((d) => d.isAfter(enrollmentDay)) || targetDays[0].add(2, 'week');

    return nextBillingDate;
  } else {
    const selectedDayIndex = WEEKDAYS.indexOf(chargeDate as DayEnum);
    const chargeDayThisWeek = enrollmentDay.day(convertWeekdayToDayjsDay(selectedDayIndex));

    return chargeDayThisWeek.isSameOrBefore(enrollmentDay) ? chargeDayThisWeek.add(1, 'week') : chargeDayThisWeek;
  }
}

function calculateStartBillingDate(
  chargeDate: string,
  billingInterval: BillingIntervalEnum,
  enrollmentDate: Date,
  billFor: 'Prior' | 'Next'
) {
  const enrollmentDay = dayjs(enrollmentDate);

  if (billingInterval === BillingIntervalEnum.MONTHLY) {
    // For monthly, get the charge date for enrollment month
    const chargeDay = enrollmentDay.set('date', parseInt(chargeDate));

    return chargeDay.isSameOrBefore(enrollmentDay) ? chargeDay.toDate() : chargeDay.subtract(1, 'month').toDate();
  } else if (billingInterval === BillingIntervalEnum.BIWEEKLY) {
    const { relevantDays, targetDays } = getRelevantDays(enrollmentDay, chargeDate);
    // Find the appropriate billing date based on billFor
    const billingDate = relevantDays.every((d) => enrollmentDay.isSameOrBefore(d))
      ? relevantDays[0].subtract(2, 'week')
      : relevantDays.filter((d) => d.isSameOrBefore(enrollmentDay)).pop() || targetDays[0];
    return billingDate.toDate();
  } else {
    // For weekly, get the charge weekday for enrollment week
    const selectedDay = chargeDate as DayEnum;
    const dayIndex = DAY_ENUM_TO_DAYJS_DAY[selectedDay] || 0;
    const chargeDayThisWeek = enrollmentDay.day(dayIndex);

    if (billFor === 'Next') {
      // For prior period, if charge day is after enrollment, subtract a week
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.toDate()
        : chargeDayThisWeek.subtract(1, 'week').toDate();
    } else {
      // For next period, if charge day is before/same as enrollment, add a week
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(1, 'week').toDate()
        : chargeDayThisWeek.toDate();
    }
  }
}

function calculateEndBillingDate(
  chargeDate: string,
  billingInterval: BillingIntervalEnum,
  enrollmentDate: Date,
  billFor: 'Prior' | 'Next'
) {
  if (chargeDate.length === 0) return new Date();
  const enrollmentDay = dayjs.utc(enrollmentDate);

  if (billingInterval === BillingIntervalEnum.MONTHLY) {
    // For monthly, get the charge date for enrollment month
    const chargeDay = enrollmentDay.set('date', parseInt(chargeDate));

    return chargeDay.isSameOrBefore(enrollmentDay)
      ? chargeDay.add(1, 'month').subtract(1, 'day').toDate()
      : chargeDay.subtract(1, 'day').toDate();
  } else if (billingInterval === BillingIntervalEnum.BIWEEKLY) {
    const { relevantDays } = getRelevantDays(enrollmentDay, chargeDate);
    // Find the charge day in the enrollment week/month
    const chargeDayThisMonth = relevantDays.find((d) => d?.isSameOrBefore(enrollmentDay)) || relevantDays[0];

    return chargeDayThisMonth.isSameOrBefore(enrollmentDay)
      ? chargeDayThisMonth.add(2, 'week').toDate()
      : chargeDayThisMonth.toDate();
  } else {
    // For weekly, get the charge weekday for enrollment week
    const selectedDay = chargeDate as DayEnum;
    const dayIndex = DAY_ENUM_TO_DAYJS_DAY[selectedDay] || 0;
    const chargeDayThisWeek = enrollmentDay.day(dayIndex);

    if (billFor === 'Next') {
      // For prior period, if charge day is after enrollment, use this week
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(1, 'week').subtract(1, 'day').toDate()
        : chargeDayThisWeek.subtract(1, 'day').toDate();
    } else {
      // For next period, if charge day is before/same as enrollment, add two weeks
      return chargeDayThisWeek.isSameOrBefore(enrollmentDay)
        ? chargeDayThisWeek.add(2, 'week').subtract(1, 'day').toDate()
        : chargeDayThisWeek.add(1, 'week').subtract(1, 'day').toDate();
    }
  }
}

function convertScheduledDaysToWeekdayNumbers(scheduledDays: ScheduledDayType[] = []): number[] {
  return scheduledDays.map((scheduleDay) => DAY_ENUM_TO_DAYJS_DAY[scheduleDay.day]).sort((a, b) => a - b);
}

function calculateRecurringInvoiceTotal(fees, tuition = 0) {
  if (!fees?.length) return tuition;

  return fees.reduce((sum, fee) => {
    const amount = fee.amount || 0;
    const isDiscount = fee.type === 'Discount';
    const isCurrency = fee.amountType === DiscountAmountType.CURRENCY;
    let total = isCurrency ? sum + amount : sum + sum * (amount / 100);
    if (isDiscount) {
      total = isCurrency ? sum - amount : sum - sum * (amount / 100);
    }
    return total;
  }, tuition);
}

function shouldCalculateProrateAmount(
  chargeDate: string,
  selectedBillingInterval: BillingIntervalEnum,
  enrollmentDate: Date
): boolean {
  if (!chargeDate || !selectedBillingInterval || !enrollmentDate) return false;
  return (
    !isEnrollmentDateSameAsChargeDate(chargeDate, selectedBillingInterval, enrollmentDate) &&
    dayjs(enrollmentDate).diff(dayjs(), 'days') >= 0
  );
}

function formatDate(date: Timestamp | Date | undefined) {
  if (!date) return null;
  let currentDate;
  if (date instanceof Timestamp) {
    currentDate = dayjs.unix(date.seconds).utc().format('YYYY-MM-DD');
  } else {
    currentDate = dayjs(date).utc().format('YYYY-MM-DD');
  }
  const d = currentDate.split('-');
  const convertedLocal = dayjs(`${d[0]}-${d[1]}-${d[2]}`, 'YYYY-MM-DD');
  return convertedLocal.valueOf();
}

function getRelevantDays(enrollmentDay: dayjs.Dayjs, chargeDate: string) {
  const chargeDay = dayjs.utc(chargeDate, 'YYYY-MM-DD');
  const weekOfMonth = Math.ceil(chargeDay.date() / 7);
  const weekNumber = weekOfMonth % 2 === 0 ? WeekNumberEnum.EVEN : WeekNumberEnum.ODD;

  // Get the first day of the current month
  const firstDayOfMonth = enrollmentDay.startOf('month');
  // Find the first occurrence of the specified day in the current month
  const targetDays: dayjs.Dayjs[] = [];
  let currentDay = firstDayOfMonth.day(chargeDay.day());

  if (enrollmentDay.isBefore(dayjs())) {
    while (currentDay.month() <= enrollmentDay.month()) {
      targetDays.push(currentDay.clone());
      currentDay = currentDay.add(1, 'week');
    }
  } else {
    while (currentDay.month() === enrollmentDay.month()) {
      targetDays.push(currentDay.clone());
      currentDay = currentDay.add(1, 'week');
    }
  }

  // Get the occurrence based on weekNumber (1 = 1st and 3rd, 2 = 2nd and 4th)
  const weekNum = parseInt(weekNumber);
  const relevantDays = targetDays.filter((_d, index) => {
    return index === weekNum - 1 || index === weekNum + 1;
  });

  return { relevantDays, targetDays };
}

export {
  calculateEndBillingDate,
  calculateFirstInvoiceDue,
  calculateProrateAmount,
  calculateRecurringInvoiceTotal,
  calculateStartBillingDate,
  convertScheduledDaysToWeekdayNumbers,
  createInvitationRecipientsFromFamily,
  formatDate,
  formatHourForDisplay,
  getDocuments,
  getInvitationRecipients,
  getNextInvoiceDate,
  getScheduledDays,
  getScheduledDaysAbbreviated,
  getTuitionAndFees,
  isEnrollmentDateSameAsChargeDate,
  shouldCalculateProrateAmount,
};
