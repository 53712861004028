import { useEffect } from 'react';

import { useOrganization } from '../../hooks/useOrganizations';
import { useAuthUser } from '../../hooks/useUser';

import { callSegmentPage } from '../api';
import { PAGE_CATEGORIES } from '../types';

export default function useSegmentPage(pageName, properties = {}) {
  const { currentUser } = useAuthUser();
  const organization = useOrganization();

  useEffect(() => {
    if (organization?.id && currentUser?.uid) {
      const data = {
        name: pageName,
        category: PAGE_CATEGORIES.default,
        properties: {
          userId: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName,
          organizationId: organization.id,
          organizationName: organization.name,
          ...properties,
        },
      };
      callSegmentPage(data);
    }
  }, [organization, pageName, properties, currentUser]);
}
