import * as actionTypes from '../../actions/types';

export type PaymentEmployeesReducerState = {
  paymentEmployees: [] | null;
};

const initialState: PaymentEmployeesReducerState = {
  paymentEmployees: null,
};

const paymentEmployeesReducer = (state = initialState.paymentEmployees, action) => {
  switch (action.type) {
    case actionTypes.PAYMENT.UPDATE_SELECTED_EMPLOYEES:
      return [
        ...action.payload.selectedEmployees.map((employee) => {
          return { ...employee };
        }),
      ];
    case actionTypes.PAYMENT.REMOVE_SELECTED_EMPLOYEE:
      return state ? [...state.filter((_, i) => i !== action.payload.index)] : null;
    case actionTypes.PAYMENT.RESET_DATA:
      return null;
    default:
      return state;
  }
};

export default paymentEmployeesReducer;
