import React from 'react';

import { OrganizationProgramTypeEnum, USStateCodeEnum } from '@wonderschool/common-base-types';
import { USStateSelect } from '@wonderschool/common-base-ui';
import OrganizationProgramTypeSelect from './OrganizationProgramTypeSelect';

type AdminDocumentsFiltersProps = {
  onStateChange: (states: USStateCodeEnum[]) => void;
  onProgramTypeChange: (programTypes: OrganizationProgramTypeEnum[]) => void;
};

export const AdminDocumentsFilters: React.FC<AdminDocumentsFiltersProps> = ({ onStateChange, onProgramTypeChange }) => {
  return (
    <div className="mb-4 flex w-full max-w-2xl flex-row gap-4">
      <USStateSelect multiple onChange={(value) => onStateChange(value.map((v) => v.value as USStateCodeEnum))} />
      <OrganizationProgramTypeSelect
        multiple
        onChange={(value) => onProgramTypeChange(value.map((v) => v.value as OrganizationProgramTypeEnum))}
      />
    </div>
  );
};
