import { useCallback, useMemo } from 'react';

import { SortDirectionEnum } from '../../enums';
import { SortDirectionType } from '../../types';
import { WsSortButton } from './WsSortButton';

const EMPTY_SORT_DIRECTION: SortDirectionType = {
  name: '',
  direction: SortDirectionEnum.NONE,
};
type WsTableHeaderProps = {
  children?: React.ReactNode;
  sortKey?: string;
  label?: string;
  onSort?: (sortDirection: SortDirectionType) => void;
  sortDirection?: SortDirectionType;
  extraClasses?: string; // additional classes to add to the header
};

const headerClasses = 'px-6 py-3 text-center margin-auto text-base font-medium text-gray-800';

export function WsTableHeader({
  sortKey,
  children,
  label = '',
  onSort,
  sortDirection = EMPTY_SORT_DIRECTION,
  extraClasses = '',
}: WsTableHeaderProps) {
  const isActive = useMemo<boolean>(() => {
    return sortDirection.name === sortKey;
  }, [sortDirection, sortKey]);

  const onSortLocal = useCallback(
    (name: string, direction: SortDirectionEnum) => {
      const sortDirectionLocal = { name, direction: direction };
      if (onSort) onSort(sortDirectionLocal);
    },
    [onSort]
  );

  return (
    <th scope="col" className={`${headerClasses} ${extraClasses}`}>
      <div className="flex flex-row items-center">
        <div className="mr-1">{children ? children : label}</div>
        {!!sortKey && (
          <WsSortButton
            name={sortKey}
            onSort={onSortLocal}
            sortDirection={isActive ? sortDirection : EMPTY_SORT_DIRECTION}
          />
        )}
      </div>
    </th>
  );
}

export default WsTableHeader;
