import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FinanacialSummaryCheckboxDropdown.scss';
import { FinanacialSummaryCheckboxDropdownProps, FinancialSummaryDropdownOption } from './types';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Image } from 'semantic-ui-react';

const FinanacialSummaryCheckboxDropdown: React.FC<FinanacialSummaryCheckboxDropdownProps> = ({
  id,
  options,
  onSelect,
  placeholder,
  selectAllLable = 'financialSummary.dropdown.selectAll',
  className,
  isMultiSelect = false,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialValue ? initialValue : []);
  const [parentOptions, setParentOptions] = useState<string[]>([]);
  const [renderSelected, setRenderSelected] = useState<string>(placeholder);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleOption = useCallback(
    (option: FinancialSummaryDropdownOption) => {
      let updatedOptions: string[];
      if (isMultiSelect) {
        updatedOptions = [...selectedOptions];

        if (option.type === 'parent') {
          // If the clicked option is a parent, toggle all its children
          const childValues = options.filter((child) => child.parent === option.value).map((child) => child.value);

          const allChildrenSelected = childValues.every((childValue) => selectedOptions.includes(childValue));

          if (allChildrenSelected) {
            // Remove the parent and all its children from the selected options
            updatedOptions = updatedOptions.filter((value) => !childValues.includes(value) && value !== option.value);
          } else {
            // Add the parent and selected children to the selected options
            const selectedChildren = childValues.filter((child) => updatedOptions.includes(child));
            if (selectedChildren?.length > 0) {
              updatedOptions = updatedOptions.filter((value) => !childValues.includes(value) && value !== option.value);
            }
            updatedOptions.push(option.value, ...childValues);
          }
        } else {
          // If the clicked option is a child, toggle its selection
          const index = updatedOptions.indexOf(option.value);
          if (index === -1) {
            updatedOptions.push(option.value);
          } else {
            updatedOptions.splice(index, 1);
          }

          // Check if all children are selected, then select the parent
          const parentValue = option.parent;
          const allChildrenSelected = options
            .filter((child) => child.parent === parentValue)
            .every((child) => updatedOptions.includes(child.value));

          if (allChildrenSelected) {
            if (!updatedOptions.includes(parentValue || '')) {
              updatedOptions.push(parentValue || '');
            }
          } else {
            updatedOptions = updatedOptions.filter((value) => value !== parentValue);
          }
        }
      } else {
        // For single-select, simply select or deselect the clicked option
        updatedOptions = [option.value];
      }
      onSelect(updatedOptions?.filter((item) => !parentOptions.includes(item)));
      setSelectedOptions(updatedOptions);
    },
    [isMultiSelect, selectedOptions, options, parentOptions, onSelect]
  );

  const toggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelectAll = useCallback(() => {
    if (isMultiSelect) {
      const allValues = options.map((opt) => opt.value);
      const updatedOptions = selectedOptions.length === allValues.length ? [] : allValues;
      onSelect(updatedOptions?.filter((item) => !parentOptions.includes(item)));
      setSelectedOptions(updatedOptions);
    }
  }, [options, isMultiSelect, selectedOptions, parentOptions, onSelect]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setParentOptions(options.filter((option) => option.type === 'parent').map((option) => option.value));
  }, [options]);

  useEffect(() => {
    const getSelectedLable = () => {
      let updatedOptions = [...selectedOptions];
      if (updatedOptions.length === options.length) {
        return t(selectAllLable);
      }
      const selectedParents: string[] = parentOptions.filter((value) => updatedOptions.includes(value));
      const childOfSelectedParents = options
        .filter((child) => selectedParents.includes(child.parent || ''))
        .map((child) => child.value);
      updatedOptions = updatedOptions?.filter((item) => !childOfSelectedParents.includes(item));

      return updatedOptions.map((option) => t(option)).join(', ');
    };
    if (selectedOptions?.length > 0) {
      setRenderSelected(getSelectedLable() || '');
    } else {
      setRenderSelected(placeholder);
    }
  }, [selectedOptions, t, placeholder, selectAllLable, parentOptions, options]);

  return (
    <>
      <div className={`checkbox-select-dropdown ${className}`} ref={dropdownRef} id={id} data-testid={id}>
        <div className="dropdown-header" onClick={toggleDropdown} data-testid={`selected-dropdown-header-${id}`}>
          <span title={renderSelected} data-testid={`selected-dropdown-text-${id}`}>
            {renderSelected}
          </span>
          <Image className="down-arrow" src="/images/down-arrow.svg" />
        </div>
        {isOpen && (
          <div className="dropdown-options" data-testid={`dropdown-options-${id}`}>
            {isMultiSelect && (
              <div
                className="dropdown-option"
                onClick={handleSelectAll}
                data-testid={`dropdown-option-select-all-${id}`}
              >
                <div className="select-all-option">
                  <Checkbox
                    checked={selectedOptions.length === options.length}
                    label={t(selectAllLable)}
                    data-testid={`select-all-${id}`}
                  />
                </div>
              </div>
            )}
            {options.map((option) => (
              <div
                key={option.value}
                className={`dropdown-option ${
                  option.type === 'child' ? 'child-option' : option.type === 'parent' ? 'parent-option' : ''
                }`}
                onClick={() => toggleOption(option)}
                data-testid={`dropdown-option-${option.value}-${id}`}
              >
                <Checkbox
                  checked={selectedOptions.includes(option.value)}
                  data-testid={`${option.value}-${id}`}
                  label={option.text}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default FinanacialSummaryCheckboxDropdown;
