import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Message } from 'semantic-ui-react';

const ShowErrors = ({ errors = {}, content = '', icon = 'cancel', header, hideHeader = false }) => {
  const { t } = useTranslation();
  const headerLocal = !hideHeader ? header || t('Something went wrong!') : null;

  if (Object.keys(errors ?? {}).length) {
    return (
      <Message
        data-testid="error-message"
        negative
        icon={icon}
        header={headerLocal}
        list={Object.keys(errors).map((errorKey) => (
          <li key={errorKey}>{errors[errorKey]}</li>
        ))}
      />
    );
  } else if (content) {
    return <Message data-testid="error-message" negative icon={icon} header={headerLocal} content={content} />;
  } else {
    return null;
  }
};

ShowErrors.propTypes = {
  errors: PropTypes.object,
  icon: PropTypes.string,
  header: PropTypes.string,
};

export default ShowErrors;
