import CN from 'classnames';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Card, Grid, Icon, Popup } from 'semantic-ui-react';

// Import utils
import { formatFullName } from '../../../../../helpers/utils';

// Import styles
import cardStyles from '../../../../Shared/CardHelpers/CardStyles.module.scss';
import './EmployeesList.scss';

const EmployeesList = ({ employeesList, selectedEmployees, onSelect }) => {
  const { t } = useTranslation();

  return (
    <Grid.Row>
      <Grid.Column>
        <Card.Group stackable itemsPerRow={6}>
          {employeesList.map((student, i) => {
            const isActive = selectedEmployees.map((e) => e.id).indexOf(student.id) >= 0;
            const studentIsDisabled = Boolean(student.preventInvoiceReason) || student?.familyMembers?.length === 0;
            const disablePopup = !studentIsDisabled;
            return (
              <Popup
                key={student.id}
                on="hover"
                disabled={disablePopup}
                wide
                trigger={
                  <Card
                    data-testid={`student-card-${i}`}
                    centered
                    className={`${isActive ? 'student selected' : 'student'} ${
                      studentIsDisabled ? 'disabled-students' : ''
                    }`}
                    key={student.id}
                    onClick={studentIsDisabled ? null : () => onSelect(student)}
                  >
                    <Card.Content data-testid={`student-card-avatar-${i}`} className="center" textAlign="center">
                      <Avatar name={formatFullName(student, true)} round size={44} src={student.picture} />
                    </Card.Content>

                    <Card.Content
                      data-testid={`student-card-name-${i}`}
                      textAlign="center"
                      className={CN(cardStyles.textTruncate, cardStyles.truncateWidthFix)}
                    >
                      {formatFullName(student)}
                    </Card.Content>
                  </Card>
                }
              >
                {studentIsDisabled && (
                  <Popup.Content>
                    <Icon name="warning circle" color="red" />
                    <span>{t(student.preventInvoiceReason)}</span>
                  </Popup.Content>
                )}
              </Popup>
            );
          })}
        </Card.Group>
      </Grid.Column>
    </Grid.Row>
  );
};

EmployeesList.propTypes = {
  employeesList: PropTypes.array.isRequired,
  selectedEmployees: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default EmployeesList;
