import { t } from 'i18next';
import { toast } from 'react-semantic-toasts';

const toastDefaults = {
  type: 'info',
  title: '',
  message: '',
  time: 8000,
  position: 'top',
  showCloseButton: false,
};

export function showSuccessToast(title, message) {
  const toastObject = {
    ...toastDefaults,
    title: title || t('Success'),
    type: 'success',
    description: <p>{message || t('Success')}</p>,
  };
  toast(toastObject);
}

export function showErrorToast(title, message) {
  const toastObject = {
    ...toastDefaults,
    title: title || t('Error'),
    type: 'error',
    description: <p>{message || t('Error')}</p>,
  };
  toast(toastObject);
}
