import { InvoicePlan } from '@wonderschool/common-base-types';
import { Dispatch } from 'redux';
import { chargesOnListen, saveCharge as saveChargeAPI } from '../../api/firebase/charges';
import * as actionTypes from './types';

export function updatePaymentDetails(payload: InvoicePlan) {
  return { type: actionTypes.PAYMENT.UPDATE_DETAILS, payload };
}

export function updateSelectedEmployees(payload: InvoicePlan) {
  return { type: actionTypes.PAYMENT.UPDATE_SELECTED_EMPLOYEES, payload };
}

export function removeEmployeeSuccess(payload: InvoicePlan) {
  return { type: actionTypes.PAYMENT.REMOVE_SELECTED_EMPLOYEE, payload };
}

export function resetPaymentData(payload?: InvoicePlan) {
  return { type: actionTypes.PAYMENT.RESET_DATA, payload };
}

export function createInvoice(payload: InvoicePlan) {
  return { type: actionTypes.INVOICES.CREATE, payload };
}

export function createInvoiceSuccess(payload: InvoicePlan) {
  return { type: actionTypes.INVOICES.CREATE_SUCCESS, payload };
}

export function loadInvoices() {
  return { type: actionTypes.INVOICES.LOAD };
}

export function loadInvoicesSuccess(payload: InvoicePlan) {
  return { type: actionTypes.INVOICES.LOAD_SUCCESS, payload };
}

// Edit InvoicePlan actions
export function invoicePlanNew() {
  return { type: actionTypes.INVOICE_NEW, isInvoice: false };
}

export function invoiceNew() {
  return { type: actionTypes.INVOICE_NEW, isInvoice: true };
}
export function invoiceEditSelectionCleared() {
  return { type: actionTypes.INVOICE_EDIT_SELECTION_CLEARED };
}
export function invoiceEditSelected(payload: InvoicePlan | null) {
  return { type: actionTypes.INVOICE_EDIT_SELECTED, payload };
}
export function invoiceEditDetails(details: InvoicePlan) {
  return { type: actionTypes.INVOICE_EDIT_DETAILS, details };
}
export function invoiceEditItems(invoiceItems: InvoicePlan) {
  return { type: actionTypes.INVOICE_EDIT_ITEMS, invoiceItems };
}
export function invoiceEditStudents(students: InvoicePlan) {
  return { type: actionTypes.INVOICE_EDIT_STUDENTS, students };
}
export function invoiceReset() {
  return { type: actionTypes.INVOICE_EDIT, invoice: null };
}
// Invoice Item Charges actions
export function chargesFetched(payload: InvoicePlan) {
  return { type: actionTypes.CHARGES_FETCHED, payload };
}
export function chargeSaved(payload: InvoicePlan) {
  return { type: actionTypes.CHARGE_SAVED, payload };
}
export const saveCharge = (organizationId: string, charge: InvoicePlan) => async (dispatch: Dispatch) => {
  try {
    await saveChargeAPI(organizationId, charge);
    return dispatch(chargeSaved(charge));
  } catch (err: unknown) {
    const error = err instanceof Error ? err : new Error(String(err));
    console.error(error);
    throw error;
  }
};
export const startChargesListener = (organizationId: string) => (dispatch: Dispatch) => {
  return chargesOnListen(
    organizationId,
    (data) => {
      if (data) dispatch(chargesFetched(data));
    },
    (error) => console.log(error.message)
  );
};
