import { Button, ClockIcon, CrossCircleIcon, Dialog, Divider, SuccessIcon } from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrimaryLocation } from '../../../hooks/useLocations';
import { KINDERSYSTEMS_REPORTS_STATUS } from '../../../reports/attendance/types';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TransferDetailsDialogProps {
  isOpen: boolean;
  isTransferringAttendance: boolean;
  onClose: () => void;
  setIsOpen: (isOpen: boolean) => void;
  handleTransferAttendance: () => void;
  kinderSystemsData?: any;
}

export const TransferDetailsDialog: React.FC<TransferDetailsDialogProps> = ({
  isOpen,
  setIsOpen,
  isTransferringAttendance,
  onClose,
  handleTransferAttendance,
  kinderSystemsData,
}) => {
  const { t } = useTranslation();
  const { timezone } = usePrimaryLocation();
  const [transferredDate, setTransferredDate] = useState<string | null>(null);
  const [lastTransferredDate, setLastTransferredDate] = useState<string | null>(null);

  const getLastTransferredDate = useCallback(() => {
    if (kinderSystemsData?.transferHistory) {
      const lastTransferred = kinderSystemsData.transferHistory
        .filter((history: any) => history.status === KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED)
        .sort((a: any, b: any) => b.transferredAt.seconds - a.transferredAt.seconds);
      if (lastTransferred.length > 0) {
        return dayjs.unix(lastTransferred[0].transferredAt.seconds).tz(timezone).format('MMMM D, YYYY [at] h:mma');
      }
    }
    return null;
  }, [kinderSystemsData, timezone]);

  const transferAttendance = useCallback(() => {
    setIsOpen(false);
    handleTransferAttendance();
  }, [handleTransferAttendance, setIsOpen]);

  useEffect(() => {
    const transferredAt = kinderSystemsData?.transferredAt
      ? dayjs.unix(kinderSystemsData.transferredAt.seconds).tz(timezone).format('MMMM D, YYYY [at] h:mma')
      : kinderSystemsData?.addedBy?.createdAt
        ? dayjs.unix(kinderSystemsData.addedBy.createdAt.seconds).tz(timezone).format('MMMM D, YYYY [at] h:mma')
        : null;
    setTransferredDate(transferredAt);
    setLastTransferredDate(getLastTransferredDate());
  }, [kinderSystemsData, timezone, getLastTransferredDate]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} extraClasses="text-gray-800">
      <Dialog.Panel>
        <Dialog.Title>{t('Details')}</Dialog.Title>
        <Divider />
        <>
          <Dialog.Description>
            <div className={`flex flex-row items-center gap-2 ${!transferredDate ? 'justify-end' : ''}`}>
              {transferredDate && <strong className="basis-2/3">{transferredDate}</strong>}
              {kinderSystemsData?.status === KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED && (
                <span className="flex w-32 flex-row rounded-full bg-green-200 px-2 py-2 text-sm">
                  <SuccessIcon className="mr-1 h-5 w-5 text-green-700" />
                  {t('Transferred')}
                </span>
              )}
              {kinderSystemsData?.status === KINDERSYSTEMS_REPORTS_STATUS.ERROR && (
                <span className="flex w-32 flex-row rounded-full bg-red-200 px-2 py-2 text-sm">
                  <CrossCircleIcon className="mr-1 h-5 w-5 text-red-700" />
                  {t('Error')}
                </span>
              )}
              {(!kinderSystemsData?.status ||
                kinderSystemsData?.status === KINDERSYSTEMS_REPORTS_STATUS.OUT_OF_SYNC) && (
                <span className="w-32 basis-1/3 rounded-full bg-yellow-200 px-2 py-2 text-sm">
                  <ClockIcon className="mr-1 h-5 w-5 text-yellow-700" />
                  {t('Out of Sync')}
                </span>
              )}
            </div>
          </Dialog.Description>
          {kinderSystemsData?.status !== KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED && (
            <Dialog.Description>{t('apps.kinderConnect.transferDetails.description1')}</Dialog.Description>
          )}
          <Divider />
          {lastTransferredDate && (
            <Dialog.Description>
              {t('apps.kinderConnect.transferDetails.description2', { lastTransferredDate })}
            </Dialog.Description>
          )}
          <div className="mt-8 flex flex-row gap-x-4">
            <Button label={t('common.close')} onClick={onClose} extraClasses="w-full" />
            {kinderSystemsData?.status !== KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED && (
              <Button
                primary
                label={lastTransferredDate ? t('common.retryTransfer') : t('common.startTransfer')}
                onClick={transferAttendance}
                extraClasses="w-full"
                loading={isTransferringAttendance}
              />
            )}
          </div>
        </>
      </Dialog.Panel>
    </Dialog>
  );
};
