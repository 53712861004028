import { firestore, functions } from './firebase';
import {
  deleteDocument,
  getDocument,
  getDocuments,
  getDocumentsAsArray,
  setDocument,
  startCollectionListener,
  updateDocument,
} from './firestore';

import { isBillingFeatureEnabled } from '../../helpers/billingFeatures';
import { BillingFeaturesEnum } from '../../helpers/billingFeatures/types';
import { InvoiceStatus } from '../../helpers/invoices';
import { findLocationIdForStudent } from '../../students/studentsUtils';
import { createInvoice } from '../payments/payments';
import { getOrganizationById } from './organizations';

import { DiscountAmountType } from '../../helpers/invoices';
import { convertToCents } from '../../helpers/money';
import { QueryConditionType } from '../../reports/types';

export const paths = {
  invoice: (organizationId, invoiceId) => `organizations/${organizationId}/invoices/${invoiceId}`,
  invoices: (organizationId) => `organizations/${organizationId}/invoices`,
  invoicePlans: (organizationId) => `organizations/${organizationId}/invoicePlans`,
  invoicePlan: (organizationId, planId) => `organizations/${organizationId}/invoicePlans/${planId}`,
};

const INVOICES_LIMIT = 2000; // We ran into performance issues with providers having 4-5k+ invoices so we decided to cap for now as a bandaid

export async function fetchOrganizationInvoices(
  organizationId,
  conditions = [],
  _orderBy = [],
  limit = null,
  startAfter = null
) {
  return getDocuments({
    path: paths.invoices(organizationId),
    conditions,
    // orderBy: [{ field: 'dateDue', direction: 'asc' }, ...orderBy],
    limit,
    startAfter,
  });
}

export async function fetchOrganizationInvoicePlans(
  organizationId,
  conditions: QueryConditionType[] = [],
  orderBy = [],
  limit = null,
  startAfter = null
): Promise<{
  count: number;
  list: {
    id: string;
    deletedAt?: string;
  }[];
}> {
  return getDocumentsAsArray({
    path: paths.invoicePlans(organizationId),
    conditions,
    orderBy: [{ field: 'dateStart', direction: 'desc' }, ...orderBy],
    limit,
    startAfter,
  });
}

export const fetchInvoicePlansWithMultipleStudents = async () => {
  const {
    list: invoicePlans,
  }: {
    list: {
      id: string;
      students?: any[];
    }[];
  } = await getDocumentsAsArray({
    path: 'invoicePlans',
    conditions: [
      {
        field: 'status',
        operation: '==',
        value: 'active',
      },
    ],
  });

  return invoicePlans.filter(({ students }) => students?.length && students?.length > 1);
};

export async function fetchOrganizationInvoice(organizationId: string, invoiceId: string) {
  getDocument({
    path: paths.invoice(organizationId, invoiceId),
  });
}

export function organizationInvoicesOnListen(
  {
    organizationId,
    locations,
    limitInvoices,
  }: {
    organizationId: string;
    locations: { [key: string]: boolean };
    limitInvoices: boolean;
  },
  next: (...args: any[]) => void,
  error: (error: Error) => void,
  complete?: () => void
) {
  const conditions: QueryConditionType[] = locations
    ? [
        {
          field: 'location',
          operation: 'in',
          value: Object.keys(locations),
        },
      ]
    : [];

  const queryOptions: any = {
    path: paths.invoices(organizationId),
    conditions,
    orderBy: [{ field: 'dateDue', direction: 'desc' }],
  };

  if (limitInvoices) {
    queryOptions.limit = INVOICES_LIMIT;
  }

  return startCollectionListener(queryOptions, next, error, complete);
}

export function organizationParentInvoicesOnListen(
  {
    organizationId,
    contactId,
  }: {
    organizationId: string;
    contactId: string;
  },
  next: (...args: any[]) => void,
  error: (error: Error) => void,
  complete?: () => void
) {
  const conditions: QueryConditionType[] = [
    {
      field: 'student.familyMembers',
      operation: 'array-contains',
      value: contactId,
    },
  ];

  return startCollectionListener(
    {
      path: paths.invoices(organizationId),
      conditions,
      orderBy: [{ field: 'dateDue', direction: 'asc' }],
    },
    next,
    error,
    complete
  );
}

async function createOrganizationInvoiceV1(organizationId: string, payload, batchResult) {
  const batch = firestore().batch();

  if (payload && payload.students && payload.students.length) {
    for (const student of payload.students) {
      if (!student) continue;

      const invoiceDocRef = firestore().collection('organizations').doc(organizationId).collection('invoices').doc();

      const locationId = findLocationIdForStudent(student);

      const invoiceData = {
        ...payload.details,
        organization: organizationId,
        location: locationId,
        student,
        createdAt: Date.now(),
      };

      batch.set(invoiceDocRef, invoiceData);
      batchResult[invoiceDocRef.id] = invoiceData;
    }
  }

  await batch.commit();
}

async function createOrganizationInvoiceV2(organizationId: string, payload: any, batchResult) {
  if (payload && payload.students && payload.students.length) {
    for (const student of payload.students) {
      if (!student) continue;

      const locationId = findLocationIdForStudent(student);

      const items = payload.details.invoiceItemList.map((item) => {
        const { amount, item: description, notes, discounts, ...rest } = item;
        return {
          ...rest,
          amount: convertToCents(amount), // Payments API stores money in cents
          description,
          note: notes,
          discounts: discounts.map((discount) => ({
            amount:
              discount.amountType === DiscountAmountType.CURRENCY ? convertToCents(discount.amount) : discount.amount, // Payments API stores money in cents
            amountType: discount.amountType,
            type: discount.discountType,
          })),
        };
      });

      const invoice = {
        createdByName: payload.details.createdBy.displayName,
        dateServiceEnd: payload.details.dateServiceEnd,
        dateServiceStart: payload.details.dateServiceStart,
        dueAt: payload.details.dateDue,
        items,
        locationId,
        manuallyPaid: payload.details.manuallyPaid,
        organizationId,
        studentId: student.id,
      };

      const result = await createInvoice(invoice);
      const invoiceId = result.data;

      batchResult[invoiceId] = {
        ...payload.details,
        organization: organizationId,
        location: locationId,
        student,
        createdAt: Date.now(),
      };
    }
  }
}

export async function createOrganizationInvoice(organizationId: string, payload: any) {
  const isV2Invoice = isBillingFeatureEnabled(
    await getOrganizationById(organizationId),
    BillingFeaturesEnum.inHouseInvoicing
  );

  // Need this object to pass into redux flow on create
  const batchResult = {};

  if (isV2Invoice) {
    await createOrganizationInvoiceV2(organizationId, payload, batchResult);
  } else {
    await createOrganizationInvoiceV1(organizationId, payload, batchResult);
  }

  return batchResult;
}

export async function createOrganizationInvoicePlan(organizationId, payload) {
  const batch = firestore().batch();
  const batchResult = {};

  if (payload && payload.students && payload.students.length) {
    for (const student of payload.students) {
      if (!student) continue;

      const invoiceDocRef = firestore()
        .collection('organizations')
        .doc(organizationId)
        .collection('invoicePlans')
        .doc();

      const invoicePlan = prepareInvoicePlanForWrite({
        ...payload,
        students: [student],
      });

      batch.set(invoiceDocRef, invoicePlan);

      // Need this object to pass into redux flow on create
      batchResult[invoiceDocRef.id] = invoicePlan;
    }
  }

  await batch.commit();
  return batchResult;
}
export const setOrganizationInvoicePlan = (organizationId, payload) => {
  const data = prepareInvoicePlanForWrite(payload);

  setDocument({
    path: `organizations/${organizationId}/invoicePlans/${payload.id}`,
    data,
  });
};

function prepareInvoicePlanForWrite(invoicePlan) {
  const { id, uid, ...strippedInvoicePlan } = invoicePlan;
  const locationId = invoicePlan?.students?.length ? findLocationIdForStudent(invoicePlan.students[0]) : '';
  const data = {
    ...strippedInvoicePlan,
    isInvoice: false,
    location: locationId,
    students: invoicePlan?.students ?? [],
    invoiceItemList: invoicePlan?.invoiceItems ?? invoicePlan.invoiceItemList ?? [],
    updatedAt: Date.now(),
  };

  if (!data.createdAt) data.createdAt = data.updatedAt;
  return data;
}

export async function updateOrganizationInvoice(organizationId, invoice) {
  if (!invoice?.id) throw Error('Invoice ID is required');

  const { id, ...data } = invoice;

  await setDocument({
    path: paths.invoice(organizationId, id),
    data,
  });
}
export async function removeOrganizationInvoice(organizationId, invoiceId) {
  await deleteDocument({
    path: paths.invoice(organizationId, invoiceId),
  });
}

export async function resendInvoiceToStripe(invoice) {
  const data = {
    status: InvoiceStatus.DRAFT,
    paidAmount: 0,
    //payments: [],
    stripe: 'null',
  };
  const path = paths.invoice(invoice.organization, invoice.id);
  await updateDocument({ path, data });
  data.status = InvoiceStatus.OPEN;
  await updateDocument({ path, data });
}

export const refreshInvoiceLinks = (stripeInvoiceId) =>
  functions().httpsCallable('callables-invoices-refreshInvoiceLinks')({
    stripeInvoiceId,
  });

export async function markInvoicesAsArchive(organizationId, invoices) {
  for (const invoice of invoices) {
    const data = {
      isArchived: true,
    };

    const path = paths.invoice(organizationId, invoice.id);
    await updateDocument({ path, data });
  }
}

export async function markRecurringPlansAsArchive(organizationId, plans) {
  for (const plan of plans) {
    const data = {
      isArchived: true,
    };

    const path = paths.invoicePlan(organizationId, plan.id);
    await updateDocument({ path, data });
  }
}

export const updateInvoiceStatus = async (stripeInvoiceId, status) => {
  const { data } = await functions().httpsCallable('callables-stripe-updateInvoiceStatus')({ stripeInvoiceId, status });
  return data;
};

export const createRefundForInvoice = async ({ invoiceId, organizationId, reason = '' }) => {
  const { data } = await functions().httpsCallable('callables-stripe-createRefundForInvoice')({
    invoiceId,
    organizationId,
    reason,
  });
  return data;
};
