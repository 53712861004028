import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Modal } from 'semantic-ui-react';

export default function ExistingContactConfirmationDialog({ existingContact, isOpen, onYes, onClose }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  if (!existingContact) return null;

  if (!onYes || !onClose) throw new Error('onYes and onClose are required');

  const message = t('{{firstName}} {{lastName}} is already a member of this organization.', {
    firstName: existingContact.firstName,
    lastName: existingContact.lastName,
  });

  return (
    <Modal open={!!isOpen} onClose={onClose} size="tiny">
      <Modal.Header data-testid="existing-user-found-btn">{t('Existing User Found')}</Modal.Header>
      <Modal.Content>
        <p>{message}</p>
        <p>{t('Would you like to add them to this student? If not, you must use a different email address.')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose} loading={isLoading} data-testid="no-btn">
          {t('common.no')}
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content={t('common.yes')}
          onClick={onYesLocal}
          loading={isLoading}
          data-testid="yes-btn"
        />
      </Modal.Actions>
    </Modal>
  );

  async function onYesLocal(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      await onYes(e);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      onClose(e);
    }
    onYes(e);
  }
}
