import { FeatureFlagsContext } from './FeatureFlagsContext';
import {
  IS_FEATURE_FLAGS_UI_ENABLED,
  IS_MAIN_MENU_BILLING_PAGES_ENABLED,
  IS_ROLLBAR_ENABLED,
  useFeatureFlag,
} from './useFeatureFlag';

export const FeatureFlagsProvider = ({ children }) => {
  const enabledFlag = useFeatureFlag(IS_FEATURE_FLAGS_UI_ENABLED);
  const rollbarFlag = useFeatureFlag(IS_ROLLBAR_ENABLED);
  const mainMenuBillingPages = useFeatureFlag(IS_MAIN_MENU_BILLING_PAGES_ENABLED);
  return (
    <FeatureFlagsContext.Provider
      value={{
        FeatureFlagsUIFlag: enabledFlag,
        RollbarFlag: rollbarFlag,
        MainMenuBillingPages: mainMenuBillingPages,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
