import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Form, Grid } from 'semantic-ui-react';

// Import components
import { useOrganization } from '../../../hooks/useOrganizations';
import { updateMoxitOrganization } from '../../../redux/actions/organizationActions';
import SectionCard from '../../Shared/SectionCard';

const EnforceAutomaticPayment = () => {
  const organization = useOrganization();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const description = t(
    organization?.automaticPayment?.enforce
      ? 'pageSettingsFinanceSectionEnforceAutomaticPaymentSwitchDescriptionOn'
      : 'pageSettingsFinanceSectionEnforceAutomaticPaymentSwitchDescriptionOff'
  );

  const onEnforceAutomaticPaymentChange = (e, { checked }) => {
    if (!organization.automaticPayment) {
      organization.automaticPayment = {};
    }

    organization.automaticPayment.enforce = checked;
    organization.automaticPayment.modifiedDate = Date.now();

    dispatch(updateMoxitOrganization(organization));
  };

  const renderSettingsList = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column width={3} className="text-right">
          <Form.Radio
            data-testid="enforce-automatic-payment-switch"
            toggle
            control={Checkbox}
            checked={organization?.automaticPayment?.enforce}
            onChange={onEnforceAutomaticPaymentChange}
          />
        </Grid.Column>
        <Grid.Column width={13}>
          <p data-testid="enforce-automatic-payment-switch-label">
            <strong>{t('pageSettingsFinanceSectionEnforceAutomaticPaymentSwitchLabel')}</strong>
          </p>
          <p data-testid="enforce-automatic-payment-switch-description">{description}</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  return (
    <SectionCard
      header={t('pageSettingsFinanceSectionEnforceAutomaticPaymentTitle')}
      component={() => renderSettingsList()}
    />
  );
};

export default EnforceAutomaticPayment;
