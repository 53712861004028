// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { kebabCase } from 'lodash';
import { Route, Switch } from 'react-router-dom';

import { useRoutes } from '../navigationHooks';
import { paths } from '../paths';
import { DefaultRoute, GuestRoute, RestrictedRoute, VerifyRoute } from './routes';

// Transition Pages
import AuthReturnPage from '../../Pages/AuthReturn';
import CompleteSignupPage from '../../Pages/CompleteSignup';
import SignoutPage from '../../Pages/Signout';

// Guest Pages
import SigninPage from '../../Pages/Signin';
import SignUpPage from '../../Pages/Signup';

// User Pages
import EmailSentPage from '../../Pages/EmailSent';
import MarketplaceExternal from '../../Pages/MarketplaceExternal';

// Misc Pages
import ErrorPage from '../../Pages/Error';
import UnauthorizedPage from '../../Pages/Unauthorized';
import { FeatureFlagsPage, useFeatureFlags } from '../../feature-flags';

import useOrganizationImpersonationResetListener from '../../hooks/useOrganizationImpersonationResetListener';
import { useOrganization } from '../../hooks/useOrganizations';
import useTrackers from '../../hooks/useTrackers';
import { useMarketplaceHeadless } from '../../integrations';
import { buildMarketplaceLink } from '../navigationUtils';

const AppRouter = () => {
  useOrganizationImpersonationResetListener();
  useTrackers();

  const { FeatureFlagsUIFlag } = useFeatureFlags();
  const { allRoutes } = useRoutes();
  const organization = useOrganization();
  const headlessConfig = useMarketplaceHeadless();

  return (
    <Switch>
      {/* Transition Routes */}
      <Route path={paths.authReturn} component={AuthReturnPage} />
      <Route path={paths.signout} component={SignoutPage} />
      <Route path={paths.signoutFull} render={(props) => <SignoutPage fullSignoutMode={true} {...props} />} />

      <Route
        path={paths.completeSignup}
        render={() => (
          <RestrictedRoute route={{ path: paths.completeSignup }}>
            <CompleteSignupPage />
          </RestrictedRoute>
        )}
      />

      {/* Guest Routes */}
      <Route
        path={paths.signin}
        render={() => (
          <GuestRoute redirect={paths.home}>
            <SigninPage />
          </GuestRoute>
        )}
      />
      <Route
        path={paths.signup}
        render={() => (
          <GuestRoute redirect={paths.home}>
            <SignUpPage />
          </GuestRoute>
        )}
      />

      {/* User Routes */}
      {createRestrictedRoutes(allRoutes, organization, headlessConfig)}

      <Route
        path={paths.verify}
        exact
        render={() => (
          <VerifyRoute>
            <EmailSentPage />
          </VerifyRoute>
        )}
      />

      {/* Misc Routes */}
      <Route path={paths.r401} component={UnauthorizedPage} />
      <Route path={paths.r404} component={ErrorPage} />
      <Route path={paths.authError} component={ErrorPage} />
      {FeatureFlagsUIFlag.enabled && <Route path={paths.featureFlags} component={FeatureFlagsPage} />}
      <Route path={paths.error} component={ErrorPage} />
      <Route render={() => <DefaultRoute component={paths.home} />} />
    </Switch>
  );
};

function createRestrictedRoutes(routes, organization, headlessConfig) {
  const makeRestrictedRoute = (route, headlessConfig) => {
    const Component = route.component;
    const isAppRoute = !!route?.path && !!route.component;
    const isHeadlessRoute = !!route.headlessPath;
    const channelId = headlessConfig.channelId;

    if (isAppRoute) {
      return (
        <Route
          key={route.path}
          path={route.path}
          exact
          render={() => <RestrictedRoute route={route}>{<Component />}</RestrictedRoute>}
        />
      );
    } else if (isHeadlessRoute) {
      const pathSlug = `/${kebabCase(route.name)}`;
      const url = buildMarketplaceLink({ path: route.headlessPath, organization, channelId });
      return (
        <Route
          key={pathSlug}
          path={pathSlug}
          exact
          render={() => (
            <RestrictedRoute route={route}>{<MarketplaceExternal url={url} routeName={route.name} />}</RestrictedRoute>
          )}
        />
      );
    }

    return null;
  };

  return routes.map((route) => makeRestrictedRoute(route, headlessConfig));
}
export default AppRouter;
