import { useCallback, useEffect, useMemo, useState } from 'react';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Checkbox, Segment } from 'semantic-ui-react';

import PageHeader from '../../../Components/Shared/PageHeader';
import { getOrganizationById } from '../../../api/firebase/organizations';
import { toDateObject } from '../../../helpers/dates';
import { useAuthUser } from '../../../hooks/useUser';
import { useMarketplaceAPI } from '../../../integrations';
import { logError } from '../../../rollbar';

import { enrollStudentsNow, fetchStudentsToEnroll } from '../../enrollmentsAPI';
import { EnrollmentStatusEnum } from '../../enums';

const EnrollmentsAdminPage: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const { currentUser: user } = useAuthUser();
  const { sendEnrollmentStatus } = useMarketplaceAPI();

  const [studentsToEnroll, setStudentsToEnroll] = useState<any[] | undefined>();
  const [organizationMap, setOrganizationMap] = useState<Record<string, any>>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<any[]>([]);

  useEffect(() => {
    async function fetchLocal() {
      try {
        if (isLoading) return;
        setIsLoading(true);
        const students = await fetchStudentsToEnroll({ targetDate: new Date() });
        setStudentsToEnroll(students.list);
      } catch (err) {
        logError('Error fetching students to enroll', err);
      } finally {
        setIsLoading(false);
      }
    }
    if (!isLoading && !studentsToEnroll) {
      fetchLocal();
    }
  }, [isLoading, studentsToEnroll]);

  useEffect(() => {
    async function fetchOrganizations() {
      if (!studentsToEnroll) return;

      const orgMap: Record<string, any> = {};
      for (const student of studentsToEnroll) {
        const organizationId = student.organization || student.defaultOrganization;
        if (!organizationId || orgMap[organizationId]) continue; // No id, or already fetched

        try {
          const organization = await getOrganizationById(organizationId);
          if (organization) orgMap[organizationId] = organization;
        } catch (err) {
          logError('Error fetching organization', organizationId, err);
        }
      }
      setOrganizationMap(orgMap);
    }
    if (studentsToEnroll && !organizationMap) {
      fetchOrganizations();
    }
  }, [organizationMap, studentsToEnroll]);

  const sendEnrollmentStatuses = useCallback(async () => {
    if (!studentsToEnroll?.length) return;

    for (const student of studentsToEnroll) {
      const marketplaceLeadId = student.enrollment?.interestedFamily?.id;
      if (!marketplaceLeadId) continue;
      try {
        await sendEnrollmentStatus(EnrollmentStatusEnum.ENROLLED, marketplaceLeadId);
      } catch (err) {
        //log the error and continue
        logError('sendEnrollmentStatus error', err);
      }
    }
  }, [sendEnrollmentStatus, studentsToEnroll]);

  const selectRow: SelectRowProps<any> = useMemo(
    () => ({
      mode: 'checkbox',
      style: {},
      clickToSelect: true,
      selectionRenderer: ({ mode, rowKey, rowIndex, ...rest }: any) => (
        <Checkbox data-testid={`enrollment-admin-${rowIndex}`} {...rest} />
      ),
      onSelect: (row: any, isSelected: boolean) => {
        const selected = isSelected ? [...selectedStudents, row] : selectedStudents.filter((c) => c.id !== row.id);
        setSelectedStudents(selected);
      },
      onSelectAll: (isSelected: boolean, rows: any[]) => {
        const selected = isSelected ? rows : [];
        setSelectedStudents(selected);
      },
    }),
    [selectedStudents]
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'displayName',
        text: t('Student Name'),
        sort: true,
      },
      {
        dataField: 'enrollment.startDate',
        text: t('Start Date'),
        sort: true,
        formatter: (cell: string) => {
          return cell ? toDateObject(cell)?.toLocaleDateString() : '';
        },
      },
      {
        dataField: 'organization',
        text: t('Organization'),
        sort: true,
        formatter: (cell: string) => {
          return organizationMap?.[cell]?.name ?? 'Unknown';
        },
      },
    ],
    [organizationMap, t]
  );

  const onEnrollButtonClick = useCallback(
    async (e: any) => {
      setIsLoading(true);

      e.preventDefault();
      e.stopPropagation();
      try {
        await enrollStudentsNow(selectedStudents, user);
        await sendEnrollmentStatuses();
        setStudentsToEnroll(undefined);
        setOrganizationMap(undefined);
      } catch (err) {
        logError('onEnrollButtonClick error', err);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedStudents, sendEnrollmentStatuses, user]
  );

  if (isLoading || !studentsToEnroll || !organizationMap) {
    return <div>Loading...</div>;
  }

  return (
    <Segment basic clearing>
      <PageHeader pageName={'Enrollments Admin'} classes="enrollments-admin-page" />
      <h1>
        <Button
          data-testid="enrollments-admin-enroll-button"
          floated="right"
          primary
          disabled={selectedStudents.length === 0}
          onClick={onEnrollButtonClick}
        >
          {t('Enroll Students')}
        </Button>
        {t('enrollments.adminPageTitle')}
      </h1>
      <ToolkitProvider bootstrap4 keyField="id" data={studentsToEnroll} columns={columns}>
        {({ baseProps }) => (
          <div className="bootstrap-iso">
            <BootstrapTable
              classes="w-auto w-md-100"
              headerClasses="table-header"
              wrapperClasses="table-responsive"
              bordered={false}
              hover={true}
              selectRow={selectRow}
              {...baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </Segment>
  );
};

export default EnrollmentsAdminPage;
