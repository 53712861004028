import { useFlags } from 'launchdarkly-react-client-sdk';

import AttendancePageV1 from '../../../Pages/Reports/Attendance';
import AttendancePage from './AttendancePage';

export const Attendance: React.FC = () => {
  const { isTailwindReportsEnabled } = useFlags();

  return isTailwindReportsEnabled ? <AttendancePage /> : <AttendancePageV1 />;
};
export default Attendance;
