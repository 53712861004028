import { Dispatch, FormEvent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, CheckboxProps, Form, Popup } from 'semantic-ui-react';
import { EnrollmentFormDataType } from '../../../enrollments';
import { WsInfo } from '../../../icons';
import { RouteNameEnum, useRoutes } from '../../../navigation';

interface StudentFormPendingEnrollmentProps {
  automaticallyEnroll: boolean;
  studentId: string;
  setFormData: Dispatch<SetStateAction<EnrollmentFormDataType>>;
}

export function StudentFormPendingEnrollment({
  automaticallyEnroll,
  studentId,
  setFormData,
}: StudentFormPendingEnrollmentProps) {
  const { t } = useTranslation();
  const { getRoutePathByName } = useRoutes();

  function onChange(_: FormEvent<HTMLInputElement>, data: CheckboxProps) {
    setFormData((prev) => ({ ...prev, automaticallyEnroll: !!data.checked }));
  }

  return (
    <>
      <Form.Field>
        <div className="ws-icon-control">
          <Checkbox
            checked={automaticallyEnroll}
            name="automaticallyEnroll"
            label={t('students.automaticallyEnrollLabel')}
            onChange={onChange}
            data-testid="enroll-automatically"
          />
          <Popup trigger={<WsInfo className="info-icon" />} content={t('students.automaticallyEnrollPopup')} />
        </div>
      </Form.Field>
      <Form.Field>
        <Link
          to={
            getRoutePathByName(RouteNameEnum.STUDENT_ENROLLMENT, [
              {
                name: 'studentId',
                value: studentId,
              },
            ]) ?? '#'
          }
          data-testid="continueToDigitalEnrollment-link"
        >
          {t('enrollments.continueToOnlineEnrollment')}
        </Link>
      </Form.Field>
    </>
  );
}
