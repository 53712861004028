import React, { useCallback } from 'react';
import { SortDirectionEnum } from '../../enums';
import { WsSortButtonProps } from './types';

import {
  ChevronDownIcon as DownIcon,
  ChevronUpDownIcon as UpDownIcon,
  ChevronUpIcon as UpIcon,
} from '@heroicons/react/20/solid';

export function WsSortButton({ sortDirection, onSort, name, 'data-testid': testId }: WsSortButtonProps) {
  const onClickLocal = useCallback(
    (e: React.MouseEvent<HTMLElement>, direction: SortDirectionEnum) => {
      e.preventDefault();
      onSort(name, direction);
    },
    [name, onSort]
  );

  const UpButton = useCallback(() => {
    return (
      <button
        name={name}
        onClick={(e) => {
          onClickLocal(e, SortDirectionEnum.DESC);
        }}
        data-testid={`${testId}-up`}
      >
        <UpIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
      </button>
    );
  }, [name, onClickLocal, testId]);

  const DownButton = useCallback(() => {
    return (
      <button
        name={name}
        onClick={(e) => {
          onClickLocal(e, SortDirectionEnum.ASC);
        }}
        data-testid={`${testId}-down`}
      >
        <DownIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
      </button>
    );
  }, [name, onClickLocal, testId]);

  const NoneButton = useCallback(() => {
    return (
      <button
        name={name}
        onClick={(e) => {
          onClickLocal(e, SortDirectionEnum.DESC);
        }}
        data-testid={`${testId}-down`}
      >
        <UpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </button>
    );
  }, [name, onClickLocal, testId]);

  if (name !== sortDirection.name) {
    return <NoneButton />;
  } else if (sortDirection.direction === SortDirectionEnum.DESC) {
    return <DownButton />;
  } else {
    return <UpButton />;
  }
}
export default WsSortButton;
