import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { groupBy, isEmpty, keyBy } from 'lodash';
import { withTranslation } from 'react-i18next';

// Import actions
import { getOrganizationInvoices } from '../../../../redux/actions/invoiceActions';

// Import component
import NoDataMessage from '../../../Shared/NoDataMessage';
import AgingList from './AgingList';

// Import utils
import { Invoice } from '@wonderschool/ws-types';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import { getPastDueInvoices, InvoiceStatus } from '../../../../helpers/invoices';
import { selectTotalByEmployee } from '../../../../helpers/utils';

type AgingContainerProps = {
  totalByEmployee: any;
  totalSummary: any;
  t: any;
};

function AgingContainer({ totalByEmployee, totalSummary, t }: AgingContainerProps) {
  if (isEmpty(totalByEmployee)) return <NoDataMessage message={t('agingContainer.missingInvoicesHelp')} />;

  return (
    <div className="rounded-lg bg-white sm:border sm:border-gray-200">
      <div className="sm:p-4">
        <AgingList totalByEmployee={totalByEmployee} totalSummary={totalSummary} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    invoices: {
      all: { list },
    },
  } = state;

  // Recently invoices.list was converted from an array to an object map. Not sure why. This is a bandaid to convert back to an array to get Aging Reports working agin.
  // Created a ticket for root cause fix: https://linear.app/wonderschool/issue/PAYM-3576/root-cause-fix-for-aging-reports
  let convertedList = list;
  if (list && _.isObject(list)) {
    convertedList = Object.values(list) as Invoice[];
  }

  const agingReportInvoices = Array.isArray(convertedList)
    ? getPastDueInvoices(
        convertedList.filter((invoice) => {
          return (
            invoice?.manuallyPaid !== true &&
            invoice?.status !== InvoiceStatus.PAID &&
            invoice?.status !== InvoiceStatus.VOID
          );
        })
      )
    : [];

  // Group invoices by student
  const invoiceGroupedByStudent = groupBy(agingReportInvoices, 'student.id');

  const studentsObj: Record<string, any> = {};

  // Find and convert the invoices for each student to an object.

  Object.keys(invoiceGroupedByStudent).forEach((studentId) => {
    studentsObj[studentId] = keyBy(invoiceGroupedByStudent[studentId], 'id');
  });

  const totalByEmployee = !isEmpty(studentsObj) ? selectTotalByEmployee(studentsObj).totalByEmployee : [];
  const totalSummary = !isEmpty(studentsObj) ? selectTotalByEmployee(studentsObj).totalSummary : {};

  return {
    totalByEmployee,
    totalSummary,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationInvoices,
    },
    dispatch
  );
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AgingContainer));
