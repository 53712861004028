import React from 'react';
import Attendance from '../../../reports/attendance/components/AttendancePage';

export const KinderConnectReports: React.FC = () => {
  return (
    <div className="-mx-7 -my-10 overflow-x-auto">
      <Attendance hideFoodProgramFilter={true} kinderSystemsReport={true} />
    </div>
  );
};
