import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { logError } from '../rollbar';
import { fetchNetwork, fetchNetworks, seedNetworksData } from './networksAPI';
import { networkFetchedAction, networksFetchedAction } from './networksRedux';

const selectNetworksList = createSelector([(state) => state.networks], (networks) => networks?.list);
const selectCurrentNetwork = createSelector([(state) => state.networks], (networks) => networks?.currentNetwork);

export function useNetworksDataToSeed() {
  const [networksDataToSeed, setNetworksDataToSeed] = useState(null);

  useEffect(() => {
    const doSeedNetworksData = async () => {
      if (networksDataToSeed) {
        try {
          await seedNetworksData(networksDataToSeed);
        } catch (error) {
          logError('Seeding network data failed:', error);
        } finally {
          setNetworksDataToSeed(null);
        }
      }
    };
    doSeedNetworksData();
  }, [networksDataToSeed]);

  return [networksDataToSeed, setNetworksDataToSeed];
}

export function useNetworks() {
  const networksList = useSelector(selectNetworksList);
  const dispatch = useDispatch();
  const [networks, setNetworks] = useState(networksList);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!networks) {
      doFetchNetworks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networks]);

  const doFetchNetworks = async () => {
    try {
      setIsLoading(true);
      const _networks = await fetchNetworks();
      setNetworks(_networks.list);
      dispatch(networksFetchedAction(_networks));
    } catch (error: any) {
      logError('Fetching network data failed:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { networks, doFetchNetworks, isLoading };
}

export function useNetwork(networkIdOrLegoId) {
  const currentNetwork = useSelector(selectCurrentNetwork);
  const dispatch = useDispatch();

  const [network, setNetwork] = useState(networkIdOrLegoId ? null : currentNetwork);

  useEffect(() => {
    const doFetchNetwork = async () => {
      try {
        const _network = await fetchNetwork(networkIdOrLegoId);
        setNetwork(_network);
        dispatch(networkFetchedAction(_network));
      } catch (error: any) {
        logError('Fetching network data failed:', error.message);
      }
    };
    if (networkIdOrLegoId && !network) {
      doFetchNetwork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkIdOrLegoId]);

  return network;
}
