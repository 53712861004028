import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Button, Checkbox, Container, Form, Grid, Header, Loader, Modal, Segment } from 'semantic-ui-react';
import { logError } from '../../rollbar';

import { userHasPermission } from '../../api/firebase/account';
import { CSV, DOC, DOCX, JPEG, PDF, PNG, TXT, XLS, XLSX } from '../../helpers/mimeTypes';
import { formatFullName } from '../../helpers/utils';
import { useOrganization } from '../../hooks/useOrganizations';
import { useUser } from '../../hooks/useUser';

import { fetchBillingDetails, saveStudent } from '../studentsAPI';
import { useSelectedStudent } from '../studentsHooks';
import { organizationUpdateStudent, studentUpdated } from '../studentsRedux';
import {
  archiveStudent,
  computeEnforceAutomaticPayment,
  isStudentArchived,
  isStudentUnenrolled,
  unarchiveStudent,
} from '../studentsUtils';

import { Dialog } from '@wonderschool/common-base-ui';
import DocumentUploader from '../../Components/Upload/DocumentUploader';
import { WsAddCircle, WsContacts } from '../../icons';
import StudentArchiveConfirmationModal from './StudentArchiveConfirmationModal';
import StudentDocumentList from './StudentDocumentList';
import StudentInvoicesTable from './StudentInvoicesTable';
import StudentPersonalInformation from './StudentPersonalInformation';
import StudentPersonalInformationForm from './StudentPersonalInformationForm';
import StudentPrograms from './StudentPrograms';
import StudentProgramsForm from './StudentProgramsForm';
import StudentEnrollment from './enrollment/StudentEnrollment';
import StudentEnrollmentForm from './enrollment/StudentEnrollmentForm';
import { FamilyEditContactModal, FamilyList } from './family';

export default function StudentDetail({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useOrganization();
  const selectedStudent = useSelectedStudent();
  const { studentArchive: studentArchiveFlag } = useFlags();

  const [isStudentPersonalInformationFormOpen, setIsStudentPersonalInformationFormOpen] = useState(false);
  const [isStudentEnrollmentFormOpen, setIsStudentEnrollmentFormOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [billingDetails, setBillingDetails] = useState(null);
  const [isStudentProgramsFormOpen, setIsStudentProgramsFormOpen] = useState(false);

  const user = useUser();
  const canRoleListBilling = userHasPermission('can_list_billing');
  const canRoleEditStudent = userHasPermission('can_edit_student');
  const canRoleEditStudentPersonal = userHasPermission('can_edit_student_personal');
  const canRoleEditStudentContacts = userHasPermission('can_edit_student_contacts');

  const allowedMimeTypes = {
    PDF,
    PNG,
    JPEG,
    DOC,
    DOCX,
    XLS,
    XLSX,
    CSV,
    TXT,
  };

  const allowedFileTypes = Object.values(allowedMimeTypes);
  const allowedFileTypeNames = Object.keys(allowedMimeTypes);

  const allowedFileTypeNamesStart = allowedFileTypeNames.slice(0, -1).join(', ');
  const allowedFileTypeNamesEnd = allowedFileTypeNames.slice(-1);

  const maxFileSize = '30MB';

  const processUploadedDocuments = (uploads) => {
    if (!uploads?.length) return;

    const { uid, email } = user;

    const documentUploadPath = `organizations/${organization.id}/students/${selectedStudent.id}/documents`;

    let documents = {
      ...selectedStudent.documents,
    };

    uploads.forEach((upload) => {
      const name = upload.name ? upload.name.split('.')[0] : 'Unknown';

      documents[upload.meta.refId] = {
        name,
        documentId: upload.meta.refId,
        path: documentUploadPath,
        type: upload.type,
        size: upload.size,
        extension: upload.extension,
        progress: upload.progress,
        meta: upload.meta,
        downloadUrl: upload.downloadUrl,
        uploadedBy: { uid, email, name: formatFullName(user, true) },
        uploadedDate: Date.now(),
        tags: [],
      };
    });

    dispatch(
      organizationUpdateStudent(organization.id, {
        ...selectedStudent,
        documents,
      })
    );
  };
  const onSaveStudent = async (newStudent) => {
    try {
      await saveStudent(organization.id, newStudent);
      dispatch(studentUpdated());
      setIsArchiveModalOpen(false);
      onClose?.();
    } catch (error) {
      logError('Error trying to save student.', error);
      throw new Error(error);
    }
  };
  const onArchiveStudent = async () => {
    try {
      const newStudent = archiveStudent(selectedStudent);
      await onSaveStudent(newStudent);
    } catch (error) {
      logError('Error trying to archive student.', error);
      throw new Error(error);
    }
  };
  const onRestoreStudent = async () => {
    try {
      const newStudent = unarchiveStudent(selectedStudent);
      await onSaveStudent(newStudent);
    } catch (e) {
      logError('Error trying to unarchive student.', e);
      throw new Error(e);
    }
  };
  const onEnforceAutomaticPaymentChange = (e, { checked }) => {
    if (!selectedStudent?.id || !organization?.id || user.isParent) return;

    if (!selectedStudent.automaticPayment) {
      selectedStudent.automaticPayment = {};
    }
    selectedStudent.automaticPayment.enforce = checked;
    selectedStudent.automaticPayment.modifiedDate = Date.now();

    dispatch(organizationUpdateStudent(organization.id, selectedStudent));
  };

  useEffect(() => {
    const fetchData = async () => {
      const billingDetails = {
        hasPaymentMethod: false,
        canChargeAutomatically: false,
      };

      try {
        Object.assign(billingDetails, await fetchBillingDetails(organization.id, selectedStudent.id));
      } catch (e) {
        console.error('Error trying to get billing details for responsible parent.', e);
      }

      setBillingDetails(billingDetails);
    };

    if (!user.isParent && canRoleListBilling && selectedStudent.id) {
      fetchData();
    }
  }, [selectedStudent.id, organization.id, user.isParent, canRoleListBilling]);

  const renderStudentEnrollmentForm = useCallback(() => {
    return <StudentEnrollmentForm student={selectedStudent} onClose={() => setIsStudentEnrollmentFormOpen(false)} />;
  }, [selectedStudent]);

  const renderStudentProgramsForm = useCallback(() => {
    return <StudentProgramsForm student={selectedStudent} onClose={() => setIsStudentProgramsFormOpen(false)} />;
  }, [selectedStudent]);

  return (
    <Container fluid className="no-padding no-margin student-detail">
      <FamilyEditContactModal
        student={selectedStudent}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
      <StudentArchiveConfirmationModal
        isOpen={isArchiveModalOpen}
        onYes={onArchiveStudent}
        onNo={() => setIsArchiveModalOpen(false)}
      />
      <Grid stackable columns="equal">
        <Grid.Column>
          <StudentPersonalInformationSegment />
        </Grid.Column>
        <Grid.Column>
          <StudentEnrollmentSegment />
          <StudentProgramsSegment />
        </Grid.Column>
      </Grid>

      <Grid stackable columns="equal">
        <Grid.Column>
          <Segment basic clearing>
            <Segment basic clearing className="no-padding">
              <h2 data-testid="family-contact-title" className={'flex items-center justify-between'}>
                <span className={'text-xl'}>
                  <WsContacts className={'text-gray-800'} /> {t('familyAndContactsLabel')}
                </span>
                {canRoleEditStudentContacts && (
                  <a
                    icon="add"
                    className="round add-contact text-base text-blue-900"
                    onClick={() => setIsAddModalOpen(true)}
                    data-testid="add-contact-btn"
                  >
                    <WsAddCircle /> {t('common.addContact')}
                  </a>
                )}
              </h2>
            </Segment>
            <FamilyList />
          </Segment>
        </Grid.Column>
      </Grid>

      {/* temporarily hiding this view from parents until invoice data issue is resolved */}
      {!user.isParent && canRoleListBilling && (
        <Grid stackable columns="equal">
          <Grid.Column>
            <Segment basic clearing>
              <Segment basic clearing className="no-padding">
                <Header as="h2" floated="left" content={t('All Invoices')} data-testid="invoices-title" />
                <Form.Radio
                  toggle
                  disabled={user.isParent}
                  id="enforceAutomaticPayment"
                  name="enforceAutomaticPayment"
                  label={
                    <label>
                      {t('studentDetailsEnforce')} <strong>{t('studentDetailsAutomaticPayment')}</strong>
                    </label>
                  }
                  className="vertical-align-sub text-right"
                  onChange={onEnforceAutomaticPaymentChange}
                  checked={computeEnforceAutomaticPayment(selectedStudent, organization)}
                  control={Checkbox}
                  data-testid="auto-payment"
                />
              </Segment>

              <Grid stackable columns="equal" className="top-border padding-bottom">
                <Grid.Column>
                  <strong>{t('studentDetailsResponsibleParentOnAutomaticPayment')}: </strong>
                  {billingDetails ? (
                    t(
                      billingDetails.canChargeAutomatically
                        ? 'studentDetailsResponsibleParentOnAutomaticPaymentYes'
                        : 'studentDetailsResponsibleParentOnAutomaticPaymentNo'
                    )
                  ) : (
                    <Loader size="mini" active inline></Loader>
                  )}
                </Grid.Column>
                <Grid.Column className="text-right">
                  <i className="credit card icon outline"></i>
                  {billingDetails ? (
                    t(
                      billingDetails.hasPaymentMethod
                        ? 'studentDetailsPaymentMethodAdded'
                        : 'studentDetailsNoPaymentMethodAdded'
                    )
                  ) : (
                    <Loader size="mini" active inline></Loader>
                  )}
                </Grid.Column>
              </Grid>

              <StudentInvoicesTable />
            </Segment>
          </Grid.Column>
        </Grid>
      )}

      <Grid stackable columns="equal">
        <Grid.Column>
          <Segment basic clearing>
            <Segment basic clearing className="no-padding">
              {canRoleEditStudent && (
                <Segment basic floated="right" className="no-padding no-margin">
                  <DocumentUploader
                    uploadPath={`organizations/${organization.id}/students/${selectedStudent.id}/documents`}
                    title={t('Uploading Student Documents')}
                    allowedFileTypes={allowedFileTypes}
                    onUploaded={processUploadedDocuments}
                    note={t(
                      'Only {{allowedFileTypeNamesStart}}, or {{allowedFileTypeNamesEnd}} files up to {{maxFileSize}} are allowed.',
                      {
                        allowedFileTypeNamesStart,
                        allowedFileTypeNamesEnd,
                        maxFileSize,
                      }
                    )}
                    data-testid="upload-doc"
                  />
                </Segment>
              )}

              <Header as="h2" floated="left" content={t('Files')} data-testid="files-title" />
            </Segment>

            <StudentDocumentList />
          </Segment>
        </Grid.Column>
      </Grid>
      {studentArchiveFlag && (
        <Grid stackable columns="equal">
          <Grid.Column textAlign="center">
            <Segment basic clearing>
              <Segment basic clearing className="no-padding">
                {canRoleEditStudent && !isStudentArchived(selectedStudent) && isStudentUnenrolled(selectedStudent) && (
                  <>
                    <p>{t('students.archiveStudentModal.hideRemoveStudentFromView')}</p>
                    <Button
                      negative
                      fluid
                      content={t('students.archiveStudentModal.archiveStudent')}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsArchiveModalOpen(true);
                      }}
                      data-testid="student-archive-btn"
                    />{' '}
                  </>
                )}
                {canRoleEditStudent && isStudentArchived(selectedStudent) && (
                  <>
                    <p>{t('students.archiveStudentModal.unarchiveStudent')}</p>
                    <Button
                      primary
                      fluid
                      content={t('students.archiveStudentModal.restoreStudent')}
                      onClick={(e) => {
                        e.preventDefault();
                        onRestoreStudent();
                      }}
                      data-testid="student-unarchive-btn"
                    />{' '}
                  </>
                )}
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>
      )}
    </Container>
  );

  function StudentPersonalInformationSegment() {
    return (
      <Segment basic clearing>
        <Segment basic clearing className="no-padding">
          {canRoleEditStudentPersonal && (
            <Button
              primary
              floated="right"
              content={t('common.edit')}
              size="tiny"
              className="round"
              onClick={(e) => {
                e.preventDefault();
                setIsStudentPersonalInformationFormOpen(true);
              }}
              data-testid="student-personal-info-edit-btn"
            />
          )}

          <Header as="h2" floated="left" content={t('Personal Info')} data-testid="personal-info-title" />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          <StudentPersonalInformation />
        </Segment>

        <Modal
          closeIcon
          centered={false}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={isStudentPersonalInformationFormOpen}
          onClose={() => setIsStudentPersonalInformationFormOpen(false)}
          header={t('Personal Info')}
          content={
            <StudentPersonalInformationForm
              hideCancel
              clearOnUnmount={false}
              done={() => setIsStudentPersonalInformationFormOpen(false)}
            />
          }
          data-testid="personal-info-modal"
        />
      </Segment>
    );
  }
  function StudentEnrollmentSegment() {
    return (
      <Segment basic clearing>
        <Segment basic clearing className="no-padding">
          {canRoleEditStudent && (
            <Button
              primary
              floated="right"
              content={t('common.edit')}
              size="tiny"
              className="round"
              onClick={(e) => {
                e.preventDefault();
                setIsStudentEnrollmentFormOpen(true);
              }}
              data-testid="enrollment-edit-btn"
            />
          )}

          <Header as="h2" floated="left" content={t('Enrollment')} data-testid="enrollment-title" />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          <StudentEnrollment student={selectedStudent} />
        </Segment>

        <Dialog
          isOpen={isStudentEnrollmentFormOpen}
          onClose={() => setIsStudentEnrollmentFormOpen(false)}
          data-testid="student-enrollment-form-modal"
        >
          <Dialog.Title>
            <div className="flex flex-col gap-2 p-4 text-xl font-bold">{t('Enrollment')}</div>
          </Dialog.Title>
          <Dialog.Description>{renderStudentEnrollmentForm()}</Dialog.Description>
        </Dialog>
      </Segment>
    );
  }

  function StudentProgramsSegment() {
    return (
      <>
        <div className="flex w-full items-center justify-between px-0">
          <Header as="h2" floated="left" content={t('Programs')} data-testid="programs-title" />
          {canRoleEditStudent && (
            <Button
              primary
              floated="right"
              content={t('common.edit')}
              size="tiny"
              className="round"
              onClick={(e) => {
                e.preventDefault();
                setIsStudentProgramsFormOpen(true);
              }}
              data-testid="programs-edit-btn"
            />
          )}
        </div>

        <div className="mt-2 border-t shadow-none">
          <StudentPrograms student={selectedStudent} className="mt-2" />
        </div>

        <Dialog
          isOpen={isStudentProgramsFormOpen}
          onClose={() => setIsStudentProgramsFormOpen(false)}
          data-testid="student-programs-form-modal"
        >
          <Dialog.Panel>
            <Dialog.Title>{t('Programs')}</Dialog.Title>
            <Dialog.Description>{renderStudentProgramsForm()}</Dialog.Description>
          </Dialog.Panel>
        </Dialog>
      </>
    );
  }
}
