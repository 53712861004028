import { CONTACT_STATUS } from '@wonderschool/ws-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirectionEnum, SortDirectionType, WsAvatar, WsSortButton, sortCollection } from '../../common';
import { useUser } from '../../hooks/useUser';
import { ClockedIn } from '../../icons';
import { DailyHoursType } from '../types';
import { SortColumnEnum } from './DailyHoursTable';
import { calculateIfClockInOutDatesAreOvernight } from './utils/calculateClockInOutTimeDifference';
import { canUserAddTimecards } from './utils/validateTimeCards';

type TimesheetDataTableProps = {
  dailyHours: DailyHoursType[];
  onTimecardSelected: (timecardId: string) => void;
};

export function TimesheetDataTable({ dailyHours, onTimecardSelected }: TimesheetDataTableProps) {
  const { t } = useTranslation();
  const user = useUser();

  const [dailyHoursSorted, setDailyHoursSorted] = useState<DailyHoursType[]>(dailyHours);
  const [sortDirection, setSortDirection] = useState<SortDirectionType>({
    name: SortColumnEnum.DATE,
    direction: SortDirectionEnum.DESC,
  });

  const onSort = useCallback((name: string, direction: SortDirectionEnum) => {
    setSortDirection({ name, direction: direction });
  }, []);

  useEffect(() => {
    if (!dailyHours) return;
    setDailyHoursSorted(sortCollection(dailyHours, sortDirection) as DailyHoursType[]);
  }, [dailyHours, sortDirection]);

  return (
    <table className="w-full overflow-x-scroll" data-testid="daily-hours-table">
      <thead className="bg-gray-100" data-testid="daily-hours-thead">
        <tr className="border-y-2 border-gray-300">
          <TableHeader />
          <TableHeader
            sortKey={SortColumnEnum.NAME}
            label="Name"
            onSort={onSort}
            sortDirection={sortDirection}
            data-testid="sort-name"
          />
          <TableHeader
            sortKey={SortColumnEnum.ROOM}
            label="Room"
            onSort={onSort}
            sortDirection={sortDirection}
            data-testid="sort-room"
          />
          <TableHeader
            sortKey={SortColumnEnum.DATE}
            label="Date"
            onSort={onSort}
            sortDirection={sortDirection}
            data-testid="sort-date"
          />
          <TableHeader
            onSort={onSort}
            sortDirection={sortDirection}
            sortKey={SortColumnEnum.IN_TIME}
            label="timecards.clockInHeader"
            data-testid="sort-in-time"
          />
          <TableHeader
            onSort={onSort}
            sortDirection={sortDirection}
            sortKey={SortColumnEnum.OUT_TIME}
            label="timecards.clockOutHeader"
            data-testid="sort-out-time"
          />
          <TableHeader
            onSort={onSort}
            sortDirection={sortDirection}
            sortKey={SortColumnEnum.TOTAL}
            label="timecards.totalHoursLabel"
            data-testid="sort-total-hours"
          />
          <TableHeader />
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-300">
        {dailyHoursSorted.map((daily) => {
          return (
            <tr
              key={daily.timecardId}
              id={daily.timecardId}
              onClick={() => onTimecardSelected(daily.timecardId)}
              className="cursor-pointer hover:bg-gray-50"
              data-testid={`daily-hours-tr-${daily.timecardId}`}
            >
              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800">
                <WsAvatar
                  photoURL={daily?.photoURL}
                  size="small"
                  data-testid={`daily-hours-avatar-${daily.contactId}`}
                />
              </td>
              <td
                className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                data-testid={`daily-hours-name-${daily.contactId}`}
              >
                {daily.displayName}
                {daily.status === CONTACT_STATUS.deleted && (
                  <>
                    <br />
                    <span className="italic text-gray-600">({t('common.deactivated')})</span>
                  </>
                )}
              </td>
              <td
                className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                data-testid={`daily-hours-room-${daily.contactId}`}
              >
                {daily.room}
              </td>
              <td
                className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                data-testid={`daily-hours-clocked-in-date-${daily.contactId}`}
              >
                {daily.clockedInDate}
              </td>
              <td
                className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                data-testid={`daily-hours-clocked-out-time-${daily.contactId}`}
              >
                {daily.clockedInTime}
              </td>
              <td
                className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                data-testid={`daily-hours-clocked-out-time${daily.contactId}`}
              >
                {daily.clockedOutTime === '-' ? <ClockedIn /> : daily.clockedOutTime}{' '}
                {calculateIfClockInOutDatesAreOvernight(new Date(daily.clockedInDate), new Date(daily.clockedOutDate))
                  ? `(${t('timecards.overnight')})`
                  : ''}
              </td>
              <td
                className="whitespace-nowrap px-6 py-4 text-sm text-gray-800"
                data-testid={`daily-hours-total-${daily.contactId}`}
              >
                {daily.totalHours}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                {canUserAddTimecards(user) && (
                  <a
                    href="#"
                    className="text-indigo-600 hover:text-indigo-900"
                    data-testid={`daily-hours-edit-link-${daily.contactId}`}
                  >
                    {t('common.edit')}
                  </a>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

type TableHeaderProps = {
  sortKey?: string;
  label?: string;
  onSort?: (name: string, direction: SortDirectionEnum) => void;
  sortDirection?: SortDirectionType;
};

const HEADER_CLASSES = 'px-6 py-3 text-left text-base font-medium text-gray-800 tracking-wider';

function TableHeader({ sortKey, label, onSort, sortDirection }: TableHeaderProps) {
  const { t } = useTranslation();

  const canBeSorted = !!sortKey && !!onSort && !!sortDirection;

  return (
    <th scope="col" className={HEADER_CLASSES}>
      <div className="flex flex-row items-center">
        {!!label && t(label)}{' '}
        {canBeSorted && <WsSortButton name={sortKey} onSort={onSort} sortDirection={sortDirection} />}
      </div>
    </th>
  );
}
