import React, { useCallback, useEffect, useState } from 'react';

// hooks
import { useTranslation } from 'react-i18next';
import { useStudents } from '../../students/studentsHooks';

// utils
import { FileCategory } from '@wonderschool/common-base-types';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Button,
  DocumentIcon,
  DownloadIcon,
  WidgetSizeEnum,
} from '@wonderschool/common-base-ui';
import { FileServiceError, useDownloadFiles, useUploadFile } from '@wonderschool/file-service-client';
import { showSuccessToast } from '../../Components/Shared/showToast';
import DocumentUploader from '../../Components/Upload/DocumentUploader';
import { getDisplayNameOrFormatFullName } from '../../helpers/utils';
import { useOrganization } from '../../hooks/useOrganizations';
import { logError } from '../../rollbar';
import { UPLOAD_STATE_CONSTS, allowedMimeTypes } from '../dictionary';
import NewDocumentsMessage from './NewDocumentsMessage';
import ParentDocumentsTable from './ParentDocumentsTable';
import ParentStudentProfileMessage from './ParentStudentProfileMessage';
import UploadProgressModal from './modals/UploadProgressModal';

interface StudentsFileIds {
  [studentId: string]: {
    fileIds: string[];
  };
}

const ParentsMain: React.FC = () => {
  const { t } = useTranslation();
  const { list: students } = useStudents();
  const { id: currentOrganizationId } = useOrganization();
  const [activeStudentIndexes, setActiveStudentIndexes] = useState<string[]>([]);
  const [studentsWithFiles, setStudentsWithFiles] = useState<StudentsFileIds>({});
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [studentIdUploadingFor, setStudentIdUploadingFor] = useState<null | string>(null);
  const [uploadState, setUploadState] = useState<UPLOAD_STATE_CONSTS>(UPLOAD_STATE_CONSTS.notUploading);

  const { downloadFiles, isLoading: isDownloading } = useDownloadFiles();
  const { uploadFile, error: uploadError, isSuccess: uploadSuccess, reset: resetUploadHook } = useUploadFile();

  const resetUploadingStates = useCallback(() => {
    // reset the file
    setFileToUpload(null);
    // reset active student id
    setStudentIdUploadingFor(null);
    // reset upload state
    setUploadState(UPLOAD_STATE_CONSTS.notUploading);
    // reset client
    resetUploadHook();
  }, [resetUploadHook]);

  const handleUploadError = useCallback(
    ({ apiError, uploadError }: { apiError?: unknown; uploadError?: FileServiceError }) => {
      setUploadState(UPLOAD_STATE_CONSTS.error);

      logError('Documents - Upload error: ', {
        clientError: apiError || 'upload student doc for parent failed',
        serverMessage: uploadError,
      });
    },
    []
  );

  const handleUploadSuccess = useCallback(() => {
    const targetStudent = students.find((student) => student.id === studentIdUploadingFor);
    const targetStudentName = targetStudent ? getDisplayNameOrFormatFullName(targetStudent, true) : 'the';

    const title = t('Upload success!');
    const message = t("{{fileName}} has been successfully uploaded into {{studentName}}'s student profile.", {
      fileName: fileToUpload?.name,
      studentName: targetStudentName,
    });

    showSuccessToast(title, message);
    // reset client side state
    resetUploadingStates();
    // reset the hook
    resetUploadHook();
  }, [resetUploadingStates, resetUploadHook, fileToUpload, students, studentIdUploadingFor, t]);

  // Handle upload error
  useEffect(() => {
    if (uploadError) {
      handleUploadError({ uploadError });
    }
  }, [uploadError, handleUploadError]);

  // Handle upload success
  useEffect(() => {
    if (uploadSuccess && fileToUpload) {
      handleUploadSuccess();
    }
  }, [uploadSuccess, fileToUpload, handleUploadSuccess]);

  const handleStudentCardExpand = (studentId: string) => {
    const isCurrentlyExpanded = activeStudentIndexes.indexOf(studentId) > -1;
    if (isCurrentlyExpanded) {
      setActiveStudentIndexes([...activeStudentIndexes.filter((id) => id !== studentId)]);
      return null;
    }

    setActiveStudentIndexes([studentId, ...activeStudentIndexes]);
    return null;
  };

  const downloadStudentFiles = (studentId: string) => {
    // prevent multi-click && do nothing when there are no files
    if (isDownloading || !studentsWithFiles[studentId]?.fileIds.length) {
      return null;
    }
    downloadFiles(studentsWithFiles[studentId].fileIds);
  };

  const handleFileSelectedForUpload = ({ data: file }: { data: File }) => {
    if (file) {
      setFileToUpload(file);
    }
  };

  const uploadStudentFile = async () => {
    if (!fileToUpload) {
      return null;
    }

    setUploadState(UPLOAD_STATE_CONSTS.uploading);

    try {
      // start the request
      await uploadFile({
        file: fileToUpload,
        options: {
          fileAction: 'downloadable',
          fileCategory: FileCategory.ENTITY_SPECIFIC_FILE,
          metadata: {
            uploadPath: `/organizations/${currentOrganizationId}`,
            entityReferences: [`/students/${studentIdUploadingFor}`],
          },
        },
      });
    } catch (error) {
      // this is very unlikely to throw as the file-service uses an error hook instead.
      // ...but better safe than sorry
      handleUploadError({ apiError: error });
    }
  };

  const appendAvailableStudentFiles = (studentId: string, fileIds: string[]) => {
    if (studentsWithFiles[studentId]) {
      return null;
    }
    setStudentsWithFiles({
      ...studentsWithFiles,
      [studentId]: { fileIds: fileIds },
    });
  };

  return (
    <>
      {t('Please download, complete, and sign the documents and forms listed below.')}
      {/* new files alert */}
      {/* TODO: remove this condition when file-service-client implements file history features */}
      {false && <NewDocumentsMessage />}

      {/* student profile action */}
      {/* TODO: remove this condition when the proper student profile is selected */}
      {false && <ParentStudentProfileMessage />}

      {/* modals */}
      <UploadProgressModal
        isModalOpen={uploadState !== UPLOAD_STATE_CONSTS.notUploading}
        uploadState={uploadState}
        filename={fileToUpload?.name || ''}
        closeModal={resetUploadingStates}
      />

      {/* students files */}
      {students.map((student: any) => (
        <div key={student.id} className="rounded-lg border border-gray-200">
          <div className="grid md:grid-cols-3">
            <div className="flex md:col-span-2">
              <div className="flex items-center gap-2">
                <div className="p-4">
                  <DocumentIcon className="size-6" />
                </div>
                {t('Documents - {{displayName}}', {
                  displayName: student.displayName,
                })}
              </div>
            </div>

            {/* action column */}
            <div className="flex flex-row items-center gap-2 p-4">
              <Button
                primary={false}
                size={WidgetSizeEnum.X_SMALL}
                preIcon={<DownloadIcon />}
                disabled={!studentsWithFiles[student.id]?.fileIds.length}
                onClick={() => downloadStudentFiles(student.id)}
                label={t('Download All')}
              />

              <DocumentUploader
                onFileSelected={handleFileSelectedForUpload}
                onSubmit={uploadStudentFile}
                onError={() => null}
                buttonOverrideProps={{
                  primary: true,
                  circular: false,
                  size: 'small',
                  color: 'blue',
                  icon: 'upload',
                  onClick: () => setStudentIdUploadingFor(student.id),
                  content: t('Upload Files'),
                }}
                shouldUseExternalStorageApi
                title={t('Upload Files')}
                allowedFileTypes={allowedMimeTypes}
                maxNumberOfFiles={1}
                maxFileSize={100000000}
              />
              <div className="flex items-center space-x-2" onClick={() => handleStudentCardExpand(student.id)}>
                {activeStudentIndexes.indexOf(student.id) > -1 ? (
                  <ArrowUpIcon className="size-6" />
                ) : (
                  <ArrowDownIcon className="size-6" />
                )}
              </div>
            </div>
          </div>

          {activeStudentIndexes.indexOf(student.id) > -1 && (
            <div className="mt-4">
              <hr />
              <ParentDocumentsTable student={student} liftStudentFileIds={appendAvailableStudentFiles} />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default ParentsMain;
