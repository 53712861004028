import * as actionTypes from '../../actions/types';

export type EmployeesReducerState = {
  employeesList: {
    id: string;
  }[];
};

const initialState: EmployeesReducerState = {
  employeesList: [],
};

const employeeReducer = (state = initialState.employeesList, action) => {
  switch (action.type) {
    case actionTypes.LOAD_EMPLOYEES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default employeeReducer;
