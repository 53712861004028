import { logError } from '../../rollbar';
import marketplaceGraphql from './marketplaceGraphql';

const TOKEN_EXCHANGE_MUTATION = `
mutation TokenExchange($ssoToken: String!) {
  exchangeSsoCredentials(ssoToken: $ssoToken) {
    accessToken
    refreshToken
    user {
      id
    }
  }
}
`;

function parseResponse(data: any): { accessToken: string; refreshToken: string } | null {
  return data.exchangeSsoCredentials;
}

function handleError(err: any) {
  logError(`Failed to make Token Exchange request`, err, { err });
}

export function exchangeToken(ssoToken: string): Promise<any | null> {
  return marketplaceGraphql(TOKEN_EXCHANGE_MUTATION, { ssoToken }).then(parseResponse).catch(handleError);
}
