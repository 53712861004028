// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MaskedInput from 'react-text-mask';
// eslint-disable-next-line no-restricted-imports
import { Button, Checkbox, Form, Icon, Modal, Popup, Segment } from 'semantic-ui-react';
import Validator from 'validator';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Import components
import { ageOptions, ethnicityOptions, raceOptions } from '../../config';
import LocationPicker from '../Locations/LocationPicker';
import InlineError from '../Messages/InlineError';
import ShowErrors from '../Messages/ShowError';
import RoomPicker from '../Rooms/RoomPicker';
import { DatePicker } from '../Shared/DatePicker';
import { Dropdown } from '../Shared/Dropdown';
import LanguagePicker from '../Shared/LanguagePicker';
import SalutationPicker from '../Shared/SalutationPicker';
import { showErrorToast, showSuccessToast } from '../Shared/showToast';
import withPermission from '../Shared/withPermission';
import StaffPicker from '../Staff/StaffTypePicker';

import { fetchEmailUsage } from '../../api/firebase/users';
import { useOrganization } from '../../hooks/useOrganizations';
import { logError } from '../../rollbar';
import { StaffLoginPinGenerator } from '../Shared/LoginPinGenerator';

import { formatFullName, phoneNumberFormat, phoneNumberParse } from '../../helpers/utils';

import { formatStringAsUtcMillisOrNull, formatUtcMillisAsString } from '../../helpers/dates';

import { deleteContact, saveContact } from '../../contacts/contactsAPI';
import { useContacts } from '../../contacts/contactsHooks';
import { CONTACT_STATUS, INVITATION_TYPES } from '../../contacts/enums';

import styles from './StaffForm.module.scss';

const SaveEditButton = (props) => <Form.Button {...props} />;

const RestrictedSaveButton = withPermission(SaveEditButton, 'can_create_staff');
const RestrictedEditButton = withPermission(SaveEditButton, 'can_edit_staff');
const EMAIL_IN_USE_MESSAGE = 'This email is already in use by another user. Please use a different email address.';

const initialFormData = {
  type: 'staff',
  status: CONTACT_STATUS.new,
  salutation: '',
  firstName: '',
  middleName: '',
  lastName: '',
  race: '',
  ethnicity: '',
  age: '',
  phone: '',
  email: '',
  location: '',
  rooms: [],
  hireDate: null,
  language: '',
  allowLoginWithPin: true,
  loginPin: '',
  staffType: [],
  sendInvitation: true,
  invitationType: INVITATION_TYPES.staff,
};

const teacherOrSupportStaff = ['teacher', 'supportStaff'];

export default function StaffForm({ staffContact, onClose }) {
  const { t } = useTranslation();
  const organization = useOrganization();
  const { contacts } = useContacts();

  const [formData, setFormData] = useState({ ...initialFormData });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [needToAddAllRooms, setNeedToAddAllRooms] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isAdd = !staffContact || Object.keys(staffContact).length === 0;

  const ensureArray = useCallback((values, defaultValue) => {
    if (!values) {
      return defaultValue ? [defaultValue] : [];
    } else if (Array.isArray(values)) {
      return !!defaultValue && !values.includes(defaultValue) ? [...values, defaultValue] : values;
    } else if (typeof values === 'object') {
      return Object.keys(values).map((key) => values[key]);
    }
  }, []);

  useEffect(() => {
    if (isAdd) return;

    const { phone, currentRoom, hireDate, ...rest } = staffContact;

    const organizations = ensureArray(staffContact.organizations, staffContact.defaultOrganization);
    const locations = ensureArray(staffContact.locations, staffContact.defaultLocation);
    const rooms = ensureArray(staffContact.rooms, staffContact.defaultRoom);

    setFormData({
      ...rest,
      hireDate: formatUtcMillisAsString(hireDate),
      phone: phoneNumberParse(phone),
      organizations,
      locations,
      rooms,
      location: staffContact.defaultLocation,
      currentRoom: currentRoom || (rooms.length ? rooms[0] : ''),
      sendInvitation: rest.status === CONTACT_STATUS.new,
    });
  }, [ensureArray, staffContact, isAdd]);

  const formatFormDataForSave = useCallback(
    (formData) => {
      const {
        email,
        phone,
        defaultOrganization,
        defaultLocation,
        defaultRoom,
        currentRoom,
        location,
        room,
        organizations,
        locations,
        rooms,
        hireDate,
        sendInvitation,
        enabled,
        status,
        ...rest
      } = formData;

      const organizationId = defaultOrganization || organization.id;
      const locationId = location || defaultLocation;
      const roomId = room || currentRoom || defaultRoom;

      let formattedFormData = {
        ...rest,
        email: email?.toLowerCase() ?? '',
        defaultOrganization: organizationId,
        currentOrganization: organizationId,
        defaultLocation: locationId || '',
        organizations: ensureArray(organizations, organizationId),
        locations: ensureArray(locations, locationId),
        rooms: ensureArray(rooms, roomId),
        currentRoom: rooms.length ? rooms[0] : '',
        status,
      };

      // Format some data
      formattedFormData.phone = phoneNumberFormat(phone);
      formattedFormData.hireDate = formatStringAsUtcMillisOrNull(hireDate);
      formattedFormData.displayName = formatFullName(rest, true);

      if (sendInvitation) formattedFormData.status = CONTACT_STATUS.inviting;

      return formattedFormData;
    },
    [ensureArray, organization.id]
  );

  const showStaffSuccessToast = useCallback(
    ({ displayName }) => {
      const message = isAdd
        ? t('Added staff member: {{displayName}}', { displayName })
        : t('Updated staff member: {{displayName}}', { displayName });
      const title = isAdd ? t('Staff Member Added') : t('Staff Member Updated');
      showSuccessToast(title, message);
    },
    [isAdd, t]
  );

  const showStaffErrorToast = useCallback(
    ({ displayName }, error) => {
      const message = isAdd
        ? t('Add staff member failed for {{displayName}}', { displayName })
        : t('Update staff member failed for {{displayName}}', { displayName });
      const title = isAdd ? t('Staff Add Failed') : t('Staff Update Failed');

      showErrorToast(title, message, error);
    },
    [isAdd, t]
  );

  const isEmailRequired = useMemo(() => {
    const isRequired =
      (formData.sendInvitation && formData.status === CONTACT_STATUS.new) ||
      formData.status === CONTACT_STATUS.invited ||
      formData.status === CONTACT_STATUS.verified;
    return isRequired;
  }, [formData]);

  const validate = useCallback(() => {
    const errors = {};

    if (!formData.firstName) errors.firstName = t('common.firstNameRequired');
    if (!formData.lastName) errors.lastName = t('common.lastNameRequired');

    if (!formData.race) errors.race = t('Race is required');
    if (!formData.ethnicity) errors.ethnicity = t('Ethnicity is required');
    if (!formData.age) errors.age = t('Age is required');

    if (!formData.location) errors.location = t('Location is required');
    if (teacherOrSupportStaff.some((value) => formData.staffType.includes(value)) && !formData.rooms?.length)
      errors.rooms = t('Room is required');

    if (isEmpty(formData.staffType)) errors.staffType = t('Staff type is required');

    if (formData.phone && !Validator.isMobilePhone(formData.phone, 'en-US')) {
      errors.phone = t('common.invalidPhone');
    }

    if (formData.email) {
      if (!Validator.isEmail(formData.email)) {
        errors.email = t('Invalid email');
      } else {
        const existingContact = contacts.find((contact) => {
          return contact.id !== formData.id && contact.email === formData.email;
        });
        if (existingContact) errors.email = t(EMAIL_IN_USE_MESSAGE);
      }
    } else if (isEmailRequired) {
      errors.email = t('common.emailRequired');
    }
    return errors;
  }, [formData, contacts, t, isEmailRequired]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const _errors = validate();
      setErrors(_errors);

      if (!isEmpty(_errors)) return;

      setIsLoading(true);
      const formattedFormData = formatFormDataForSave(formData);
      try {
        let emailUsage = null;
        if (formattedFormData.email) {
          emailUsage = await fetchEmailUsage(formattedFormData.email, organization.id);
          // If the email is already in use. We've already checked this org in the validate function.

          if (emailUsage?.hasRootUser && !emailUsage.hasOrganizationUser) {
            setErrors({
              email: t('common.userAlreadyExistsError'),
            });
            return false;
          }
        }
        // if auth exists and no root user
        // and if shouldNotSendNetworkMigrationMail is true, than update status to new and add uid. No email will be sent as it is 1.0 to 2.0 Migration
        // and if shouldNotSendNetworkMigrationMail is false, than update status to verified and add uid. Welcome email will be sent.
        if (emailUsage && emailUsage.hasAuthUser && !emailUsage.hasRootUser && emailUsage.uid) {
          formattedFormData.status = organization?.shouldNotSendNetworkMigrationMail
            ? CONTACT_STATUS.new
            : CONTACT_STATUS.verified;
          formattedFormData.uid = emailUsage.uid;
        }

        await saveContact(organization.id, formattedFormData);
        showStaffSuccessToast(formattedFormData);
        if (onClose) onClose();
      } catch (error) {
        logError('Error while saving Staff', error);
        showStaffErrorToast(formattedFormData, error);
      } finally {
        setIsLoading(false);
      }
    },
    [validate, formatFormDataForSave, formData, organization, showStaffSuccessToast, onClose, t, showStaffErrorToast]
  );

  const removeError = useCallback(
    (name) => {
      const _errors = { ...errors };
      delete _errors[name];
      setErrors(_errors);
    },
    [errors]
  );

  const onChange = useCallback(
    (e, { name, value, checked }) => {
      if (name === 'location') {
        setFormData({ ...formData, location: value, rooms: [] });
      }

      // Set all rooms for locationAdmins on 2 conditions
      // 1. user selected the locationAdmin staff role
      if (name === 'staffType') {
        if (value.includes('locationAdmin')) {
          setNeedToAddAllRooms(true);
        }
      }
      // 2. user selected a location and staffType includes locationAdmin
      if (name == 'location') {
        if (formData.staffType?.includes('locationAdmin')) {
          setNeedToAddAllRooms(true);
        }
      }
      setFormData({ ...formData, [name]: checked ?? value });

      removeError(name);
    },
    [removeError, formData]
  );
  const onChangeRooms = useCallback(
    (value) => {
      setFormData((prev) => ({ ...prev, rooms: value?.length ? value : [] }));
      removeError('rooms');
    },
    [removeError]
  );
  const onChangePhone = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      removeError(e.target.name);
    },
    [removeError, formData]
  );

  const onChangeHireDate = useCallback(
    (e, { name, value, valid }) => {
      onChange(e, { name, value });
      if (!valid) {
        setErrors({ ...errors, [name]: t('Invalid Date') });
      } else {
        removeError(name);
      }
    },
    [errors, removeError, setErrors, onChange, t]
  );
  const toggleStatus = useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      status: prev.status === CONTACT_STATUS.verified ? CONTACT_STATUS.disabled : CONTACT_STATUS.verified,
    }));
  }, [setFormData]);

  const openDeleteStaffHandler = () => {
    setIsDeleteModalOpen(true);
  };

  const deleteStaffHandler = async () => {
    try {
      setIsLoading(true);
      setIsDeleteModalOpen(false);
      await deleteContact(staffContact);
      showStaffSuccessToast(staffContact);
    } catch (error) {
      logError('Error while saving Staff', error);
      showStaffErrorToast(staffContact, error);
    } finally {
      setIsLoading(false);
      if (onClose) onClose();
    }
  };

  return (
    <Segment basic textAlign="left">
      <ShowErrors errors={errors} />

      <Form id="staff-form" onSubmit={onSubmit} loading={isLoading} noValidate data-testid="staff-form">
        <Form.Group widths="equal">
          <StaffPicker
            translator={t}
            isForm
            id="staffType"
            name="staffType"
            label={
              <label htmlFor="staffType">
                {t('Staff Type')}
                <Popup
                  className={styles.staffPickerTooltip}
                  content={t(
                    "Staff types are: Organization Administrator: A super user who has access to every feature on the platform. Location Administrator: A user who has access to every feature in the assigned location(s). Teacher and Supporting Staff: A user who has access to the student list and attendance reporting in the rooms that they are assigned to. They won't have any access to the financial information."
                  )}
                  position="right center"
                  offset={[4, 0]}
                  trigger={<Icon name="info circle" />}
                />
              </label>
            }
            placeholder={t('Staff Type')}
            value={formData.staffType}
            required
            selection
            multiple
            search
            onChange={onChange}
            error={!!errors.staffType}
            data-testid="staff-picker"
          >
            {errors.staffType && <InlineError text={errors.staffType} data-testid="staff-error" />}
          </StaffPicker>

          <Form.Field error={!!errors.salutation}>
            <Form.Field
              translator={t}
              id="salutation"
              name="salutation"
              label={t('Salutation')}
              control={SalutationPicker}
              placeholder={'Salutation'}
              value={formData.salutation}
              selection
              search
              onChange={onChange}
              data-testid="staff-salutation"
            />
            {errors.salutation && <InlineError text={errors.salutation} data-testid="salutation-error" />}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.firstName}>
            <Form.Input
              required
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={onChange}
              label={t('common.firstName')}
              placeholder={t('common.firstName')}
              data-testid="staff-first-name"
            />
            {errors.firstName && <InlineError text={errors.firstName} data-testid="first-name-error" />}
          </Form.Field>

          <Form.Field error={!!errors.middleName}>
            <Form.Input
              type="text"
              id="middleName"
              name="middleName"
              value={formData.middleName}
              onChange={onChange}
              label={t('common.middleName')}
              placeholder={t('common.middleName')}
              data-testid="staff-middle-name"
            />
            {errors.middleName && <InlineError text={errors.middleName} data-testid="middle-name-error" />}
          </Form.Field>
        </Form.Group>
        <Form.Field error={!!errors.lastName}>
          <Form.Input
            required
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={onChange}
            label={t('common.lastName')}
            placeholder={t('common.lastName')}
            data-testid="staff-last-name"
          />
          {errors.lastName && <InlineError text={errors.lastName} data-testid="last-name-error" />}
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.race}>
            <Dropdown
              translator={t}
              isForm
              id="race"
              name="race"
              label={t('Race')}
              placeholder="Race"
              required
              selection
              selectOnBlur={false}
              value={formData.race}
              onChange={onChange}
              options={raceOptions}
              data-testid="staff-race"
            />
            {errors.race && <InlineError text={errors.race} data-testid="race-error" />}
          </Form.Field>

          <Form.Field error={!!errors.ethnicity}>
            <Dropdown
              translator={t}
              isForm
              id="ethnicity"
              name="ethnicity"
              label={t('Ethnicity')}
              placeholder={'Ethnicity'}
              required
              selection
              selectOnBlur={false}
              value={formData.ethnicity}
              onChange={onChange}
              options={ethnicityOptions}
              data-testid="staff-ethnicity"
            />
            {errors.ethnicity && <InlineError text={errors.ethnicity} data-testid="ethnicity-error" />}
          </Form.Field>
        </Form.Group>
        <Form.Field error={!!errors.age}>
          <Dropdown
            translator={t}
            isForm
            id="age"
            name="age"
            label={t('Age')}
            placeholder={'Age'}
            required
            selection
            selectOnBlur={false}
            value={formData.age}
            onChange={onChange}
            options={ageOptions}
            data-testid="staff-age"
          />
          {errors.age && <InlineError text={errors.age} data-testid="age-error" />}
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.email}>
            <Form.Input
              required={isEmailRequired}
              type="text"
              id="email"
              name="email"
              value={formData.email && formData.email.indexOf('moxit_') !== -1 ? '' : formData.email}
              onChange={onChange}
              label={t('common.email')}
              placeholder={t('common.email')}
              data-testid="staff-email"
            />
            {errors.email && <InlineError text={errors.email} data-testid="email-error" />}
          </Form.Field>

          <Form.Field error={!!errors.phone}>
            <Form.Input
              type="text"
              id="phone"
              name="phone"
              onChange={onChangePhone}
              label={t('common.cellPhone')}
              control={MaskedInput}
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              guide={false}
              value={formData.phone}
              placeholder={'(123) 456-7890'}
              data-testid="staff-phone"
            />
            {errors.phone && <InlineError text={errors.phone} data-testid="phone-error" />}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.location}>
            <Form.Field
              required
              id="location"
              name="location"
              label={t('Location')}
              control={LocationPicker}
              placeholder={t('Select location')}
              value={formData.location}
              selection
              search
              onChange={onChange}
              data-testid="staff-location"
            />
            {errors.location && <InlineError text={errors.location} data-testid="location-error" />}
          </Form.Field>

          <Form.Field error={!!errors.rooms}>
            <RoomPicker
              required={teacherOrSupportStaff.some((value) => formData.staffType.includes(value))}
              id="rooms"
              name="rooms"
              label={t('Rooms')}
              placeholder={t('Rooms')}
              value={formData?.rooms ?? []}
              location={formData.location}
              dependent
              selection
              multiple
              search
              needToAddAllRooms={needToAddAllRooms}
              returnAllRooms={(rooms) => {
                setNeedToAddAllRooms(false);
                onChangeRooms(rooms.map((room) => room.value));
              }}
              onChange={onChangeRooms}
              noResultsMessage={t(!formData.location ? 'Select location first' : 'No room found')}
              data-testid="staff-rooms-picker"
            />
            {!!errors.rooms && <InlineError text={errors.rooms} data-testid="rooms-picker-error" />}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.language}>
            <Form.Field
              id="language"
              name="language"
              label={t('Language')}
              control={LanguagePicker}
              placeholder={t('Language')}
              value={formData.language}
              selection
              search
              onChange={onChange}
              data-testid="staff-language"
            />
            {errors.language && <InlineError text={errors.language} data-testid="language-error" />}
          </Form.Field>

          <Form.Field width={16}>
            <DatePicker
              id="hireDate"
              name="hireDate"
              value={formData.hireDate}
              onChange={onChangeHireDate}
              label={t('Hire Date')}
              maxDate={moment()}
              error={!!errors.hireDate}
              closable
              data-testid="staff-hire-date"
            >
              {errors.hireDate && <InlineError text={errors.hireDate} data-testid="hire-date-error" />}
            </DatePicker>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.allowLoginWithPin}>
            <label>{t('Allow user to login using PIN code?')}</label>
            <Form.Radio
              toggle
              id="allowLoginWithPin"
              name="allowLoginWithPin"
              onChange={onChange}
              checked={formData.allowLoginWithPin}
              control={Checkbox}
              data-testid="staff-allow-login-with-pin"
            />
            {errors.allowLoginWithPin && (
              <InlineError text={errors.allowLoginWithPin} data-testid="allow-login-with-pin-error" />
            )}
          </Form.Field>
          {formData.allowLoginWithPin && formData.status === CONTACT_STATUS.verified && formData.uid && (
            <Form.Field error={!!errors.loginPin}>
              <StaffLoginPinGenerator
                uid={formData.uid}
                organizationId={organization.id}
                loginPin={formData.loginPin}
                onGenerating={() => setIsLoading(true)}
                onGenerated={(loginPin) => {
                  setIsLoading(false);
                  setFormData({ ...formData, loginPin });
                }}
                onError={() => {
                  setIsLoading(false);
                  setErrors({
                    ...errors,
                    loginPin: 'Failed to generate PIN Code.',
                  });
                }}
                data-testid="staff-pin-generator"
              />

              {errors.loginPin && <InlineError text={errors.loginPin} data-testid="pin-generator-error" />}
            </Form.Field>
          )}
        </Form.Group>
        {(formData.status == CONTACT_STATUS.verified || formData.status == CONTACT_STATUS.disabled) &&
          formData.uid !== organization.createdBy && (
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t('Disable this user?')}</label>
                <Form.Radio
                  toggle
                  id="disableStaff"
                  name="disableStaff"
                  control={Checkbox}
                  data-testid="disable-staff"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsDisableModalOpen(true);
                  }}
                  checked={formData.status === CONTACT_STATUS.disabled}
                />
              </Form.Field>
            </Form.Group>
          )}
        {formData.status === CONTACT_STATUS.new && (
          <Form.Field>
            <label>{t('Send an invitation to join Wonderschool')}</label>
            <Form.Radio
              toggle
              id="sendInvitation"
              name="sendInvitation"
              label={formData.sendInvitation ? t('common.yes') : t('common.no')}
              onChange={onChange}
              checked={formData.sendInvitation}
              control={Checkbox}
              data-testid="staff-send-invitation"
            />
          </Form.Field>
        )}
        <br />
        <br />
        <Form.Group>
          {formData.id && <RestrictedEditButton primary content={t('common.update')} data-testid="update-btn" />}
          {!formData.id && <RestrictedSaveButton primary content={t('Save')} data-testid="save-btn" />}
          <Form.Button
            basic
            content={t('common.cancel')}
            onClick={(e) => {
              if (e) e.preventDefault();
              if (onClose) onClose();
            }}
            data-testid="cancel-btn"
          />
          {formData.id && formData.uid !== organization.createdBy && (
            <Form.Button
              secondary
              negative
              content={t('Delete')}
              data-testid="delete-btn"
              onClick={(e) => {
                if (e) e.preventDefault();
                openDeleteStaffHandler();
              }}
            />
          )}
        </Form.Group>
      </Form>

      {/* Disable Staff modal */}

      <Modal
        size="small"
        closeIcon
        centered={true}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        open={isDisableModalOpen}
        onClose={() => setIsDisableModalOpen(false)}
        data-testid="disable-staff-member"
      >
        <Modal.Header>
          {formData.status === CONTACT_STATUS.disabled ? t('staff.disable.title') : t('staff.enable.title')}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              {formData.status === CONTACT_STATUS.verified ? t('staff.disable.confirm') : t('staff.enable.confirm')}
            </p>
            <p>
              {formData.status === CONTACT_STATUS.verified ? t('staff.disable.warning') : t('staff.enable.warning')}
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setIsDisableModalOpen(false)} data-testid="enable-staff-member">
            {t('common.no')}
          </Button>
          <Button
            positive
            data-testid="disable-staff-member"
            onClick={() => {
              toggleStatus();
              setIsDisableModalOpen(false);
            }}
          >
            {t('common.yes')}
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Delete Staff Modal */}

      <Modal
        size="small"
        closeIcon
        centered={true}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        data-testid="delete-staff-member"
      >
        <Modal.Header>{t('staff.delete.title')}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{t('staff.delete.confirm')}</p>
            <p>{t('staff.delete.warning')}</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setIsDeleteModalOpen(false)} data-testid="delete-staff-member-no-button">
            {t('common.no')}
          </Button>
          <Button positive onClick={() => deleteStaffHandler()} data-testid="delete-staff-member-yes-button">
            {t('common.yes')}
          </Button>
        </Modal.Actions>
      </Modal>
    </Segment>
  );
}
