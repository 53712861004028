import { BackIcon, Button, Label, LogoutIcon } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';

// Import components
import { routes } from '../config/routes';
import { PAGE_NAMES, useSegmentPage } from '../segment';
import wsLogo from '../styles/img/wonderschool/logo-base.png';

const Error = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.error);

  return (
    <div className="flex min-h-screen flex-col items-center justify-start bg-gray-100">
      <div className="flex flex-col items-center text-center">
        <div className="mb-16 hidden sm:block">
          <img src={wsLogo} alt="Wonderschool logo" className="mx-auto h-10 w-auto" />
        </div>
        <Label extraClasses="text-4xl pb-8 font-bold">{t('Oops, something went wrong!')}</Label>
        <div className="text-center">
          <Button
            primary={true}
            extraClasses="bg-red-900 text-white hover:bg-red-500 mb-4"
            preIcon={<LogoutIcon className="size-7" />}
            label={t('error.trySigningOut')}
            onClick={() => {
              window.location.replace(routes.signout);
            }}
          />{' '}
          <Button
            primary={true}
            extraClasses="bg-blue-900 text-white"
            preIcon={<BackIcon className="size-7" />}
            label={t('error.tryStartingOver')}
            onClick={() => {
              window.location.replace(routes.home);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Error;
