import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { mergeDocumentsAsArray } from '../api/firebase/firestore';
import { fetchCategories, fetchTransactions } from './transactionsAPI';
import { transactionCategoriesFetchedAction, transactionsFetchedAction } from './transactionsRedux';

const ROW_LIMIT = 10;

const selectTransactionCategories = createSelector(
  [(state) => state.transactions],
  (transactions) => transactions?.categories
);
const selectStatsList = createSelector([(state) => state.stats], (stats) => stats?.list ?? []);

export function useTransactions(organizationId, searchConditions, startAfter, extraFetchProps) {
  const dispatch = useDispatch();

  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const limit = ROW_LIMIT;

  useEffect(() => {
    const doFetch = async () => {
      try {
        setLoading(true);
        let _transactions = await fetchTransactions(organizationId, searchConditions, limit, startAfter);

        // if this was a paginated fetch, we need to merge the new transactions with the existing ones
        if (startAfter && transactions?.lastDocument === startAfter) {
          _transactions = mergeDocumentsAsArray(transactions, _transactions);
        }
        setTransactions(_transactions);

        dispatch(transactionsFetchedAction(_transactions));
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (!loading) doFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchConditions, organizationId, startAfter, extraFetchProps]);

  return { loading, error, transactions };
}

export function useCategories(organizationId) {
  const categoriesFromStore = useSelector(selectTransactionCategories);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState(categoriesFromStore?.list);

  useEffect(() => {
    const doFetch = async () => {
      try {
        const _categories = await fetchCategories(organizationId);
        setCategories(_categories);
        dispatch(transactionCategoriesFetchedAction(_categories));
      } catch (error) {
        console.log(error);
      }
    };
    if (!categories) {
      if (categoriesFromStore) setCategories(categoriesFromStore);
      else doFetch();
    }
  }, [categories, categoriesFromStore, organizationId, dispatch]);
  return categories;
}

export function useTotalTransactionsCount() {
  const stats = useSelector(selectStatsList);
  return stats?.find((item) => item.id === 'transactions')?.total;
}
