import { DataTable, SortByEnum, SortDirectionEnum } from '@wonderschool/common-base-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberShorthand } from '../../../../helpers/utils';
import ReportTotalSummary from './AgingTotalSummary';

function AgingList({ totalByEmployee, totalSummary }) {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        fieldName: 'displayName',
        label: t('Student'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => <span className="block w-full sm:w-48">{row.displayName}</span>,
      },
      {
        fieldName: 'first',
        label: t('{{range}} Days', { range: '1-6' }),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => <span>${formatNumberShorthand(row.first)}</span>,
      },
      {
        fieldName: 'second',
        label: t('{{range}} Days', { range: '7-13' }),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => <span>${formatNumberShorthand(row.second)}</span>,
      },
      {
        fieldName: 'third',
        label: t('{{range}} Days', { range: '14-20' }),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => <span>${formatNumberShorthand(row.third)}</span>,
      },
      {
        fieldName: 'fourth',
        label: t('{{range}} Days', { range: '21-28' }),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => <span>${formatNumberShorthand(row.fourth)}</span>,
      },
      {
        fieldName: 'fifth',
        label: t('{{range}} Days', { range: '29+' }),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => <span>${formatNumberShorthand(row.fifth)}</span>,
      },
      {
        fieldName: 'total',
        label: t('Total'),
        sortBy: SortByEnum.CLIENT,
        renderCell: (row) => <span>${formatNumberShorthand(row.total)}</span>,
      },
    ],
    [t]
  );

  return (
    <div className="flex flex-col space-y-8">
      <div className="w-full">
        <ReportTotalSummary totalSummary={totalSummary} />
      </div>

      <div className="w-full">
        <DataTable
          numItemsPerPage={500}
          data={totalByEmployee}
          headerClasses="px-2"
          rowClasses="[&>td:first-child]:w-4 h-16"
          columns={columns}
          defaultSortConfig={{ name: 'displayName', direction: SortDirectionEnum.ASC }}
        />
      </div>
    </div>
  );
}

export default AgingList;
