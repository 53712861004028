import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Icon, Image, Menu, Modal } from 'semantic-ui-react';

// Import components.
import ChangeLanguage from '../../Components/Shared/ChangeLanguage';
import WSA from '../../WSA';
import { FeatureFlagsLink } from '../../feature-flags';
import { useNetworkLogo } from '../../hooks/useNetworksLogo';
import LeftNavigation from './LeftNavigation';
import UserProfileMenu from './UserProfileMenu';

const MobileNavigation = ({ user: _user, permissions: _permissions, handleToggle: _handleToggle }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isReady, isAuthenticated } = WSA.Components.useWSAuth();
  const logoUrl = useNetworkLogo();

  if (!(isReady && isAuthenticated)) return null;

  const renderMobileHamburger = () => {
    return (
      <Modal
        closeIcon
        centered={false}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        trigger={
          <Menu.Item className="hamburger">
            <Icon name="sidebar" />
          </Menu.Item>
        }
      >
        <Modal.Header>
          <Image
            src={logoUrl}
            style={{
              width: '80%',
              paddingTop: '0.5em',
              paddingBottom: '0.5em',
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Menu id="main-menu" stackable>
              <LeftNavigation onClickMenuItem={() => setMenuOpen(false)} />

              <Menu.Item>
                <FeatureFlagsLink mobile />
              </Menu.Item>

              <Menu.Item>
                <ChangeLanguage />
              </Menu.Item>

              <Menu.Item>
                <UserProfileMenu viewport="mobile" onClick={() => setMenuOpen(false)} />
              </Menu.Item>
            </Menu>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <Menu secondary size="large">
      {renderMobileHamburger()}
      <Menu.Item as="a">
        <Image size="medium" src={logoUrl} />
      </Menu.Item>
    </Menu>
  );
};

export default MobileNavigation;
