import { Button, ButtonLink, Dialog, PageLoading } from '@wonderschool/common-base-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { matchStudentsWithKinderSystems } from '../../../api/firebase/kinderSystems';
import KinderConnectCredentials from './credentials';

interface CredentialsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
}

export const CredentialsDialog: React.FC<CredentialsDialogProps> = ({ isOpen, onClose, organizationId }) => {
  const { t } = useTranslation();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isMatchingStudents, setIsMatchingStudents] = useState(false);

  const handleLoginHelpModal = () => {
    setIsHelpModalOpen(true);
  };

  const onSave = useCallback(
    async (edited) => {
      if (edited) {
        setIsMatchingStudents(true);
        const matchedStudents = await matchStudentsWithKinderSystems({
          organizationId,
        });
        if (matchedStudents) {
          setIsMatchingStudents(false);
          onClose();
        }
      }
    },
    [organizationId, onClose]
  );

  return (
    <Dialog isOpen={isOpen} onClose={onClose} extraClasses="text-gray-800">
      <Dialog.Panel>
        <Dialog.Title>{t('KinderConnect')}</Dialog.Title>
        {!isHelpModalOpen && !isMatchingStudents && (
          <>
            <Dialog.Description>{t('apps.kinderConnect.manageLoginDescription')}</Dialog.Description>
            <div className="pt-4">
              <KinderConnectCredentials onSave={onSave} />
            </div>
            <ButtonLink
              onClick={handleLoginHelpModal}
              content={t('apps.kinderConnect.needHelp')}
              extraClasses="w-full text-center mt-4"
            />
          </>
        )}
        {/* Help Modal */}
        {isHelpModalOpen && !isMatchingStudents && (
          <>
            <Dialog.Description>{t('apps.kinderConnect.description2')}</Dialog.Description>
            <Dialog.Description>{t('apps.kinderConnect.description3')}</Dialog.Description>
            <div className="mt-">
              <Button
                primary
                label={t('common.backToLogin')}
                onClick={() => setIsHelpModalOpen(false)}
                extraClasses="w-full mt-8"
              />
            </div>
          </>
        )}

        {/* Matching Students Modal */}
        {isMatchingStudents && !isHelpModalOpen && (
          <>
            <Dialog.Description>
              <PageLoading />
            </Dialog.Description>
            <Dialog.Description>{t('apps.kinderConnect.matchingStudentsDescription')}</Dialog.Description>
            <div className="mt-">
              <Button label={t('common.cancel')} onClick={onClose} extraClasses="w-full mt-8" />
            </div>
          </>
        )}
      </Dialog.Panel>
    </Dialog>
  );
};
