// TODO: Lodash should no longer be used here
import { useFlags } from 'launchdarkly-react-client-sdk';
// eslint-disable-next-line no-restricted-imports
import { kebabCase } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import { userHasPermission } from '../api/firebase/account';
import { useOrganization, useOrganizationFeatureFlag } from '../hooks/useOrganizations';
import { usePermissions } from '../hooks/usePermissions';

import { paths } from './paths';
import { getRoutes } from './routes';
import {
  FeatureFlagEnum,
  InvoicePathsType,
  NewFeatureFlagEnum,
  RecurringPlansPathsType,
  RouteNameEnum,
  RouteParamType,
  RouteType,
  UsePathsReturnType,
  UseRoutesReturnType,
} from './types';

import { useMarketplaceFeaturesEnabled } from '../integrations';
import {
  buildHelpCenterLink,
  buildMarketplaceLink,
  buildOnboardingLink,
  buildParameterizedPath,
} from './navigationUtils';

export { usePaths, useRouteDebounce, useRoutes };

// This is a hook that returns all the routes both active and inactive
// It also returns helper functions to get active routes and paths and such
function useRoutes(): UseRoutesReturnType {
  const history = useHistory();
  const organization = useOrganization();
  const permissions = usePermissions();

  useMarketplaceFeaturesEnabled();

  const { isFeatureEnabled } = useOrganizationFeatureFlag();
  const allRoutes = getRoutes();
  const hasMarketplaceId = !!organization.marketplaceSchoolId && organization.marketplaceSchoolId !== '';

  const { isFinancialSummaryEnabled, adminDocuments } = useFlags();

  const getNewFeatureFlagEnabled = useCallback(
    (flagName: string) => {
      switch (flagName) {
        case NewFeatureFlagEnum.ISFINANCIALSUMMARYENABLED:
          return isFinancialSummaryEnabled;
        case NewFeatureFlagEnum.IS_ADMIN_DOCS_ENABLED:
          return adminDocuments;
        default:
          return false;
      }
    },
    [isFinancialSummaryEnabled, adminDocuments]
  );

  const isRouteActive = useCallback(
    (route: RouteType) => {
      const hasPermission = route.permission === '*' || !route.permission || userHasPermission(route.permission);
      const isFeatureFlagEnabled = !route.featureFlag || isFeatureEnabled(route.featureFlag);
      const shouldHide = route.shouldHide ? route.shouldHide() : false;
      const maybeHideIfMarketplacePath = route.headlessPath ? hasMarketplaceId : true;
      const isNewFeatureFlagEnabled =
        route?.newFeatureFlag !== undefined ? getNewFeatureFlagEnabled(route?.newFeatureFlag) : true;

      return (
        !shouldHide && hasPermission && isFeatureFlagEnabled && maybeHideIfMarketplacePath && isNewFeatureFlagEnabled
      );
    },
    //Need to re-render when permissions change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFeatureEnabled, permissions, hasMarketplaceId]
  );

  // This is a memoized version of the all active routes
  const allActiveRoutes = useMemo<RouteType[]>(() => {
    const _routes = allRoutes.filter((route) => {
      return isRouteActive(route);
    });
    return _routes;
  }, [allRoutes, isRouteActive]);

  // Create a map of all active routes for quick lookup
  const allRoutesMap = useMemo<Record<string, RouteType>>(() => {
    return allRoutes.reduce((acc: Record<string, RouteType>, route: RouteType) => {
      acc[route.name] = route;
      return acc;
    }, {});
  }, [allRoutes]);

  // Search the active routes for one or more routes. Returns RouteType[]
  const getActiveRoutes = useCallback(
    (routesToFind: RouteNameEnum | RouteNameEnum[]) => {
      const _routesToFind: RouteNameEnum[] = Array.isArray(routesToFind) ? routesToFind : [routesToFind];

      const routes: Array<RouteType> = [];

      _routesToFind.forEach((routeToFind: RouteNameEnum) => {
        const route = allRoutesMap[routeToFind];
        if (route && isRouteActive(route)) {
          routes.push(route);
        }
      });
      return routes;
    },
    [allRoutesMap, isRouteActive]
  );

  // Search the active routes for one route. Returns RouteType
  const getActiveRoute = useCallback(
    (routeToFind: RouteNameEnum) => {
      const route = allRoutesMap[routeToFind];
      return route && isRouteActive(route) ? route : undefined;
    },
    [allRoutesMap, isRouteActive]
  );

  // Search the active routes for one route by path. Returns RouteType | undefined
  const getActiveRouteByPath = useCallback(
    (path: string) => {
      const route = allActiveRoutes.find((route) => route.path === path);
      return route && isRouteActive(route) ? route : undefined;
    },
    [allActiveRoutes, isRouteActive]
  );

  const getRoute = useCallback((routeName: RouteNameEnum) => allRoutesMap[routeName], [allRoutesMap]);

  // Builds the path for a route. For external links like Marketplace,
  // or parameterized paths, we need to build the url. Otherwise, we just return the path.
  const getRoutePath = useCallback(
    (route: RouteType, params?: RouteParamType[]) => {
      if (route.headlessPath && hasMarketplaceId) {
        return kebabCase(route.name);
      } else if (route.featureFlag === FeatureFlagEnum.MARKETPLACE) {
        return route.name === RouteNameEnum.ONBOARDING
          ? buildOnboardingLink(route)
          : buildMarketplaceLink({ organization, path: route?.path });
      } else if (route.featureFlag === FeatureFlagEnum.HELP_CENTER) {
        return buildHelpCenterLink(route.path);
      } else if (params?.length) {
        // If the page has handlebar or rails style parameters, replace them
        const parameterizedPath = buildParameterizedPath(route, params);
        // If the path was successfully parameterized, return it
        if (parameterizedPath !== route.path) return parameterizedPath;

        // Otherwise, create a query string from the params and append it to the path
        const searchParams = new URLSearchParams(params.map((param) => [param.name, param.value]));
        return `${route.path}?${searchParams.toString()}`;
      } else {
        return route.path;
      }
    },
    [organization, hasMarketplaceId]
  );

  const getRoutePathByName = useCallback(
    (routeName: RouteNameEnum, params?: RouteParamType[]) => {
      const route = getRoute(routeName);
      return route ? getRoutePath(route, params) : undefined;
    },
    [getRoute, getRoutePath]
  );

  // navigate to a route, or to an external url
  const gotoRoute = useCallback(
    (route: RouteType, params?: RouteParamType[]) => {
      if (!isRouteActive(route)) return;

      const isHeadlessRoute = !!route?.headlessPath;
      const path = getRoutePath(route, params);

      if (isHeadlessRoute && hasMarketplaceId) {
        const path = kebabCase(route.name);
        history.push(path);
      } else if (path?.startsWith('http')) {
        if (route.shouldOpenInNewTab) window.open(path, '_blank');
        else window.location.assign(path);
      } else if (path) {
        history.push(path);
      }
    },
    [getRoutePath, history, isRouteActive, hasMarketplaceId]
  );

  const gotoRouteByName = useCallback(
    (routeName: RouteNameEnum, params?: RouteParamType[]) => {
      const route = getRoute(routeName);
      if (route) {
        gotoRoute(route, params);
      }
    },
    [gotoRoute, getRoute]
  );
  const goBack = useCallback(() => history.goBack(), [history]);

  return {
    allRoutes,
    allActiveRoutes,
    getActiveRoute,
    getActiveRouteByPath,
    getActiveRoutes,
    getRoute,
    getRoutePath,
    getRoutePathByName,
    goBack,
    gotoRoute,
    gotoRouteByName,
    isRouteActive,
  };
}

function usePaths(): UsePathsReturnType {
  // If the left navigation is enabled, pull from paths.ts, otherwise pull from config/routes.ts
  const invoicePaths = useMemo<InvoicePathsType>(() => {
    return {
      invoices: paths.invoices,
      invoiceEditConfirm: paths.invoiceEditConfirm,
      invoiceEditDetails: paths.invoiceEditDetails,
      invoiceEditStudents: paths.invoiceEditStudents,
    };
  }, []);

  // If the left navigation is enabled, pull from paths.ts, otherwise pull from config/routes.js
  const recurringPlansPaths = useMemo<RecurringPlansPathsType>(() => {
    return {
      recurringPlans: paths.recurringPlans,
      recurringPlanEditDetails: paths.recurringPlanEditDetails,
    };
  }, []);

  return { paths, invoicePaths, recurringPlansPaths };
}

const TIMEOUT = 500;

// Route debouncing
function useRouteDebounce(stringKey: string, needsDebounce: boolean, timeout = TIMEOUT): boolean {
  const [isDebounced, setDebounced, isDebouncedRef] = useStateRef(false);

  /**
   * Because a lot of changes happen all at once when loading a route and the ordering
   * is not so easy to control as the app changes, we rely on a debounce effect to stabilize
   * the validation checks.
   */
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;

    if (needsDebounce && isDebouncedRef.current !== true) {
      timer = setTimeout(() => {
        if (isDebouncedRef.current !== true) {
          setDebounced(true);
        }
      }, timeout);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [isDebouncedRef, needsDebounce, setDebounced, stringKey, timeout]);

  return needsDebounce ? isDebounced : true;
}
