import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Container, Form, Loader, Modal } from 'semantic-ui-react';
import { logError } from '../../../rollbar';

import { userHasPermission } from '../../../api/firebase/account';
import { CSV, DOC, DOCX, JPEG, PDF, PNG, TXT, XLS, XLSX } from '../../../helpers/mimeTypes';
import { formatFullName } from '../../../helpers/utils';
import { useOrganization } from '../../../hooks/useOrganizations';
import { useUser } from '../../../hooks/useUser';

import { fetchBillingDetails, saveStudent } from '../../studentsAPI';
import { useSelectedStudent } from '../../studentsHooks';
import { organizationUpdateStudent, studentUpdated } from '../../studentsRedux';
import {
  archiveStudent,
  computeEnforceAutomaticPayment,
  isStudentArchived,
  isStudentUnenrolled,
  unarchiveStudent,
} from '../../studentsUtils';

import { Button, Dialog, EditIcon } from '@wonderschool/common-base-ui';
import DocumentUploader from '../../../Components/Upload/DocumentUploader';
import { WsAddCircle, WsContacts } from '../../../icons';
import StudentArchiveConfirmationModal from '../StudentArchiveConfirmationModal';
import StudentDocumentList from '../StudentDocumentList';
import StudentInvoicesTable from '../StudentInvoicesTable';
import StudentProgramsForm from '../StudentProgramsForm';
import StudentEnrollment from '../enrollment/StudentEnrollment';
import StudentEnrollmentForm from '../enrollment/StudentEnrollmentForm';
import { FamilyEditContactModal, FamilyList } from '../family';
import StudentPersonalInformationProviderApi from './StudentPersonalInformationProviderApi';
import { StudentProgramsProviderApi } from './StudentProgramsProviderApi';

type BillingDetails = {
  hasPaymentMethod: boolean;
  canChargeAutomatically: boolean;
};

type StudentDetailHeaderProps = {
  children: React.ReactNode | React.ReactNode[];
  'data-testid'?: string;
};

function StudentDetailHeader({ children, 'data-testid': dataTestId }: StudentDetailHeaderProps) {
  return (
    <h2 className="mb-6 flex items-center justify-between text-xl font-bold" data-testid={dataTestId}>
      {children}
    </h2>
  );
}

export default function StudentDetailProviderApi({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useOrganization();
  const selectedStudent = useSelectedStudent();
  const { studentArchive: studentArchiveFlag } = useFlags();

  const [isStudentEnrollmentFormOpen, setIsStudentEnrollmentFormOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(null);
  const [isStudentProgramsFormOpen, setIsStudentProgramsFormOpen] = useState(false);

  const user = useUser();
  const canRoleListBilling = userHasPermission('can_list_billing');
  const canRoleEditStudent = userHasPermission('can_edit_student');
  const canRoleEditStudentContacts = userHasPermission('can_edit_student_contacts');

  const allowedMimeTypes = {
    PDF,
    PNG,
    JPEG,
    DOC,
    DOCX,
    XLS,
    XLSX,
    CSV,
    TXT,
  };

  const allowedFileTypes = Object.values(allowedMimeTypes);
  const allowedFileTypeNames = Object.keys(allowedMimeTypes);

  const allowedFileTypeNamesStart = allowedFileTypeNames.slice(0, -1).join(', ');
  const allowedFileTypeNamesEnd = allowedFileTypeNames.slice(-1);

  const maxFileSize = '30MB';

  const processUploadedDocuments = (uploads) => {
    if (!uploads?.length) return;

    const { uid, email } = user;

    const documentUploadPath = `organizations/${organization.id}/students/${selectedStudent.id}/documents`;

    const documents = {
      ...selectedStudent.documents,
    };

    uploads.forEach((upload) => {
      const name = upload.name ? upload.name.split('.')[0] : 'Unknown';

      documents[upload.meta.refId] = {
        name,
        documentId: upload.meta.refId,
        path: documentUploadPath,
        type: upload.type,
        size: upload.size,
        extension: upload.extension,
        progress: upload.progress,
        meta: upload.meta,
        downloadUrl: upload.downloadUrl,
        uploadedBy: { uid, email, name: formatFullName(user, true) },
        uploadedDate: Date.now(),
        tags: [],
      };
    });

    dispatch(
      organizationUpdateStudent(organization.id, {
        ...selectedStudent,
        documents,
      })
    );
  };
  const onSaveStudent = async (newStudent) => {
    try {
      await saveStudent(organization.id, newStudent);
      dispatch(studentUpdated());
      setIsArchiveModalOpen(false);
      onClose?.();
    } catch (error: any) {
      logError('Error trying to save student.', error);
      throw new Error(error);
    }
  };
  const onArchiveStudent = async () => {
    try {
      const newStudent = archiveStudent(selectedStudent);
      await onSaveStudent(newStudent);
    } catch (error: any) {
      logError('Error trying to archive student.', error);
      throw new Error(error);
    }
  };
  const onRestoreStudent = async () => {
    try {
      const newStudent = unarchiveStudent(selectedStudent);
      await onSaveStudent(newStudent);
    } catch (e: any) {
      logError('Error trying to unarchive student.', e);
      throw new Error(e);
    }
  };
  const onEnforceAutomaticPaymentChange = (e, { checked }: { checked?: boolean }) => {
    if (!selectedStudent?.id || !organization?.id || user.isParent) return;

    if (!selectedStudent.automaticPayment) {
      selectedStudent.automaticPayment = {};
    }
    selectedStudent.automaticPayment.enforce = checked;
    selectedStudent.automaticPayment.modifiedDate = Date.now();

    dispatch(organizationUpdateStudent(organization.id, selectedStudent));
  };

  useEffect(() => {
    const fetchData = async () => {
      const billingDetails = {
        hasPaymentMethod: false,
        canChargeAutomatically: false,
      };

      try {
        Object.assign(billingDetails, await fetchBillingDetails(organization.id, selectedStudent.id));
      } catch (e) {
        console.error('Error trying to get billing details for responsible parent.', e);
      }

      setBillingDetails(billingDetails);
    };

    if (!user.isParent && canRoleListBilling && selectedStudent.id) {
      fetchData();
    }
  }, [selectedStudent.id, organization.id, user.isParent, canRoleListBilling]);

  const renderStudentEnrollmentForm = useCallback(() => {
    return <StudentEnrollmentForm student={selectedStudent} onClose={() => setIsStudentEnrollmentFormOpen(false)} />;
  }, [selectedStudent]);

  const renderStudentProgramsForm = useCallback(() => {
    return <StudentProgramsForm student={selectedStudent} onClose={() => setIsStudentProgramsFormOpen(false)} />;
  }, [selectedStudent]);

  return (
    <Container fluid className="no-padding no-margin student-detail">
      <FamilyEditContactModal
        student={selectedStudent}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        contact={undefined}
        onReplaceExistingContact={undefined}
      />
      <StudentArchiveConfirmationModal
        isOpen={isArchiveModalOpen}
        onYes={onArchiveStudent}
        onNo={() => setIsArchiveModalOpen(false)}
      />
      <div className="flex flex-col gap-5">
        <StudentPersonalInformationProviderApi />
        <StudentEnrollmentSegment />
        <StudentProgramsSegment />
        <FamilyAndContactsSegment />
        {/* temporarily hiding this view from parents until invoice data issue is resolved */}
        {!user.isParent && canRoleListBilling && <AllInvoicesSegment />}
        <StudentDocumentListSegment />
        {studentArchiveFlag && <StudentArchiveActionsSegment />}
      </div>
    </Container>
  );

  function StudentEnrollmentSegment() {
    return (
      <div className="p-4">
        <StudentDetailHeader data-testid="enrollment-title">
          {t('Enrollment')}
          {canRoleEditStudent && (
            <Button
              extraClasses="ring-0 ring-offset-0 shadow-none"
              onClick={(e) => {
                e.preventDefault();
                setIsStudentEnrollmentFormOpen(true);
              }}
              data-testid="enrollment-edit-btn"
            >
              <EditIcon className="mr-2 size-4" />
              {t('common.edit')}
            </Button>
          )}
        </StudentDetailHeader>

        <StudentEnrollment student={selectedStudent} />

        <Modal
          closeIcon
          centered={false}
          size="tiny"
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={isStudentEnrollmentFormOpen}
          onClose={() => setIsStudentEnrollmentFormOpen(false)}
          header={t('Enrollment')}
          content={renderStudentEnrollmentForm()}
          data-testid="student-enrollment-form-modal"
        />
      </div>
    );
  }

  function StudentProgramsSegment() {
    return (
      <div className="p-4">
        <StudentDetailHeader data-testid="programs-title">
          {t('Programs')}
          {canRoleEditStudent && (
            <Button
              extraClasses="ring-0 ring-offset-0 shadow-none"
              onClick={(e) => {
                e.preventDefault();
                setIsStudentProgramsFormOpen(true);
              }}
              data-testid="programs-edit-btn"
            >
              <EditIcon className="mr-2 size-4" />
              {t('common.edit')}
            </Button>
          )}
        </StudentDetailHeader>

        <StudentProgramsProviderApi student={selectedStudent} />

        <Dialog
          isOpen={isStudentProgramsFormOpen}
          onClose={() => setIsStudentProgramsFormOpen(false)}
          data-testid="student-programs-form-modal"
        >
          <Dialog.Panel>
            <Dialog.Title>{t('Programs')}</Dialog.Title>
            <Dialog.Description>{renderStudentProgramsForm()}</Dialog.Description>
          </Dialog.Panel>
        </Dialog>
      </div>
    );
  }

  function FamilyAndContactsSegment() {
    return (
      <div className="p-4">
        <StudentDetailHeader data-testid="family-contact-title">
          <span className="text-xl">
            <WsContacts className="text-gray-800" /> {t('familyAndContactsLabel')}
          </span>
          {canRoleEditStudentContacts && (
            <a
              className="round add-contact text-base text-blue-900"
              onClick={() => setIsAddModalOpen(true)}
              data-testid="add-contact-btn"
            >
              <WsAddCircle /> {t('common.addContact')}
            </a>
          )}
        </StudentDetailHeader>

        <FamilyList />
      </div>
    );
  }

  function AllInvoicesSegment() {
    return (
      <div className="p-4">
        <StudentDetailHeader data-testid="invoices-title">
          {t('All Invoices')}
          <Form.Radio
            toggle
            disabled={user.isParent}
            id="enforceAutomaticPayment"
            name="enforceAutomaticPayment"
            label={
              <label>
                {t('studentDetailsEnforce')} <strong>{t('studentDetailsAutomaticPayment')}</strong>
              </label>
            }
            className="vertical-align-sub text-right"
            onChange={onEnforceAutomaticPaymentChange}
            checked={computeEnforceAutomaticPayment(selectedStudent, organization)}
            control={Checkbox}
            data-testid="auto-payment"
          />
        </StudentDetailHeader>

        <div className="flex justify-between">
          <div className="inline-block">
            <strong>{t('studentDetailsResponsibleParentOnAutomaticPayment')}: </strong>
            {billingDetails ? (
              t(
                billingDetails.canChargeAutomatically
                  ? 'studentDetailsResponsibleParentOnAutomaticPaymentYes'
                  : 'studentDetailsResponsibleParentOnAutomaticPaymentNo'
              )
            ) : (
              <Loader size="mini" active inline />
            )}
          </div>
          <div className="inline-block">
            <i className="credit card icon outline"></i>
            {billingDetails ? (
              t(
                billingDetails.hasPaymentMethod
                  ? 'studentDetailsPaymentMethodAdded'
                  : 'studentDetailsNoPaymentMethodAdded'
              )
            ) : (
              <Loader size="mini" active inline></Loader>
            )}
          </div>
        </div>
        <StudentInvoicesTable />
      </div>
    );
  }

  function StudentDocumentListSegment() {
    return (
      <div className="p-4">
        <StudentDetailHeader data-testid="files-title">
          {t('Files')}
          {canRoleEditStudent && (
            <DocumentUploader
              uploadPath={`organizations/${organization.id}/students/${selectedStudent.id}/documents`}
              title={t('Uploading Student Documents')}
              allowedFileTypes={allowedFileTypes}
              onUploaded={processUploadedDocuments}
              note={t(
                'Only {{allowedFileTypeNamesStart}}, or {{allowedFileTypeNamesEnd}} files up to {{maxFileSize}} are allowed.',
                {
                  allowedFileTypeNamesStart,
                  allowedFileTypeNamesEnd,
                  maxFileSize,
                }
              )}
              data-testid="upload-doc"
            />
          )}
        </StudentDetailHeader>
        <StudentDocumentList />
      </div>
    );
  }

  function StudentArchiveActionsSegment() {
    return (
      <div className="p-4">
        {canRoleEditStudent && !isStudentArchived(selectedStudent) && isStudentUnenrolled(selectedStudent) && (
          <div className="flex items-center justify-between">
            <span>{t('students.archiveStudentModal.hideRemoveStudentFromView')}</span>
            <Button
              extraClasses="ring-0 ring-offset-0 shadow-none bg-red-800 hover:bg-red-700 text-neutral-50"
              onClick={(e) => {
                e.preventDefault();
                setIsArchiveModalOpen(true);
              }}
              data-testid="student-archive-btn"
            >
              {t('students.archiveStudentModal.archiveStudent')}
            </Button>
          </div>
        )}
        {canRoleEditStudent && isStudentArchived(selectedStudent) && (
          <div className="flex items-center justify-between">
            <span>{t('students.archiveStudentModal.unarchiveStudent')}</span>
            <Button
              primary
              extraClasses="ring-0 ring-offset-0 shadow-none"
              onClick={(e) => {
                e.preventDefault();
                onRestoreStudent();
              }}
              data-testid="student-unarchive-btn"
            >
              {t('students.archiveStudentModal.restoreStudent')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
