import { CHARGES_FETCHED } from '../../actions/types';

export type ChargesReducerState = {
  list: {
    id: string;
  }[];
};

const initialState: ChargesReducerState = {
  list: [],
};

export default function chargesReducer(state = initialState, action) {
  switch (action.type) {
    case CHARGES_FETCHED:
      return {
        ...state,
        count: action.payload.count,
        list: action.payload.list,
        last: action.payload.last,
      };

    default:
      return state;
  }
}
