import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Dropdown, Input } from 'semantic-ui-react';

import { Label } from '@wonderschool/common-base-ui';
import RoomPicker from '../../Components/Rooms/RoomPicker';
import { EnrollmentStatusEnum } from '../../enrollments/enums';
import { capitalize } from '../../helpers/utils';
import { useOrganization } from '../../hooks/useOrganizations';
import { RouteNameEnum, useRoutes } from '../../navigation';
import { STUDENT_PROGRAMS } from '../consts';
import { useStudents } from '../studentsHooks';
import { isStudentArchived, isStudentEnrolled, isStudentPending, isStudentUnenrolled } from '../studentsUtils';
import styles from './students.module.scss';

const StudentsFilters = ({ state, setState }) => {
  const { t } = useTranslation();
  const { studentArchive: studentArchiveFlag, foodProgramFlag } = useFlags();

  const { list: students, count } = useStudents(state);
  const { gotoRouteByName } = useRoutes();
  const { isKinderSystemsEnabled } = useOrganization();

  const selectProgramsOptions = useMemo(() => {
    const options = [];
    if (isKinderSystemsEnabled) {
      options.push({
        key: STUDENT_PROGRAMS.KINDER_CONNECT,
        value: STUDENT_PROGRAMS.KINDER_CONNECT,
        text: t('KinderConnect'),
      });
    }

    if (foodProgramFlag) {
      options.push({
        key: STUDENT_PROGRAMS.CACFP,
        value: STUDENT_PROGRAMS.CACFP,
        text: t('CACFP'),
      });
    }

    return options;
  }, [foodProgramFlag, isKinderSystemsEnabled, t]);

  useEffect(() => {
    const counts = {
      all: 0,
      enrolled: 0,
      unenrolled: 0,
      pending: 0,
    };
    students.forEach((student) => {
      if (!isStudentArchived(student) || (isStudentArchived(student) && !state.filters?.hideArchivedStudents)) {
        counts.all++;
        if (isStudentEnrolled(student)) counts.enrolled++;
        else if (isStudentUnenrolled(student)) counts.unenrolled++;
        else if (isStudentPending(student)) counts.pending++;
      }
    });
    setState((prev) => ({
      ...prev,
      count: counts,
    }));
  }, [students, count, setState, state.filters?.hideArchivedStudents]);

  return (
    <div className="flex flex-col gap-2">
      <div className={styles.studentStatusFilterBar}>
        <a
          className={`${state.activeTab === 'all' ? styles.active : ''} ${styles.statusFilter}`}
          onClick={() => {
            setState((prev) => ({
              ...prev,
              filters: { ...prev.filters, enrollmentStatus: '' },
              activeTab: 'all',
            }));
            gotoRouteByName(RouteNameEnum.STUDENTS_FILTER, [{ name: 'filter', value: 'all' }]);
          }}
          data-testid="all-filter"
        >
          <Label
            extraClasses={`${state.activeTab === 'all' ? 'text-white' : 'text-blue-950'} font-bold`}
          >{`${t('common.all')} (${state.count.all})`}</Label>
        </a>
        <a
          className={`${state.activeTab === EnrollmentStatusEnum.ENROLLED ? styles.active : ''} ${styles.statusFilter}`}
          onClick={() => {
            setState((prev) => ({
              ...prev,
              filters: {
                ...prev.filters,
                enrollmentStatus: EnrollmentStatusEnum.ENROLLED,
              },
              activeTab: EnrollmentStatusEnum.ENROLLED,
            }));
            gotoRouteByName(RouteNameEnum.STUDENTS_FILTER, [{ name: 'filter', value: 'enrolled' }]);
          }}
          data-testid="enrolled-filter"
        >
          <Label
            extraClasses={`${state.activeTab === EnrollmentStatusEnum.ENROLLED ? 'text-white' : 'text-blue-950'} font-bold`}
          >
            {t(capitalize(EnrollmentStatusEnum.ENROLLED, ''))} {`(${state?.count[EnrollmentStatusEnum.ENROLLED]})`}
          </Label>
        </a>
        <a
          className={`${state.activeTab === EnrollmentStatusEnum.UNENROLLED ? styles.active : ''} ${
            styles.statusFilter
          }`}
          onClick={() => {
            setState((prev) => ({
              ...prev,
              filters: {
                ...prev.filters,
                enrollmentStatus: EnrollmentStatusEnum.UNENROLLED,
              },
              activeTab: EnrollmentStatusEnum.UNENROLLED,
            }));
            gotoRouteByName(RouteNameEnum.STUDENTS_FILTER, [{ name: 'filter', value: 'unenrolled' }]);
          }}
          data-testid="unenrolled-filter"
        >
          <Label
            extraClasses={`${state.activeTab === EnrollmentStatusEnum.UNENROLLED ? 'text-white' : 'text-blue-950'} font-bold`}
          >
            {t(capitalize(EnrollmentStatusEnum.UNENROLLED, ''))} {`(${state?.count[EnrollmentStatusEnum.UNENROLLED]})`}
          </Label>
        </a>
        <a
          className={`${state.activeTab === EnrollmentStatusEnum.PENDING ? styles.active : ''} ${styles.statusFilter}`}
          onClick={() => {
            setState((prev) => ({
              ...prev,
              filters: {
                ...prev.filters,
                enrollmentStatus: EnrollmentStatusEnum.PENDING,
              },
              activeTab: EnrollmentStatusEnum.PENDING,
            }));
            gotoRouteByName(RouteNameEnum.STUDENTS_FILTER, [{ name: 'filter', value: 'pending' }]);
          }}
          data-testid="pending-filter"
        >
          <Label
            extraClasses={`${state.activeTab === EnrollmentStatusEnum.PENDING ? 'text-white' : 'text-blue-950'} font-bold`}
          >
            {t('students.enrollmentPendingFilterLabel')} {`(${state?.count[EnrollmentStatusEnum.PENDING]})`}
          </Label>
        </a>
      </div>

      <div className="flex w-full max-w-screen-xl flex-wrap gap-3">
        <div className="md:flex-1/4 flex-1">
          <RoomPicker
            selection
            search
            clearable
            value={state.filters?.room || ''}
            placeholder={t('All Rooms')}
            onChange={(value) => {
              setState((prev) => ({
                ...prev,
                filters: { ...prev.filters, room: value },
              }));
            }}
            customOptions={[
              {
                key: 'no-room',
                text: t('No room'),
                value: 'no-room',
              },
            ]}
          />
        </div>

        <div className="md:flex-1/4 flex-1">
          <Dropdown
            className="w-full"
            placeholder={t('Age')}
            selection
            multiple
            clearable
            onChange={(_, { value }) => {
              setState((prev) => ({
                ...prev,
                filters: { ...prev.filters, age: value },
              }));
            }}
            options={[
              { key: '0', value: '0', text: '0-1' },
              { key: '1', value: '1', text: '1-2' },
              { key: '2', value: '2', text: '2-3' },
              { key: '3', value: '3', text: '3-4' },
              { key: '4', value: '4', text: '4-5' },
              { key: '5', value: '5', text: '5-6' },
              { key: '6', value: '6', text: '6-7' },
              { key: '7', value: '7', text: '7-8' },
              { key: '8', value: '8', text: '8-9' },
              { key: '9', value: '9', text: '9-10' },
              { key: '10', value: '10', text: '10-11' },
              { key: '11', value: '11', text: '11-12' },
              { key: '12', value: '12', text: '12+' },
            ]}
            data-testid="age-filter"
          />
        </div>

        <div className="md:flex-1/4 flex-1">
          <Dropdown
            className="w-full"
            placeholder={t('common.schedule')}
            multiple
            selection
            onChange={(_, { value }) => {
              setState((prev) => ({
                ...prev,
                filters: { ...prev.filters, schedule: value },
              }));
            }}
            options={[
              { key: 'M', value: 'M', text: t('Monday') },
              { key: 'T', value: 'T', text: t('Tuesday') },
              { key: 'W', value: 'W', text: t('Wednesday') },
              { key: 'Th', value: 'Th', text: t('Thursday') },
              { key: 'F', value: 'F', text: t('Friday') },
              { key: 'S', value: 'S', text: t('Saturday') },
              { key: 'Su', value: 'Su', text: t('Sunday') },
            ]}
            data-testid="schedule-filter"
          />
        </div>

        <div className="md:flex-1/4 flex-1">
          <Dropdown
            className="w-full"
            placeholder={t('Enrollment Source')}
            multiple
            selection
            onChange={(_, { value }) => {
              setState((prev) => ({
                ...prev,
                filters: { ...prev.filters, enrollmentSource: value },
              }));
            }}
            options={[
              { key: 'network', value: 'network', text: t('network.networkText') },
              { key: 'provider', value: 'provider', text: t('Provider') },
              { key: 'wonderschool', value: 'wonderschool', text: t('Wonderschool') },
            ]}
            data-testid="enrollment-source-filter"
          />
        </div>
        {(foodProgramFlag || isKinderSystemsEnabled) && (
          <div className="md:flex-1/4 flex-1">
            <Dropdown
              className="w-full"
              placeholder={t('Programs')}
              clearable
              selection
              onChange={(_, { value }) => {
                setState((prev) => ({
                  ...prev,
                  filters: { ...prev.filters, programs: value },
                }));
              }}
              options={selectProgramsOptions}
              data-testid="programs-source-filter"
            />
          </div>
        )}
      </div>

      <div>
        <Input
          className="mt-2 max-w-screen-xl"
          icon="search"
          iconPosition="left"
          fluid
          placeholder={t('Search Students / Contacts / Allergies')}
          onChange={(e) =>
            setState({
              ...state,
              searchText: e.currentTarget.value,
            })
          }
          data-testid="search-input-field"
        />
      </div>
      {studentArchiveFlag && (state.activeTab === EnrollmentStatusEnum.UNENROLLED || state.activeTab === 'all') && (
        <div>
          <Checkbox
            checked={!!state.filters.hideArchivedStudents}
            name="hideArchivedStudents"
            label={t('students.hideArchivedStudents')}
            onClick={(e, { checked }) => {
              setState((prev) => ({
                ...prev,
                filters: { ...prev.filters, hideArchivedStudents: checked },
              }));
            }}
            data-testid="hide-archived-students"
          />
        </div>
      )}
    </div>
  );
};

export default StudentsFilters;
