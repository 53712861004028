import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Card, Divider, Icon, Image, List } from 'semantic-ui-react';

import { calculateTotalFromInvoiceItems } from '../../../helpers/invoices';
import { formatCurrency } from '../../../helpers/utils';
import { useInvoiceEditItems } from '../../../hooks/useInvoiceEdit';

// Import style
import './Billing.scss';

export function StudentsListWidget({ students, onRemove, allowDelete = true }) {
  const { t } = useTranslation();
  if (!students?.length) return null;

  return (
    <Card fluid>
      <Card.Content className={'students-summary-card-header'}>
        <Card.Header data-testid="selected-employees-text" textAlign="center">
          {t('{{selectedStudentsCount}} students to be invoiced', {
            selectedStudentsCount: students.length,
          })}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List selection verticalAlign="middle" className={'students-summary-list'}>
          {students.map((student, _index) => (
            <List.Item key={student.id} data-testid={`selected-employee-${_index}`}>
              <Image avatar src={student.picture} data-testid={`selected-employee-avatar-${_index}`} />
              <List.Content data-testid={`selected-employee-name-${_index}`}>
                <List.Header>{student.displayName}</List.Header>
              </List.Content>
              {allowDelete && (
                <List.Content floated="right" data-testid={`selected-employee-remove-${_index}`}>
                  <Icon name="remove" onClick={() => onRemove(student)} />
                </List.Content>
              )}
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  );
}

export function InvoicePlanWidget({ invoicePlan }) {
  const { t } = useTranslation();

  return (
    <Card fluid className={'details-widget'}>
      <Card.Content className={'details-widget-header'}>
        <Card.Header textAlign="center" data-testid="invoice-plan-detail-title">
          {t('billing.invoiceDetail.title')}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List>
          <Divider />
          <List.Item>
            <List.Content>
              <List.Header>
                <div data-testid="invoice-plan-detail-total-label">{t('Total')}</div>
                <div data-testid="invoice-plan-detail-total-value">
                  {invoicePlan && formatCurrency(invoicePlan.total)}
                </div>
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
      </Card.Content>
    </Card>
  );
}
export function InvoiceItemsWidget() {
  const { t } = useTranslation();
  const items = useInvoiceEditItems();

  return (
    <Card fluid className={'details-widget'}>
      <Card.Content className={'details-widget-header'}>
        <Card.Header textAlign="center" data-testid="invoice-items-detail-title">
          {t('billing.invoiceDetail.title')}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List>
          {items?.map((item, _index) => (
            <List.Item key={item.id}>
              <List.Content>
                <List.Header>
                  <div data-testid={`invoice-item-${_index}`}>{t(item.category)}</div>
                  <div data-testid={`invoice-item-amount-${_index}`}>{formatCurrency(item.amount)}</div>
                </List.Header>
                {item.discounts?.map((discount, discIndex) => {
                  return (
                    <List.Description key={discount.id}>
                      <div data-testid={`invoice-item-discount-${_index}-${discIndex}`}>{t(discount.discountType)}</div>
                      <div data-testid={`invoice-item-discount-amount-${_index}-${discIndex}`}>
                        -{formatCurrency(discount.amount)}
                      </div>
                    </List.Description>
                  );
                })}
              </List.Content>
            </List.Item>
          ))}
          <Divider />
          <List.Item>
            <List.Content>
              <List.Header>
                <div data-testid="invoice-items-detail-total-label">{t('Total')}</div>
                <div data-testid="invoice-items-detail-total-value">
                  {formatCurrency(calculateTotalFromInvoiceItems(items))}
                </div>
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
      </Card.Content>
    </Card>
  );
}
