// eslint-disable-next-line no-restricted-imports
import { Segment } from 'semantic-ui-react';

const PageTitle = ({ count = null, title, RightComponent = null }) => {
  const counter = count ? `(${count})` : '';

  return (
    <Segment id="page-title" clearing basic className="no-padding">
      <h4 className="m-0 text-right text-xl">{RightComponent && <RightComponent />}</h4>
      <h1 className="text-left text-3xl font-bold" data-testid={`${title}-title`}>
        {`${title} ${counter}`}
      </h1>
    </Segment>
  );
};

export default PageTitle;
