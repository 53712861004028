import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TimecardStatusEnum } from './enums';
import { TimecardType, TimecardsListType } from './types';

type UseTimecardsType = {
  timecards: TimecardType[];
  clockedInTimecards: TimecardType[];
};

export function useTimecards(): UseTimecardsType {
  const timecardsList: TimecardsListType = useSelector((state: any) => state.timecards);

  const timecards = useMemo<TimecardType[]>(() => timecardsList?.list ?? [], [timecardsList]);

  const clockedInTimecards = useMemo<TimecardType[]>(
    () => timecards.filter((timecard) => timecard.status === TimecardStatusEnum.CLOCKED_IN),
    [timecards]
  );
  return {
    timecards,
    clockedInTimecards,
  };
}
