import { PERMISSION_CHANGED } from '../actions/types';

export type PermissionsReducerState = {
  [key: string]: boolean;
};

const initialState: PermissionsReducerState = {};

const permissions = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSION_CHANGED:
      return { ...action.permissions };
    default:
      return state;
  }
};

export default permissions;
