import { useEffect, useState } from 'react';

// components
import { BusinessCoachCard, CoachMessageType } from '@wonderschool/common-base-ui';
import DocumentUploader from '../../Components/Upload/DocumentUploader';
import FormsAndDocumentsActionButtons from './StaffActionButtons';
import FormsAndDocumentsTable from './StaffDocumentsTable';
import FileShareModal from './modals/FileShareModal';
import UploadProgressModal from './modals/UploadProgressModal';

// hooks
import { useDownloadFiles, useUploadFile } from '@wonderschool/file-service-client';
import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../hooks/useOrganizations';

// util stuff
import { FileCategory, FileDocument } from '@wonderschool/common-base-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { showErrorToast, showSuccessToast } from '../../Components/Shared/showToast';
import { logError } from '../../rollbar';
import { FILE_ACTIONS_CONSTS, UPLOAD_STATE_CONSTS, allowedMimeTypes } from '../dictionary';
import ParentHandbookModal from './modals/ParentHandbookModal';

// descriptive variable to mask passing a boolean argument to modal control function
const MODAL_STATE_OPEN = true;

const StaffMain: React.FC = () => {
  const { t } = useTranslation();
  const { uploadFile, error: uploadError, isSuccess: uploadSuccess, reset: resetUploadHook } = useUploadFile();
  const { downloadFiles, error: downloadError } = useDownloadFiles();
  const { id: currentOrganizationId } = useOrganization();

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [uploadState, setUploadState] = useState<UPLOAD_STATE_CONSTS | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<FileDocument[]>([]);
  const [openModalControl, setOpenModalControl] = useState<FILE_ACTIONS_CONSTS | null>(null);
  const [showParentHandbookModal, setShowParentHandbookModal] = useState(false);
  const { parentHandbook } = useFlags();

  const handleModalStates = (modalKey: FILE_ACTIONS_CONSTS, isOpen: boolean) => {
    setOpenModalControl(isOpen ? modalKey : null);
  };

  // upload stuff
  const handleFileSelectedForUpload = ({ data: file }: { data: File }) => {
    if (file) {
      setFileToUpload(file);
    }
  };

  const handleOpenParentHandbookModal = () => {
    setShowParentHandbookModal(true);
  };

  // Handle errors if download fails.
  useEffect(() => {
    if (!downloadError) return;

    // log it to rollbar
    logError('Documents - Download error', {
      status: downloadError?.status,
      message: downloadError?.message,
    });

    // show a toast
    const title = t('Error occurred while downloading file');
    const message = t('Unknown error occurred');
    showErrorToast(title, message);
  }, [downloadError, t]);

  // Handle errors if upload fails.
  useEffect(() => {
    if (!uploadError) return;

    // show the error modal state
    setUploadState(UPLOAD_STATE_CONSTS.error);
    setOpenModalControl(FILE_ACTIONS_CONSTS.upload);
    // log it with rollbar
    logError('Documents - Upload error: ', {
      serverMessage: uploadError,
    });
  }, [uploadError, setUploadState, setOpenModalControl]);

  // Handle upload success
  useEffect(() => {
    if (uploadSuccess && fileToUpload) {
      // show a toast
      const title = t('Upload success!');
      const message = t('{{fileName}} has been successfully uploaded.', {
        fileName: fileToUpload?.name,
      });
      showSuccessToast(title, message);
      // reset the file
      setFileToUpload(null);
      // reset the hook
      resetUploadHook();
    }
  }, [uploadSuccess, fileToUpload, resetUploadHook, setFileToUpload, t]);

  const handleFileSubmitted = async () => {
    if (!fileToUpload) {
      return null;
    }
    // set the modal content
    setUploadState(UPLOAD_STATE_CONSTS.uploading);
    // open progress modal
    handleModalStates(FILE_ACTIONS_CONSTS.upload, MODAL_STATE_OPEN);

    try {
      // start the request
      await uploadFile({
        file: fileToUpload,
        options: {
          fileAction: 'downloadable',
          fileCategory: FileCategory.FORM,
          metadata: {
            uploadPath: `/organizations/${currentOrganizationId}`,
          },
        },
      });

      // close the modal
      handleModalStates(FILE_ACTIONS_CONSTS.upload, !MODAL_STATE_OPEN);
    } catch (error) {
      // show the error modal state
      setUploadState(UPLOAD_STATE_CONSTS.error);
      // log it with rollbar
      logError('Documents - Upload error: ', {
        clientError: error,
        serverMessage: uploadError,
      });
    }

    return null;
  };

  // current docs stuff
  const handleSelectedDocuments = (selectedDocuments: FileDocument[]) => {
    setSelectedDocuments(selectedDocuments);
  };

  const handleFileActionClicked = (fileAction: FILE_ACTIONS_CONSTS) => {
    // Triggers file download.
    if (fileAction === FILE_ACTIONS_CONSTS.download) {
      downloadFiles(selectedDocuments.map((doc) => doc.id));
      return null;
    }

    handleModalStates(fileAction, true);
  };

  return (
    <>
      {/* modals */}
      <>
        {openModalControl === FILE_ACTIONS_CONSTS.share && (
          <FileShareModal
            isModalOpen
            selectedDocuments={selectedDocuments}
            closeModal={() => handleModalStates(FILE_ACTIONS_CONSTS.share, !MODAL_STATE_OPEN)}
          />
        )}
        {openModalControl === FILE_ACTIONS_CONSTS.upload && (
          <UploadProgressModal
            isModalOpen
            uploadState={uploadState}
            filename={fileToUpload?.name || ''}
            closeModal={() => handleModalStates(FILE_ACTIONS_CONSTS.upload, !MODAL_STATE_OPEN)}
          />
        )}
      </>
      <div className="flex flex-col space-y-4">
        {parentHandbook && (
          <>
            <BusinessCoachCard
              messageType={CoachMessageType.INFO}
              text={t('parentHandbook.ctaDescription')}
              name={t('parentHandbook.ctaTitle')}
              ctaText={t('parentHandbook.cta')}
              onCtaClick={handleOpenParentHandbookModal}
            />
            {showParentHandbookModal && (
              <ParentHandbookModal
                isModalOpen={showParentHandbookModal}
                closeModal={() => setShowParentHandbookModal(false)}
              />
            )}
          </>
        )}
        <div className="flex flex-col justify-between gap-y-4 md:flex-row">
          <p>{t('Upload, store and share documents with parents electronically here.')}</p>
          {/* UPLOADER */}
          {selectedDocuments.length === 0 && (
            <DocumentUploader
              floated="none"
              onFileSelected={handleFileSelectedForUpload}
              onSubmit={handleFileSubmitted}
              onError={() => null}
              shouldUseExternalStorageApi
              title={t('Uploading Files and Documents')}
              allowedFileTypes={allowedMimeTypes}
              maxNumberOfFiles={1}
            />
          )}
        </div>

        {/* forms/docs actions row */}
        <div className="flex flex-col">
          {/* SELECTED FILE ACTIONS */}
          {selectedDocuments.length > 0 && (
            <div data-testid="documents-action-btn-group" className="mt-4">
              <FormsAndDocumentsActionButtons
                areMultipleSelected={selectedDocuments.length > 1}
                handleFileActionClicked={handleFileActionClicked}
              />
            </div>
          )}
        </div>
        <div className="w-full">
          <FormsAndDocumentsTable onRowSelect={handleSelectedDocuments} />
        </div>
      </div>
    </>
  );
};

export default StaffMain;
