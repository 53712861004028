import { Button, DownloadIcon, UserGroupIcon } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';
import { FILE_ACTIONS_CONSTS } from '../dictionary';

interface IStaffActionButtonsProps {
  areMultipleSelected: boolean;
  handleFileActionClicked: (action: FILE_ACTIONS_CONSTS) => void;
}

const StaffActionButtons: React.FC<IStaffActionButtonsProps> = ({ handleFileActionClicked }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-4">
      <Button
        primary
        onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.download)}
        data-testid="download-action-btn"
      >
        <DownloadIcon className="size-5 pr-1" />
        {t('Download')}
      </Button>
      <Button onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.share)} data-testid="share-action-btn">
        <UserGroupIcon className="size-5 pr-1" />
        {t('common.share')}
      </Button>
    </div>
  );
};

export default StaffActionButtons;
