import { useCallback } from 'react';

import { useOrganization } from '../../hooks/useOrganizations';
import { useAuthUser } from '../../hooks/useUser';
import { callSegmentTrack } from '../api';

// This returns a function that can be used to track an event.
// The function takes an event name and an optional object of properties.
// Usage:
// const segmentTrack = useSegmentTrack();
// segmentTrack('eventName', { property: 'value' });

export default function useSegmentTrack() {
  const { currentUser } = useAuthUser();
  const organization = useOrganization();

  const segmentTrackFunction = useCallback(
    (eventName, properties = {}) => {
      const data = {
        event: eventName,
        properties: {
          ...properties,
          ...(currentUser?.uid && { userId: currentUser.uid }),
          ...(currentUser?.email && { email: currentUser.email }),
          ...(currentUser?.displayName && { displayName: currentUser.displayName }),
          ...(organization?.id && { organizationId: organization.id }),
          ...(organization?.name && { organizationName: organization.name }),
        },
      };

      callSegmentTrack(data);
    },
    [currentUser, organization]
  );

  return segmentTrackFunction;
}
