import { Dispatch } from 'redux';
import { SIDEBAR_TOGGLED, SLIDER_TOGGLED, SPACE_SECTION_TOGGLED, TIME_SECTION_TOGGLED } from './types';

export const toggleSlider = (sliderOpen: boolean) => ({
  type: SLIDER_TOGGLED,
  sliderOpen,
});

export const sidebarOpen = (isSidebarOpen: boolean) => ({
  type: SIDEBAR_TOGGLED,
  isSidebarOpen,
});

export const toggleTimeSection = (isTimeSectionActive: boolean) => ({
  type: TIME_SECTION_TOGGLED,
  isTimeSectionActive,
});

export const toggleSpaceSection = (isSpaceSectionActive: boolean) => ({
  type: SPACE_SECTION_TOGGLED,
  isSpaceSectionActive,
});

export const openSlider = (status: boolean) => (dispatch: Dispatch) => dispatch(toggleSlider(status));

export const openSidebar = (isSidebarOpen: boolean) => (dispatch: Dispatch) => dispatch(sidebarOpen(isSidebarOpen));
