import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Image, Segment } from 'semantic-ui-react';

import PageHeader from '../Components/Shared/PageHeader';
import { PAGE_NAMES, useSegmentPage } from '../segment';

export default function EnrolledFamilies() {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.enrolledFamilies);

  return (
    <>
      <Segment basic>
        <PageHeader pageName={PAGE_NAMES.enrolledFamilies} classes="enrolled-families" />
        <div className="sunny-container">
          <Image src="/images/sunny-mobile-phone.svg" />
          <h5>{t('Two way messaging with enrolled families')}</h5>
          <p>{t('is now available via the')}</p>
          <p>{t('Wonderschool mobile app.')}</p>
          <AppStoreBadges />
          <div className="grassy-hills">
            <Image src="/images/sunny-green-hills.svg" />
          </div>
        </div>
      </Segment>
    </>
  );
}

function AppStoreBadges() {
  return (
    <div className="app-store-badges">
      <div>
        <a href="https://apps.apple.com/us/app/wonderschool-2-0/id1560074981" target="_blank" rel="noreferrer">
          <Image style={{ height: '45px', width: 'auto' }} src="/images/download-apple-store-badge.svg" />
        </a>
      </div>
      <div>
        <a
          href="https://play.google.com/store/apps/details?id=com.moxitinc.wonderschool"
          target="_blank"
          rel="noreferrer"
        >
          <Image style={{ height: '45px', width: 'auto' }} src="/images/download-google-play-badge2.png" />
        </a>
      </div>
    </div>
  );
}
