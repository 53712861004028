import { FileDocument } from '@wonderschool/common-base-types';

export type FileDocumentFieldValue = FileDocument[keyof FileDocument];

export enum UPLOAD_STATE_CONSTS {
  uploading = 'uploadingInProgress',
  error = 'uploadingError',
  notUploading = 'notUploading',
}
export enum FILE_ACTIONS_CONSTS {
  share = 'share',
  rename = 'rename',
  archive = 'archive',
  download = 'download',
  upload = 'upload',
}

export type FILE_ACTION_CONST_VALUE = (typeof FILE_ACTIONS_CONSTS)[keyof typeof FILE_ACTIONS_CONSTS];

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// there are some additional image types, but i'm not sure whether to include to them.
export const allowedMimeTypes = [
  // word
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // excel
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // pdf
  'application/pdf',
  // images
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
  'image/heif',
  'text/csv',
];

export const documentTestIds = {
  docsTable: 'docs-table',
  studentDocList: {
    deletePopupText: 'document-student-list-delete-popup-txt',
    deleteBtnWrap: 'document-student-list-delete-btn-wrapper',
    btns: {
      delete: 'document-student-list-delete-btn',
    },
  },
  sharedForModal: {
    unsharedMsg: 'document-sharedFor-modal-msg',
    roomsList: 'document-sharedFor-modal-rooms-list',
    studentsList: 'document-sharedFor-modal-students-list',
    btns: {
      showAll: 'document-sharedFor-modal-show-btn',
      done: 'document-sharedFor-modal-done-btn',
    },
  },
  deleteModal: {
    header: 'document-delete-modal-title',
    content: {
      outer: 'document-delete-modal-text',
      inner: {
        standard: 'document-delete-modal-text-standard',
        room: 'document-delete-modal-text-room',
        roomAndStudent: 'document-delete-modal-text-room-and-student',
      },
    },
    legacyBtns: {
      no: 'no-btn',
      yes: 'delete-btn',
    },
    btns: {
      no: 'delete-document-no-btn',
      yes: 'delete-document-btn',
    },
  },
};
