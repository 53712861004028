import { Button } from '@wonderschool/common-base-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { networkOptions } from '../../config';
import { isLocal } from '../../config/env';
import { useNetworks, useNetworksDataToSeed } from '../networksHooks';
import NetworkDetails from './NetworkDetails';
import NetworksTable from './NetworksTable';

export function NetworksAdminContainer() {
  const { t } = useTranslation();
  const { networks, doFetchNetworks, isLoading } = useNetworks();

  const [setNetworksDataToSeed] = useNetworksDataToSeed();
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState();

  const onClose = useCallback(() => {
    setOpenDetails(false);
  }, []);

  const onSave = useCallback(() => {
    setSelectedNetwork(null);
    setOpenDetails(false);
    doFetchNetworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageHeader = t('network.pageTitle', {
    networkCount: networks?.length || 0,
  });

  return (
    <>
      <div className="flex justify-between bg-white p-4">
        <h1 className="text-2xl font-bold">{pageHeader}</h1>
        <div className="flex gap-2">{renderCommandSection()}</div>
      </div>
      <NetworksTable
        networks={networks}
        onSelect={(network) => {
          setSelectedNetwork(network);
          setOpenDetails(true);
        }}
      />

      {openDetails && <NetworkDetails network={selectedNetwork} onClose={onClose} onSave={onSave} open={openDetails} />}
    </>
  );

  function renderCommandSection() {
    return (
      <>
        {isLocal() && (
          <Button
            primary
            loading={isLoading}
            onClick={() => {
              setNetworksDataToSeed(networkOptions);
            }}
          >
            {t('network.seedNetworkData')}
          </Button>
        )}
        <Button
          primary
          loading={isLoading}
          onClick={() => {
            setOpenDetails(true);
            setSelectedNetwork(null);
          }}
        >
          {t('network.addNetwork')}
        </Button>
      </>
    );
  }
}
export default NetworksAdminContainer;
