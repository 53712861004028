import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Modal } from 'semantic-ui-react';

export default function DeleteContactConfirmationDialog({ isOpen, onYes, onClose }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  if (!onYes || !onClose) throw new Error('onYes and onClose are required');

  return (
    <Modal open={!!isOpen} onClose={onClose} size="tiny">
      <Modal.Header data-testid="delete-family-modal-title">{t('Delete Family Member')}</Modal.Header>
      <Modal.Content>
        <p>{t('Are you sure you want to delete this record? This action is not reversible.')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={isLoading}
          negative
          onClick={onClose}
          content={t('common.no')}
          data-testid="delete-contact-no-btn"
        />
        <Button
          loading={isLoading}
          positive
          icon="checkmark"
          labelPosition="right"
          content={t('common.yes')}
          onClick={onYesLocal}
          data-testid="delete-contact-btn"
        />
      </Modal.Actions>
    </Modal>
  );

  async function onYesLocal(e) {
    if (e) e.preventDefault();

    try {
      setIsLoading(true);
      await onYes();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
}
