// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { omit } from 'lodash';
import {
  DEVICES_FETCHED,
  DEVICE_REFRESHED,
  DEVICE_REMOVE_FROM_LIST,
  DEVICE_SELECTED,
  DEVICE_SELECTION_CLEARED,
} from '../actions/types';

export type DevicesReducerState = {
  selected?: {
    id?: string;
  };
  list?: {
    id?: string;
  }[];
  last?: {
    id?: string;
  };
  count?: number;
};
const initialState: DevicesReducerState = {
  selected: {},
  list: [],
  last: {},
  count: 0,
};

const devices = (state = initialState, action) => {
  switch (action.type) {
    case DEVICES_FETCHED: {
      const list = { ...state.list, ...action.devices.data };
      return {
        ...state,
        list,
        last: action.devices.lastDocument,
        count: Object.keys(list).length,
      };
    }
    case DEVICE_SELECTED:
      return { ...state, selected: action.device };

    case DEVICE_SELECTION_CLEARED:
      return { ...state, selected: {} };

    case DEVICE_REFRESHED: {
      const device = action.device;
      if (device && device.id && device.name)
        return {
          ...state,
          list: { ...state.list, [device.id]: device },
        };
      // No change return the previous state;
      return state;
    }

    case DEVICE_REMOVE_FROM_LIST: {
      return { ...state, list: omit(state.list, [action.id]) };
    }
    default:
      return state;
  }
};

export default devices;
