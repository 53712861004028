export default function NoDataIcon() {
  return (
    <svg width="116" height="98" viewBox="0 0 116 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="61.8827" cy="46.1556" r="33.8475" fill="#FFBD33" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.3983 60.0231C83.1068 53.009 83.6813 41.9303 77.3783 34.2279C70.5621 25.8985 58.2842 24.6717 49.9547 31.4879C41.6253 38.304 40.3985 50.5819 47.2147 58.9114C53.3678 66.4306 63.9717 68.1617 72.104 63.4132L84.3822 78.4174C85.3389 79.5864 87.0621 79.7586 88.2311 78.8019C89.4002 77.8453 89.5724 76.1221 88.6157 74.953L76.3983 60.0231Z"
        fill="#6876A6"
      />
      <circle cx="62.2965" cy="46.5697" r="15.3852" transform="rotate(-39.2941 62.2965 46.5697)" fill="#EAF5FE" />
      <path
        d="M74.5326 47.5231C74.5326 40.5367 68.869 34.873 61.8826 34.873"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M110.174 70.4789C110.278 70.6106 110.354 70.7618 110.399 70.9233C110.443 71.0849 110.455 71.2535 110.433 71.4192C110.41 71.5849 110.355 71.7442 110.27 71.8876C110.185 72.031 110.072 72.1556 109.938 72.2539C109.803 72.3523 109.65 72.4223 109.488 72.4599C109.325 72.4975 109.157 72.5019 108.992 72.4728C108.828 72.4436 108.67 72.3816 108.53 72.2904C108.389 72.1992 108.268 72.0807 108.174 71.9419L105.306 74.0401C105.67 74.5774 106.137 75.0364 106.681 75.3896C107.225 75.7428 107.833 75.9831 108.47 76.096C109.107 76.2089 109.759 76.1922 110.388 76.0468C111.017 75.9014 111.609 75.6303 112.129 75.2497C112.649 74.8692 113.087 74.3869 113.416 73.8319C113.745 73.2768 113.958 72.6602 114.044 72.0191C114.129 71.3779 114.084 70.7252 113.912 70.1001C113.74 69.475 113.445 68.8902 113.043 68.3807L110.174 70.4789Z"
        fill="#F5841F"
      />
      <path
        d="M44.0844 0C44.0942 0.274744 44.0489 0.548661 43.9513 0.805396C43.8536 1.06213 43.7055 1.29642 43.5159 1.49428C43.3263 1.69214 43.0991 1.84951 42.8477 1.95699C42.5964 2.06447 42.3261 2.11987 42.053 2.11987C41.7799 2.11987 41.5096 2.06447 41.2583 1.95699C41.0069 1.84951 40.7797 1.69214 40.5901 1.49428C40.4005 1.29642 40.2524 1.06213 40.1547 0.805396C40.0571 0.548661 40.0118 0.274744 40.0216 0H34.1947C34.1561 1.06322 34.3308 2.12336 34.7083 3.11708C35.0859 4.11081 35.6585 5.01772 36.392 5.78364C37.1256 6.54956 38.0049 7.15878 38.9776 7.57488C39.9502 7.99098 40.9962 8.20544 42.053 8.20544C43.1098 8.20544 44.1558 7.99098 45.1285 7.57488C46.1011 7.15878 46.9804 6.54956 47.714 5.78364C48.4475 5.01772 49.0201 4.11081 49.3977 3.11708C49.7752 2.12336 49.9499 1.06322 49.9113 0H44.0844Z"
        fill="#86CDAD"
      />
      <path d="M98.2692 5.67503L91.8322 0L86.1571 6.43706L92.5942 12.1121L98.2692 5.67503Z" fill="#1E8BCC" />
      <path d="M22.3663 70.7861L18.8378 67.6753L15.727 71.2038L19.2555 74.3146L22.3663 70.7861Z" fill="#F5841F" />
      <path d="M3.19374 56.2981L15.4913 49.9346L12.2975 43.7624L-0.000119913 50.126L3.19374 56.2981Z" fill="#FEBE19" />
      <path d="M82.7382 94.4648L91.1162 97.5054L92.6423 93.3005L84.2643 90.2599L82.7382 94.4648Z" fill="#1E8BCC" />
      <path
        d="M106.671 53.3354C108.937 53.3354 110.773 51.6516 110.773 49.5745C110.773 47.4975 108.937 45.8137 106.671 45.8137C104.405 45.8137 102.568 47.4975 102.568 49.5745C102.568 51.6516 104.405 53.3354 106.671 53.3354Z"
        fill="#F69689"
      />
      <path
        d="M21.8658 25.135C23.4444 24.2292 23.9897 22.2151 23.0838 20.6366C22.178 19.058 20.1639 18.5127 18.5854 19.4186C17.0068 20.3245 16.4615 22.3385 17.3674 23.917C18.2733 25.4956 20.2873 26.0409 21.8658 25.135Z"
        fill="#EF4539"
      />
      <path
        d="M50.965 94.4447C53.1688 93.18 53.9302 90.3682 52.6655 88.1644C51.4008 85.9606 48.589 85.1993 46.3852 86.464C44.1813 87.7287 43.42 90.5405 44.6847 92.7443C45.9494 94.9481 48.7612 95.7094 50.965 94.4447Z"
        fill="#86CDAD"
      />
    </svg>
  );
}
