import { useTranslation } from 'react-i18next';
import './FinancialSummary.scss';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Header } from 'semantic-ui-react';
import { usePrimaryLocation } from '../../../../hooks/useLocations';
import FinancialSummaryOverviewCard from './FinancialSummaryOverviewCard';
import { getStartEndMonths } from './FinancialSummaryUtils';
import { ReportTitleEnum } from './enums';
import { FinancialSummaryOverviewProps } from './types';

const FinancialSummaryOverview: React.FC<FinancialSummaryOverviewProps> = ({
  financialSummary,
  filters,
  loading = false,
}) => {
  const { t } = useTranslation();
  const location = usePrimaryLocation();
  const [revenueTotal, setRevenueTotal] = useState<string | number>(0);
  const [expenseTotal, setExpenseTotal] = useState<string | number>(0);
  const [totalProfit, setTotalProfit] = useState<number>(0);
  useEffect(() => {
    if (financialSummary?.length > 0) {
      const revenueTotalRow = financialSummary.find((row) => row.title === ReportTitleEnum.TOTAL_REVENUE) || {
        total: 0,
      };
      setRevenueTotal(revenueTotalRow?.total || 0);
      const expenseTotalRow = financialSummary.find((row) => row.title === ReportTitleEnum.TOTAL_EXPENSE) || {
        total: 0,
      };
      setExpenseTotal(expenseTotalRow?.total || 0);
      setTotalProfit(Number(revenueTotalRow?.total) - Number(expenseTotalRow?.total));
    } else {
      setRevenueTotal(0);
      setExpenseTotal(0);
      setTotalProfit(0);
    }
  }, [financialSummary]);
  const { start, end } = getStartEndMonths(filters?.selectedMonths, filters?.selectedYear, location?.timezone);
  return (
    <>
      <div className="items-top filter-selection flex h-full w-full flex-row gap-5">
        {filters && filters.searchConditions && (
          <>
            <div>
              <Header as="h3" floated="left" data-testid={'summary-range'}>
                {moment(start).format('MMMM D')}-{moment(end).format('MMMM D, YYYY')}
              </Header>
            </div>
          </>
        )}
      </div>
      <div className="items-top mb-4 mt-2 flex h-full w-full flex-row gap-5">
        {financialSummary?.length > 0 && !loading && (
          <div>
            <Header as="h4" floated="left" data-testid={'summary-title'}>
              {t('financialSummary.overview.title')}
            </Header>
          </div>
        )}
      </div>
      {financialSummary?.length > 0 && (
        <div className="items-top mt-2 flex h-full w-full flex-col gap-5">
          <div className="fs-summary">
            <FinancialSummaryOverviewCard
              amount={Number(revenueTotal)}
              label={ReportTitleEnum.TOTAL_REVENUE}
            ></FinancialSummaryOverviewCard>
            <FinancialSummaryOverviewCard
              amount={Number(expenseTotal)}
              label={ReportTitleEnum.TOTAL_EXPENSE}
            ></FinancialSummaryOverviewCard>
            <FinancialSummaryOverviewCard
              amount={Number(totalProfit)}
              label={ReportTitleEnum.TOTAL_PROFIT}
            ></FinancialSummaryOverviewCard>
          </div>
          <div
            data-testid="fs-summary-description"
            className="fs-summary-description"
            dangerouslySetInnerHTML={{ __html: t('financialSummary.overview.description') }}
          />
        </div>
      )}
    </>
  );
};
export default FinancialSummaryOverview;
