import { useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { List, Menu, Modal } from 'semantic-ui-react';

import { formatFullName } from '../../helpers/utils';

import styles from './CheckedInStudentsModal.module.scss';

const LIST_TYPE = {
  students: 'Students',
  staff: 'Staff',
};
export function CheckedInStudentsModal({ roomName, students, staff, onClose, open }) {
  const { t } = useTranslation();
  const [listType, setListType] = useState(LIST_TYPE.students);

  return (
    <Modal onClose={onClose} centered={false} open={open} closeIcon size="small" closeOnDimmerClick={true}>
      <Modal.Header>{roomName}</Modal.Header>
      <Modal.Content>
        <Menu stackable compact>
          <Menu.Item onClick={() => setListType(LIST_TYPE.students)} active={listType === LIST_TYPE.students}>
            {t('Students')}
          </Menu.Item>
          <Menu.Item onClick={() => setListType(LIST_TYPE.staff)} active={listType === LIST_TYPE.staff}>
            {t('Staff')}
          </Menu.Item>
        </Menu>
        {listType === LIST_TYPE.students && (
          <List selection verticalAlign="middle">
            {students.map((student, i) => (
              <List.Item key={student?.id ?? i}>
                <Avatar
                  name={formatFullName(student, true)}
                  round
                  size={28}
                  alt={formatFullName(student, true)}
                  src={student.picture}
                  className={styles.avatar}
                />
                <List.Content className={styles.listContent}>
                  <List.Header>{formatFullName(student, true)}</List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
        )}
        {listType === LIST_TYPE.staff && (
          <List selection verticalAlign="middle">
            {staff.map((person, i) => (
              <List.Item key={person?.id ?? i}>
                <Avatar
                  name={formatFullName(person, true)}
                  round
                  size={28}
                  alt={formatFullName(person, true)}
                  src={person.photoURL}
                  className={styles.avatar}
                />
                <List.Content className={styles.listContent}>
                  <List.Header>{formatFullName(person, true)}</List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default CheckedInStudentsModal;
