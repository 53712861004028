import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Form, Header, Input, Item, Segment, Select, Table } from 'semantic-ui-react';

// API
import { getUserProfiles } from '../../../api/firebase/users';

// Import components
import UsersDetail from './UsersDetail';

// Import utils
import { formatFullName } from '../../../helpers/utils';
import UserManage from './UserManage';

const options = [
  { key: 'uid', text: 'User ID', value: 'uid' },
  { key: 'displayName', text: 'Full Name', value: 'displayName' },
  { key: 'email', text: 'Email', value: 'email' },
  { key: 'organizationId', text: 'Organization ID', value: 'organizationId' },
];

const UsersTableFilter = ({ getData }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState({
    field: 'email',
    operation: '==',
    value: '',
  });

  const onChange = (e, { name, value }) => setSearch({ ...search, [name]: value });
  const handleSubmit = () => getData([search]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          type="text"
          placeholder={t('Search...')}
          name="value"
          value={search?.value}
          onChange={onChange}
        />
        <Form.Field control={Select} options={options} name="field" value={search?.field} onChange={onChange} />
        <Form.Field control={Button} type="submit" primary content={t('Search')} />
      </Form.Group>
    </Form>
  );
};

const UsersListContainer = () => {
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (conditions = []) => {
    try {
      const u = await getUserProfiles({
        conditions,
        limit: 50,
      });

      const { data } = u;

      setUsers(Object.keys(data).map((key) => data[key]));
    } catch (error) {
      console.error(error);
    }
  };

  const renderPageTitle = () => {
    if (users) {
      return (
        <Segment basic clearing>
          <Header
            as="h1"
            content={t('Users ({{userCount}})', {
              userCount: users.length || 0,
            })}
          />
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left" content={t('Users')} />
      </Segment>
    );
  };

  const renderModal = () => {
    return (
      <UsersDetail
        user={selectedUser}
        onClose={() => {
          setSelectedUser(null);
        }}
      />
    );
  };

  const renderTableFilter = () => <UsersTableFilter getData={getData} />;

  const renderUsersTable = () => {
    const row =
      users &&
      users.map((user, key) => {
        return (
          <Table.Row key={key}>
            <Table.Cell>
              <Item>
                <Item.Content>
                  <Item.Header>
                    <a
                      href={`#${user.id}`}
                      onClick={(e) => {
                        e.preventDefault();

                        setSelectedUser(user);
                      }}
                      data-testid="impersonate-org-name"
                    >
                      <strong>{user.displayName ?? formatFullName(user, true)}</strong>
                    </a>
                  </Item.Header>
                  <Item.Extra style={{ fontSize: '13px', color: '#999' }}>{user.uid ?? user.id}</Item.Extra>
                </Item.Content>
              </Item>
            </Table.Cell>
            <Table.Cell>{user.email ?? ''}</Table.Cell>
            <Table.Cell>{user.defaultOrganization ?? ''}</Table.Cell>
            <Table.Cell>
              <UserManage user={user} />
            </Table.Cell>
          </Table.Row>
        );
      });

    return (
      <>
        {users && (
          <Table stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4} computer={4}>
                  {t('Full Name')}
                </Table.HeaderCell>
                <Table.HeaderCell>{t('common.email')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Organization ID')}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>{row}</Table.Body>
          </Table>
        )}
      </>
    );
  };

  return (
    <>
      <div>{renderPageTitle()}</div>
      <div>{renderTableFilter()}</div>
      <div>{renderUsersTable()}</div>
      {!!selectedUser && renderModal()}
    </>
  );
};

export default UsersListContainer;
