import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Label } from 'semantic-ui-react';
import './Steps.scss';

const StepInner = ({ step, isActive, label }) => (
  <>
    <Label data-testid={`step-number-${step}`} circular color={isActive ? 'blue' : 'grey'}>
      {step}
    </Label>
    <div className={isActive ? 'active' : ''} data-testid={`step-label-${step}`}>
      {label}
    </div>
  </>
);

const Step = (props) => {
  return (
    <>
      {props.disabled ? (
        <div className={'step'}>
          <StepInner {...props} />
        </div>
      ) : (
        <Link to={props.link} className={'step'}>
          <StepInner {...props} />
        </Link>
      )}
    </>
  );
};

Step.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string,
  link: PropTypes.string,
  step: PropTypes.number,
};

export default Step;
