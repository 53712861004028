import { FileCategory, OrganizationProgramTypeEnum, USStateCodeEnum } from '@wonderschool/common-base-types';
import {
  Button,
  Dialog,
  InfoIcon,
  Option,
  USStateSelect,
  UploadIcon,
  WidgetSizeEnum,
} from '@wonderschool/common-base-ui';
import { FC, useState } from 'react';
import { Controller, Form, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { allowedMimeTypes } from '../../../documents/dictionary';
import FileCategorySelect from './FileCategorySelect';
import OrganizationProgramTypeSelect from './OrganizationProgramTypeSelect';

export type AdminDocumentFormData = {
  file: File;
  category: FileCategory;
  states: USStateCodeEnum[];
  programTypes: OrganizationProgramTypeEnum[];
};

export const UploadAdminDocument: FC<{ onUploadFile: (data: AdminDocumentFormData) => void }> = ({ onUploadFile }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const formMethods = useForm<AdminDocumentFormData>({
    defaultValues: {
      states: [],
      programTypes: [],
    },
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = formMethods;

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeModalAndResetForm = () => {
    setIsOpen(false);
    formMethods.reset();
  };

  const onSubmit: SubmitHandler<AdminDocumentFormData> = (data) => {
    onUploadFile(data);
    closeModalAndResetForm();
  };

  return (
    <div className="flex justify-end">
      <Dialog
        isOpen={isOpen}
        hasCloseButton
        onClose={closeModalAndResetForm}
        data-testid="upload-admin-document-modal"
        extraClasses="md:w-1/2 md:max-w-1/2"
      >
        <Dialog.Title>
          <h1 className="border-b-2 border-b-gray-200 px-6 py-5 text-xl" data-testid="upload-admin-doc-title">
            {t('common.uploadFile')}
          </h1>
        </Dialog.Title>
        <Dialog.Panel>
          <FormProvider {...formMethods}>
            <Form id="upload-form" control={control} onInvalid={(errors) => console.error(errors)}>
              <Controller
                name="file"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, ...fieldProps } }) => (
                  <input
                    {...fieldProps}
                    type="file"
                    id="file"
                    required
                    accept={allowedMimeTypes.join(',')}
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      fieldProps.onChange(file);
                    }}
                  />
                )}
              />
              <Controller
                name="category"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <FileCategorySelect
                    labelClasses="mt-6"
                    label={t('common.fileCategory')}
                    placeholder={t('common.selectFileCategory')}
                    required
                    onChange={(newValue: Option) => {
                      field.onChange(newValue.value as FileCategory);
                    }}
                  />
                )}
              />
              <Controller
                name="states"
                control={control}
                render={({ field }) => (
                  <USStateSelect
                    labelClasses="mt-6"
                    multiple
                    onChange={(newValue: Option[]) => {
                      field.onChange(newValue.map((option) => option.value as USStateCodeEnum));
                    }}
                  />
                )}
              />
              <Controller
                name="programTypes"
                control={control}
                render={({ field }) => (
                  <OrganizationProgramTypeSelect
                    labelClasses="mt-6"
                    multiple
                    onChange={(newValue: Option[]) => {
                      field.onChange(newValue.map((option) => option.value as OrganizationProgramTypeEnum));
                    }}
                  />
                )}
              />
              <div className="my-6 flex flex-row">
                <InfoIcon className="mr-1 hidden h-5 text-gray-600 md:block" />
                <span className="text-sm text-gray-600">{t('admin.wonderschoolDocuments.allTagsDisclaimer')}</span>
              </div>
            </Form>
          </FormProvider>
        </Dialog.Panel>
        <Dialog.Actions>
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
            <Button label={t('common.cancel')} extraClasses="w-full sm:w-auto " onClick={closeModalAndResetForm} />
            <Button label={t('Upload')} onClick={handleSubmit(onSubmit)} primary disabled={!isValid || !isDirty} />
          </div>
        </Dialog.Actions>
      </Dialog>
      <Button
        label={t('Upload')}
        onClick={openDialog}
        primary
        size={WidgetSizeEnum.SMALL}
        postIcon={<UploadIcon className="h-5 w-5" />}
      />
    </div>
  );
};

export default UploadAdminDocument;
