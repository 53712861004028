import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../helpers/utils';
import fStyle from './FinanceAtGlance.module.scss';
import FinanceBox from './FinanceBox';

const fx = classNames.bind(fStyle);
function TransactionSection({ revenue, expenses, title }) {
  const { t } = useTranslation();
  return (
    <>
      {title && <div className={fx('textFontSize', 'mt-10')}>{t(title)}</div>}
      <div className={fx('mt-1')}>
        <div className={fx('financeAtGlance')}>
          <FinanceBox boxType="revenue" amount={formatCurrency(revenue)} label={t('Revenue')} />
          <FinanceBox boxType="expenses" amount={formatCurrency(expenses)} label={t('Expenses')} />
        </div>
      </div>
    </>
  );
}

export default TransactionSection;
