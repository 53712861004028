import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// Import component
import { MainContentLayout } from '@wonderschool/common-base-ui';
import AccountProfile from '../Components/Account/Account';
import { getTitlePrefix } from '../config/env';
import { PAGE_NAMES, useSegmentPage } from '../segment';

const Profile = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.profile);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Account Profile') })}
      </title>
      <body className="profile" />
    </Helmet>
  );

  const renderPageTitle = () => (
    <div className="flex w-full items-center justify-between border-gray-300 py-4">
      <h1 className="text-left text-2xl font-bold">{t('Account Profile')}</h1>
    </div>
  );

  return (
    <MainContentLayout>
      {renderPageHead()}

      {renderPageTitle()}
      <AccountProfile />
    </MainContentLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(Profile);
