import { FileCategory } from '@wonderschool/common-base-types';
import {
  MultiSelectProps,
  Option,
  Select,
  SingleSelectProps,
  isMultiSelect,
  isSingleSelect,
} from '@wonderschool/common-base-ui';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type FileCategorySelectProps = Omit<MultiSelectProps | SingleSelectProps, 'options'>;

export const FileCategorySelect: FC<FileCategorySelectProps> = (selectProps) => {
  const { t } = useTranslation();

  const fileCategoryOptions: Option[] = useMemo(
    () => [
      ...Object.values(FileCategory).map((category) => ({
        label: t(`common.fileCategory.${category}`),
        value: category,
      })),
    ],
    [t]
  );

  if (isMultiSelect(selectProps)) {
    return (
      <Select
        {...selectProps}
        label={t('common.fileCategory', { count: 100 })}
        placeholder={t('common.all')}
        options={fileCategoryOptions}
        multiple
      />
    );
  }

  if (isSingleSelect(selectProps)) {
    return (
      <Select
        {...selectProps}
        label={t('common.fileCategory', { count: 100 })}
        placeholder={t('common.all')}
        options={fileCategoryOptions}
      />
    );
  }

  return null;
};

export default FileCategorySelect;
