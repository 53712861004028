/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useRef, useState } from 'react';
import migrationData from './migration_data.json';

interface TimelineEntry {
  date: string;
  js_count: number;
  ts_count: number;
  js_percentage: number;
  ts_percentage: number;
}

interface AuthorEntry {
  author: string;
  ts_file_count: number;
}

export const MigrationReport: React.FC = () => {
  const [timelineData, setTimelineData] = useState<TimelineEntry[]>([]);
  const [authorsData, setAuthorsData] = useState<AuthorEntry[]>([]);
  const chartRef = useRef<HTMLCanvasElement>(null);

  // Dynamically load the Chart.js script from a CDN
  // don't want to add as a dependency
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/chart.js';
    script.async = true;
    script.onload = () => {
      // Once Chart.js is loaded, initialize the chart
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          new (window as any).Chart(ctx, {
            type: 'line',
            data: {
              labels: timelineData.map((entry) => entry.date), // Dates for the x-axis
              datasets: [
                {
                  label: 'JS %',
                  data: timelineData.map((entry) => entry.js_percentage), // JS percentages for the y-axis
                  borderColor: 'rgba(255, 99, 132, 1)', // Red color
                  backgroundColor: 'rgba(255, 99, 132, 0.2)', // Translucent red fill
                  fill: true,
                },
                {
                  label: 'TS %',
                  data: timelineData.map((entry) => entry.ts_percentage), // TS percentages for the y-axis
                  borderColor: 'rgba(54, 162, 235, 1)', // Blue color
                  backgroundColor: 'rgba(54, 162, 235, 0.2)', // Translucent blue fill
                  fill: true,
                },
              ],
            },
            options: {
              responsive: true,
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Date',
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'Percentage',
                  },
                  min: 0,
                  max: 100,
                },
              },
            },
          });
        }
      }
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup script if necessary
      document.body.removeChild(script);
    };
  }, [timelineData]);

  useEffect(() => {
    if (migrationData) {
      // Sort timeline data by date in descending order (most recent first)
      const sortedTimeline = migrationData.timeline;

      // Sort authors by TS file count in descending order
      const sortedAuthors = [...migrationData.authors].sort((a, b) => b.ts_file_count - a.ts_file_count);

      setTimelineData(sortedTimeline);
      setAuthorsData(sortedAuthors);
    }
  }, []);

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="mb-8">
        <h2 className="mb-4 text-xl font-semibold">JS and TS Percentage Over Time</h2>
        <canvas ref={chartRef}></canvas>
      </div>
      <h2 className="mb-4 text-xl font-semibold">Timeline of JS/TS File Counts</h2>
      <table className="min-w-full border-collapse border border-gray-200 bg-white">
        <thead>
          <tr>
            <th className="border border-gray-200 px-4 py-2">Date</th>
            <th className="border border-gray-200 px-4 py-2">JS File Count</th>
            <th className="border border-gray-200 px-4 py-2">TS File Count</th>
            <th className="border border-gray-200 px-4 py-2">JS %</th>
            <th className="border border-gray-200 px-4 py-2">TS %</th>
          </tr>
        </thead>
        <tbody>
          {timelineData.map((entry, index) => (
            <tr key={index}>
              <td className="border border-gray-200 px-4 py-2">{entry.date}</td>
              <td className="border border-gray-200 px-4 py-2">{entry.js_count}</td>
              <td className="border border-gray-200 px-4 py-2">{entry.ts_count}</td>
              <td className="border border-gray-200 px-4 py-2">{entry.js_percentage}%</td>
              <td className="border border-gray-200 px-4 py-2">{entry.ts_percentage}%</td>
            </tr>
          ))}
        </tbody>
      </table>
      <h2 className="mb-4 mt-8 text-xl font-semibold">TS/TSX Files Authored by Each Author (Latest Commit)</h2>
      <p>This is simply the original author of the file, so not a good reference for actual js to ts</p>
      <table className="min-w-full border-collapse border border-gray-200 bg-white">
        <thead>
          <tr>
            <th className="border border-gray-200 px-4 py-2">Author</th>
            <th className="border border-gray-200 px-4 py-2">TS/TSX File Count</th>
          </tr>
        </thead>
        <tbody>
          {authorsData.map((authorEntry, index) => (
            <tr key={index}>
              <td className="border border-gray-200 px-4 py-2">{authorEntry.author}</td>
              <td className="border border-gray-200 px-4 py-2">{authorEntry.ts_file_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
