import { Input, RadioButtonGroup } from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, GenderEnum } from '../../common';
import { capitalize } from '../../helpers/utils';
import { StudentType } from '../types';

type StudentEditFormProps = FormComponentProps<StudentType> & {
  student?: StudentType;
};

function StudentEditForm({ title, hasTitle = true, isSaving, onSave, student: studentParam }: StudentEditFormProps) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<StudentType>({
    defaultValues: studentParam || ({} as StudentType),
  });

  const viewTitle = hasTitle ? t(title || 'students.studentInformationFormTitle') : '';

  const onSubmit = useCallback(
    (data) => {
      const saveData = {
        ...data,
        gender: data.gender?.value,
      };
      if (onSave) {
        onSave({ data: saveData });
      }
    },
    [onSave]
  );

  useEffect(() => {
    if (studentParam) {
      reset(studentParam);
    }
  }, [studentParam, reset]);

  useEffect(() => {
    if (isSaving) {
      handleSubmit(onSubmit)();
    }
  }, [isSaving, handleSubmit, onSubmit]);

  const genderItems = [
    { value: GenderEnum.FEMALE, label: t(capitalize(GenderEnum.FEMALE)), name: GenderEnum.FEMALE },
    { value: GenderEnum.MALE, label: t(capitalize(GenderEnum.MALE)), name: GenderEnum.MALE },
    { value: GenderEnum.OTHER, label: t(capitalize(GenderEnum.OTHER)), name: GenderEnum.OTHER },
  ];

  return (
    <div className="new-student-form" data-testid="new-student-form">
      {!!viewTitle && (
        <h2 className="mb-4 text-2xl font-bold" data-testid="new-student-form-title">
          {viewTitle}
        </h2>
      )}
      <div className="-mx-2 flex flex-wrap">
        <div className="mb-4 w-full px-2 md:w-1/2">
          <div className={`${errors.firstName ? 'border-red-500' : ''}`} data-testid="error-firstname">
            <Controller
              name="firstName"
              control={control}
              rules={{ required: t('common.firstNameRequired') }}
              render={({ field, formState: { errors } }) => (
                <Input
                  {...field}
                  required
                  type="text"
                  placeholder={t('common.firstName')}
                  label={t('common.firstName')}
                  data-testid="first-name"
                  className="w-full rounded border p-2"
                  error={errors.firstName?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="mb-4 w-full px-2 md:w-1/2">
          <div className={`${errors.lastName ? 'border-red-500' : ''}`} data-testid="error-lastname">
            <Controller
              name="lastName"
              control={control}
              rules={{ required: t('common.lastNameRequired') }}
              render={({ field, formState: { errors } }) => (
                <Input
                  {...field}
                  required
                  type="text"
                  placeholder={t('common.lastName')}
                  label={t('common.lastName')}
                  data-testid="last-name"
                  className="w-full rounded border p-2"
                  error={errors.lastName?.message}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label className="mb-2 block">{t('Gender')}</label>
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <RadioButtonGroup
              {...field}
              value={genderItems.find((item) => item.value === field.value)}
              data-testid="checkbox-gender"
              items={genderItems}
              className="flex space-x-4"
            />
          )}
        />
      </div>
      <div className="mb-4 w-full md:w-1/2">
        <div className={`${errors.birthday ? 'border-red-500' : ''}`} data-testid="birthday-field">
          <Controller
            name="birthday"
            rules={{
              required: t('enrollments.errors.birthdateRequired'),
              validate: {
                maxDate: (date) => dayjs(date).isBefore(dayjs('2100-12-31')) || t('enrollments.errors.maxDate'),
              },
            }}
            control={control}
            render={({ field, formState: { errors } }) => (
              <Input
                {...field}
                required
                value={field.value ?? ''}
                id="birthday"
                label={t('Birthday')}
                type="date"
                inputType="date"
                error={errors.birthday?.message}
                max="2100-12-31"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default StudentEditForm;
