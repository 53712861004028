import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Container, Segment } from 'semantic-ui-react';

import LocationsList from '../Components/Locations/LocationsList';
import { getTitlePrefix } from '../config/env';
import { PAGE_NAMES, useSegmentPage } from '../segment';

const Locations = (_props) => {
  const { t } = useTranslation();

  useSegmentPage(PAGE_NAMES.locations);
  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Locations') })}
      </title>
      <body className="locations" />
    </Helmet>
  );

  return (
    <Container fluid className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        <LocationsList />
      </Segment>
    </Container>
  );
};

export default Locations;
