import { getDocument } from './firestore';

const paths = {
  student: (organizationId, studentId) => `organizations/${organizationId}/students/${studentId}`,
  students: (organizationId) => `organizations/${organizationId}/students`,
  rootStudent: (studentId) => `students/${studentId}`,
};

export const getStudentById = async (studentId, organizationId: string = '') => {
  try {
    if (!studentId) {
      throw new Error('Student ID is required to fetch Student');
    }
    const path = organizationId ? paths.student(organizationId, studentId) : paths.rootStudent(studentId);
    const student = await getDocument({ path });
    return student;
  } catch (error: any) {
    console.error(error);
    throw new Error(error?.message || 'Error in while fetching Student.');
  }
};
