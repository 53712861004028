// returns an array of activities
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { getDocumentsAsArray } from '../api/firebase/firestore';
import { DefaultDateFormat, formatStringAsLocaleMillisOrNull } from '../helpers/dates';
import { logError } from '../rollbar';

import { chunkArray } from '../helpers/utils';
import { ActivitiesFiltersType, ActivityType, QueryConditionType } from './types';

const paths = {
  activities: (organizationId: string) => `organizations/${organizationId}/activities`,
};
export async function fetchActivities(filters: ActivitiesFiltersType): Promise<ActivityType[]> {
  const activityTypes = Array.isArray(filters.activityTypes) ? filters.activityTypes : [filters.activityTypes];

  const conditions: QueryConditionType[] = [
    {
      field: 'type',
      operation: 'in',
      value: activityTypes,
    },
    {
      field: 'activityTime',
      operation: '>=',
      value: formatStringAsLocaleMillisOrNull(moment(filters.startDate).startOf('day')),
    },
    {
      field: 'activityTime',
      operation: '<=',
      value: moment(filters.endDate, DefaultDateFormat).isSame(moment(new Date(), DefaultDateFormat))
        ? formatStringAsLocaleMillisOrNull(moment(new Date()))
        : formatStringAsLocaleMillisOrNull(moment(filters.endDate).endOf('day')),
    },
  ];

  if (filters.roomId) {
    conditions.push({
      field: 'room',
      operation: '==',
      value: filters.roomId,
    });
  }

  if (filters.studentId) {
    conditions.push({
      field: 'student.id',
      operation: '==',
      value: filters.studentId,
    });
  }
  try {
    if (!filters.studentId && filters?.kinderSystemsStudents?.length) {
      const studentsChunkedList = chunkArray(filters.kinderSystemsStudents, 10);
      const activitiesList: ActivityType[] = [];
      for (const studentsChunk of studentsChunkedList) {
        const conditionsChunk: QueryConditionType[] = [...conditions];
        conditionsChunk.push({
          field: 'student.id',
          operation: 'in',
          value: studentsChunk,
        });
        const activities = await getDocumentsAsArray({
          path: paths.activities(filters.organizationId),
          conditions: conditionsChunk,
          orderBy: [
            { field: 'activityTime', direction: 'desc' },
            { field: 'addedBy.createdAt', direction: 'desc' },
          ],
        });
        activitiesList.push(...activities.list);
      }
      return activitiesList;
    }
    const activities = await getDocumentsAsArray({
      path: paths.activities(filters.organizationId),
      conditions,
      orderBy: [
        { field: 'activityTime', direction: 'desc' },
        { field: 'addedBy.createdAt', direction: 'desc' },
      ],
    });
    return activities.list ?? [];
  } catch (error) {
    logError(error);
    throw error;
  }
}
