import { SystemNotificationsPage } from '@wonderschool/ccms-ui';
import {
  Button,
  ColumnConfig,
  DataTable,
  MainContentLayout,
  showToast,
  Spinner,
  ToastTypeEnum,
} from '@wonderschool/common-base-ui';
import {
  CreateSystemNotificationInput,
  removeTypenameFromQueryData,
  SystemNotification,
  SystemNotificationType,
  UpdateSystemNotificationInput,
  useSystemNotificationCreateMutation,
  useSystemNotificationsLazyQuery,
  useSystemNotificationUpdateMutation,
} from '@wonderschool/common-food-program-data-access';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

/**
 * Form for creating a new system notification
 */
function CreateNotificationForm({
  onSubmit,
  loading,
}: {
  onSubmit: (data: CreateSystemNotificationInput) => void;
  loading: boolean;
}) {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm<CreateSystemNotificationInput>();

  const onFormSubmit = (data: CreateSystemNotificationInput) => {
    onSubmit(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="mb-4 flex max-w-[300px] flex-col gap-y-4">
      <label>{t('systemNotifications.type')}</label>
      <select {...register('type')} required>
        {Object.values(SystemNotificationType).map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
      <label>{t('systemNotifications.startDate')}</label>
      <input type="date" {...register('startDate')} placeholder="Start Date" />
      <label>{t('systemNotifications.endDate')}</label>
      <input type="date" {...register('endDate')} placeholder="End Date" />
      <div className="flex items-center gap-x-4">
        <input type="checkbox" {...register('active')} defaultChecked={true} />
        <label>{t('systemNotifications.active')}</label>
        <input type="checkbox" {...register('dismissible')} defaultChecked={true} />
        <label>{t('systemNotifications.dismissible')}</label>
      </div>
      <Button disabled={loading} type="submit">
        {t('systemNotifications.createNotification')}
      </Button>
    </form>
  );
}

export function SystemNotifications() {
  return <SystemNotificationsPage />;
}

export function SystemNotificationsOld() {
  const { t } = useTranslation();
  const [systemNotifications, setSystemNotifications] = useState<SystemNotification[]>([]);

  const [systemNotificationsQuery, { loading, called, refetch }] = useSystemNotificationsLazyQuery({
    onError: (error) => {
      showToast(ToastTypeEnum.Error, error.message);
    },
  });

  const [systemNotificationCreateMutation, { loading: createLoading }] = useSystemNotificationCreateMutation({
    onError: (error) => {
      showToast(ToastTypeEnum.Error, error.message);
    },
  });

  const [systemNotificationUpdateMutation] = useSystemNotificationUpdateMutation({
    onError: (error) => {
      showToast(ToastTypeEnum.Error, error.message);
    },
  });

  const refreshNotifications = useCallback(async () => {
    const resp = await refetch();

    setSystemNotifications(removeTypenameFromQueryData(resp.data.systemNotifications));
  }, [refetch]);

  const handleCreateSystemNotification = useCallback(
    async (input: CreateSystemNotificationInput) => {
      await systemNotificationCreateMutation({
        variables: {
          input: {
            ...input,
            startDate: input.startDate ? new Date(input.startDate) : undefined,
            endDate: input.endDate ? new Date(input.endDate) : undefined,
          },
        },
        onCompleted: async () => {
          refreshNotifications();
        },
      });
    },
    [refreshNotifications, systemNotificationCreateMutation]
  );

  const handleUpdateSystemNotification = useCallback(
    async (input: UpdateSystemNotificationInput) => {
      await systemNotificationUpdateMutation({
        variables: { input },
        onCompleted: async () => {
          refreshNotifications();
        },
      });
    },
    [refreshNotifications, systemNotificationUpdateMutation]
  );

  useEffect(() => {
    if (called) {
      return;
    }

    systemNotificationsQuery({
      variables: {
        args: {},
      },
      onCompleted: (data) => {
        setSystemNotifications(removeTypenameFromQueryData(data.systemNotifications));
      },
    });
  }, [called, systemNotificationsQuery]);

  const renderDate = (date?: Date | null) => {
    return date ? new Date(date).toLocaleDateString() : '';
  };

  const renderBoolean = (value: boolean) => {
    return value ? 'Yes' : 'No';
  };

  const renderType = (type: SystemNotificationType) => {
    return type;
  };

  const columns: ColumnConfig<SystemNotification>[] = useMemo(
    () => [
      {
        label: 'id',
        fieldName: 'id',
        renderCell: (row) => row.id,
      },
      {
        label: t('systemNotifications.type'),
        fieldName: 'type',
        renderCell: (row) => renderType(row.type),
      },
      {
        label: t('systemNotifications.active'),
        fieldName: 'active',
        renderCell: (row) => renderBoolean(row.active ?? false),
      },
      {
        label: t('systemNotifications.dismissible'),
        fieldName: 'dismissable',
        renderCell: (row) => renderBoolean(row.dismissible ?? false),
      },
      {
        label: t('systemNotifications.startDate'),
        fieldName: 'startDate',
        renderCell: (row) => renderDate(row.startDate),
      },
      {
        label: t('systemNotifications.endDate'),
        fieldName: 'endDate',
        renderCell: (row) => renderDate(row.endDate),
      },
      {
        label: t('systemNotifications.firstSeenAt'),
        fieldName: 'firstSeenAt',
        renderCell: (row) => renderDate(row.firstSeenAt),
      },
      {
        label: t('systemNotifications.lastSeenAt'),
        fieldName: 'lastSeenAt',
        renderCell: (row) => renderDate(row.lastSeenAt),
      },
      {
        label: t('systemNotifications.actions'),
        fieldName: 'actions',
        renderCell: (row) => (
          <div>
            <Button
              onClick={() =>
                handleUpdateSystemNotification({
                  id: row.id,
                  active: !row.active,
                  dismissible: row.dismissible,
                  type: row.type,
                  firstSeenAt: row.firstSeenAt,
                  lastSeenAt: row.lastSeenAt,
                })
              }
            >
              {row.active ? 'Deactivate' : 'Activate'}
            </Button>
          </div>
        ),
      },
    ],
    [handleUpdateSystemNotification, t]
  );

  return (
    <MainContentLayout title="System Notifications">
      {loading ? <Spinner /> : <DataTable data={systemNotifications} columns={columns} />}
      <CreateNotificationForm onSubmit={handleCreateSystemNotification} loading={createLoading} />
    </MainContentLayout>
  );
}
