import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useCallback, useMemo, useState } from 'react';
import Avatar from 'react-avatar';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Grid, Header, Modal, Segment } from 'semantic-ui-react';

import { getScheduledDaysAbbreviated } from '../../enrollments';
import { EnrollmentStatusEnum } from '../../enrollments/enums';
import { dateFormatter, getAgeFromBirthday, toDateObject } from '../../helpers/dates';
import { capitalize, formatFullName } from '../../helpers/utils';
import { useOrganization, useOrganizationContactsListener } from '../../hooks/useOrganizations';
import useRooms from '../../hooks/useRooms';
import { useUser } from '../../hooks/useUser';
import { WsCheckSuccessCircle, WsCrossCircle, WsPending } from '../../icons';
import { RouteNameEnum, useRoutes } from '../../navigation';

import ShowSuccess from '../../Components/Messages/ShowSuccess';
import NoResults from '../../Components/Shared/NoResults';
import withPermission from '../../Components/Shared/withPermission';
import withSlidingPanel from '../../Components/Shared/withSlidingPanel';
import wsLogo from '../../styles/img/wonderschool/logo-base.png';
import StudentDetail from './StudentDetail';

import StudentsFilters from './StudentsFilters';

import { useAddedStudent, useStudents } from '../studentsHooks';
import { studentAddCleared, studentSelected, studentSelectionCleared } from '../studentsRedux';
import { getEnrollmentStatus, isStudentArchived } from '../studentsUtils';

import { Button, DataTable, exportDataTable, ReportToolbar } from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import StudentDetailProviderApi from './studentPersonalInformation/StudentDetailProviderApi';
import styles from './students.module.scss';

const SLIDER_WIDTH = '85%';

const SlidingStudentDetailProviderApi = withSlidingPanel(StudentDetailProviderApi, {
  width: SLIDER_WIDTH,
});

const SlidingStudentDetail = withSlidingPanel(StudentDetail, {
  width: SLIDER_WIDTH,
});

const ImportButton = () => {
  const { gotoRouteByName } = useRoutes();

  const { t } = useTranslation();
  return (
    <Button
      primary
      onClick={() => {
        gotoRouteByName(RouteNameEnum.STUDENTS_IMPORT);
      }}
      data-testid="import-students-btn"
    >
      {t('Import students')}
    </Button>
  );
};

const AddStudentButton = () => {
  const { t } = useTranslation();
  const { gotoRouteByName } = useRoutes();

  return (
    <Button
      primary
      onClick={() => {
        gotoRouteByName(RouteNameEnum.STUDENT_ADD);
      }}
      data-testid="add-student-btn"
    >
      {t('Add student')}
    </Button>
  );
};
const ImportStudentsWithPermission = withPermission(ImportButton, 'can_edit_import');
const AddStudentWithPermission = withPermission(AddStudentButton, 'can_create_student');

const PageTitle = ({ state }) => {
  const { t } = useTranslation();
  const { list: students } = useStudents(state);

  if (!students?.length && !state.filters) {
    return (
      <Segment basic clearing>
        <Header as="h1" floated="left" content={t('Students')} data-testid="students-title" />
      </Segment>
    );
  }

  return (
    <Segment basic clearing className={'w-full'}>
      <div className={styles.studentPageTitleWrap}>
        <div>
          <h1 className="text-3xl font-bold" data-testid="students-header">
            {t('Students')}
          </h1>
        </div>

        <div className="flex w-full flex-col gap-2 pt-2 sm:flex-row md:w-auto">
          <ImportStudentsWithPermission />
          <AddStudentWithPermission />
        </div>
      </div>
    </Segment>
  );
};

const ShowRecordsCount = ({ state }) => {
  const { t } = useTranslation();
  return (
    <>
      {!(state?.activeTab === 'all') ? (
        <div className={styles.showCount} data-testid="count-wrapper">
          {`${t('Showing')}
          ${t(state?.count?.[state?.activeTab])}
          ${t('of')} ${state?.count.all} ${t(capitalize(state?.activeTab)).toLowerCase()}
           ${t('Students').toLowerCase()}`}
        </div>
      ) : null}
    </>
  );
};

const StudentsTableContainer = ({ students, state, setState }) => {
  const { t } = useTranslation();
  const currentOrganization = useOrganization();

  const getAgeText = useCallback(
    (birthday) => {
      const age = getAgeFromBirthday(birthday);
      if (!age) return '';
      // this covers babies with an assigned birthday that are less than 1 month old
      if (age.years === 0 && age.months === 0 && birthday) {
        return `< 1 ${t('mon')}`;
      }
      const yearSuffix = age.years && age.years > 1 ? t('yrs') : t('yr');
      const monthSuffix = t('mon');
      const { months, years } = age;
      // x months && 0 years
      if (months && !years) {
        return `${months} ${monthSuffix}`;
      }
      // x years && 0 months
      if (years && !months) {
        return `${years} ${yearSuffix}`;
      }
      // everything else
      return `${years} ${yearSuffix} ${months} ${monthSuffix}`;
    },
    [t]
  );

  const { isOrganizationAdmin } = useUser();
  // we need both staff and family contacts to be able to sort by contacts
  useOrganizationContactsListener(
    currentOrganization.id,
    isOrganizationAdmin
      ? []
      : [
          {
            field: 'type',
            operation: '==',
            value: 'family',
          },
        ],
    [isOrganizationAdmin]
  );

  const rooms = useRooms();
  const dispatch = useDispatch();

  const dispatchStudentSelected = (studentId) => {
    const student = students.find((student) => student.id === studentId);

    dispatch(studentSelected(student));
    setState((prev) => ({ ...prev, isEditOpen: true }));
  };

  const [shouldOpenPdfModal, setShouldOpenPdfModal] = useState(false);

  const localeCompareSortHelper = (a, b, order) => {
    if (!b) {
      return -1;
    }
    if (!a) {
      return 1;
    }
    return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
  };

  const studentsPrimitive = JSON.stringify(students);
  const roomsPrimitive = JSON.stringify(rooms);

  const csvColumns = useMemo(
    () => [
      {
        key: 'displayName',
        header: t('common.name'),
      },
      {
        key: 'rooms',
        header: t('Room'),
      },
      {
        key: 'enrollmentStatus',
        header: t('common.status'),
      },
      {
        key: 'scheduleMonday',
        header: t('common.schedule'),
      },
      {
        key: 'scheduleTuesday',
        header: '',
      },
      {
        key: 'scheduleWednesday',
        header: '',
      },
      {
        key: 'scheduleThursday',
        header: '',
      },
      {
        key: 'scheduleFriday',
        header: '',
      },
      {
        key: 'scheduleSaturday',
        header: '',
      },
      {
        key: 'scheduleSunday',
        header: '',
      },
      {
        key: 'age',
        header: t('Age'),
      },
      {
        key: 'birthday',
        header: t('Birthday'),
      },
      {
        key: 'allergies',
        header: t('Allergies'),
      },
      {
        key: 'combinedFamily',
        header: t('Contacts'),
      },
    ],
    [t]
  );

  const csvData = useMemo(
    () =>
      studentsPrimitive && roomsPrimitive
        ? students.map((student) => ({
            displayName: student.displayName,
            rooms: rooms.find((room) => room.id === student.rooms?.[0])?.name || '',
            enrollmentStatus: capitalize(getEnrollmentStatus(student)),
            scheduleMonday: student.schedule?.includes('M') ? 'M' : '',
            scheduleTuesday: student.schedule?.includes('T') ? 'T' : '',
            scheduleWednesday: student.schedule?.includes('W') ? 'W' : '',
            scheduleThursday: student.schedule?.includes('Th') ? 'Th' : '',
            scheduleFriday: student.schedule?.includes('F') ? 'F' : '',
            scheduleSaturday: student.schedule?.includes('S') ? 'S' : '',
            scheduleSunday: student.schedule?.includes('Su') ? 'Su' : '',
            age: getAgeText(student.birthday),
            birthday: student.birthday ? dateFormatter(student.birthday, 'MM/DD/YYYY') : '',
            allergies: student.allergies || '',
            combinedFamily: Object.values(student.combinedFamily)
              .map((contact) => (contact.displayName ? contact.displayName : formatFullName(contact, true)))
              .join(', '),
          }))
        : [],
    [studentsPrimitive, roomsPrimitive, rooms, students, getAgeText]
  );

  const tableColumns = useMemo(
    () => [
      {
        fieldName: 'picture',
        renderCell: (student) => {
          return <Avatar round size={44} src={student.picture} />;
        },
      },
      {
        fieldName: 'displayName',
        label: t('common.name'),
        sortBy: 'CLIENT',
        sortFunction: (a, b, order) => {
          return localeCompareSortHelper(a.displayName, b.displayName, order);
        },
        renderCell: (student) => {
          return !isStudentArchived(student) ? (
            student.displayName
          ) : (
            <div>
              <div>{student.displayName}</div>
              <div className={styles.studentArchived}>{t('Archived')}</div>
            </div>
          );
        },
      },
      {
        fieldName: 'rooms',
        label: t('Room'),
        sortBy: 'CLIENT',
        sortFunction: (a, b, order) => {
          const roomA = rooms?.find((room) => a?.rooms.indexOf(room.id) > -1);
          const roomB = rooms?.find((room) => b?.rooms.indexOf(room.id) > -1);
          return localeCompareSortHelper(roomA?.name, roomB?.name, order);
        },
        renderCell: (student) => rooms.find((room) => room.id === student.rooms?.[0])?.name || '',
      },
      {
        fieldName: 'enrollmentStatus',
        label: t('common.status'),
        renderCell: (student) => {
          const status = getEnrollmentStatus(student);
          if (status === EnrollmentStatusEnum.ENROLLED) {
            return (
              <span className={`${styles.successTag} ${styles.tag}`}>
                <WsCheckSuccessCircle />
                {t('Enrolled')}
              </span>
            );
          } else if (status === EnrollmentStatusEnum.UNENROLLED) {
            return (
              <span className={`${styles.errorTag} ${styles.tag}`}>
                <WsCrossCircle />
                {t('Unenrolled')}
              </span>
            );
          } else if (status === EnrollmentStatusEnum.PENDING) {
            return (
              <span className={`${styles.pendingTag} ${styles.tag}`}>
                <WsPending />
                {t('Pending')}
              </span>
            );
          }
        },
      },
      {
        fieldName: 'schedule',
        label: t('common.schedule'),
        renderCell: (student) => {
          return (
            <div>
              {getScheduledDaysAbbreviated(student)?.join('') || (
                <span className={styles.studentDataPlaceholder}>-</span>
              )}
            </div>
          );
        },
      },
      {
        fieldName: 'birthday',
        label: t('Age'),
        sortBy: 'CLIENT',
        sortFunction: (a, b) => {
          const dateA = toDateObject(a.birthday)?.valueOf();
          const dateB = toDateObject(b.birthday)?.valueOf();

          if (!dateA && !dateB) return 0;
          if (!dateB) return 1;
          if (!dateA) return -1;

          return dateB - dateA;
        },
        renderCell: (student) => {
          // returns null or object of { years: x, months: x }
          const birthday = toDateObject(student.birthday);
          const formattedBirthday =
            !birthday || birthday.getTime() > Date.now() ? '' : ` (${dateFormatter(student.birthday, 'MM/DD/YYYY')})`;
          return `${getAgeText(birthday)}${formattedBirthday}`;
        },
      },
      {
        fieldName: 'allergies',
        label: t('Allergies'),
        renderCell: (student) => {
          return student.allergies ? student.allergies : <div className={styles.studentDataPlaceholder}>-</div>;
        },
      },
      {
        fieldName: 'combinedFamily',
        label: t('Contacts'),
        renderCell: (student) => {
          if (!student.combinedFamily || !Object.keys(student)?.length) {
            return <div className={styles.studentDataPlaceholder}>-</div>;
          }
          return Object.values(student.combinedFamily).map((contact, i) => (
            <div key={i} className={styles.studentContactCellDiv}>
              {contact?.displayName ? contact?.displayName : formatFullName(contact, true)}
            </div>
          ));
        },
      },
    ],
    [rooms, getAgeText, t]
  );

  //PDF Generation

  const calculateAge = (timestamp) => {
    // returns null or object of { years: x, months: x }
    const birthday = toDateObject(timestamp);
    const age = getAgeFromBirthday(birthday);
    if (!age) return '';
    // this covers babies with an assigned birthday that are less than 1 month old
    if (age.years === 0 && age.months === 0 && timestamp) {
      return `< 1 ${t('mon')}`;
    }
    const yearSuffix = age.years && age.years > 1 ? t('yrs') : t('yr');
    const monthSuffix = t('mon');
    const { months, years } = age;
    // x months && 0 years
    if (months && !years) {
      return `${months} ${monthSuffix}`;
    }
    // x years && 0 months
    if (years && !months) {
      return `${years} ${yearSuffix}`;
    }
    // everything else
    return `${years} ${yearSuffix} ${months} ${monthSuffix}`;
  };

  const formatData = async (datas) => {
    const formattedData = [];
    for (const data of datas) {
      const doc = [
        data?.displayName,
        rooms.find((room) => room.id === data?.rooms[0])?.name ?? '',
        capitalize(getEnrollmentStatus(data)),
        data?.schedule?.includes('M') ? 'M' : '',
        data?.schedule?.includes('T') ? 'T' : '',
        data?.schedule?.includes('W') ? 'W' : '',
        data?.schedule?.includes('Th') ? 'Th' : '',
        data?.schedule?.includes('F') ? 'F' : '',
        data?.schedule?.includes('S') ? 'S' : '',
        data?.schedule?.includes('Su') ? 'Su' : '',
        data?.birthday ? calculateAge(data.birthday) : '',
        data?.birthday ? `${dateFormatter(data.birthday, 'MM/DD/YYYY')}` : '',
        data?.allergies ? data.allergies : '',
        Object.values(data.combinedFamily).length > 0
          ? Object.values(data.combinedFamily).map((contact) =>
              contact?.displayName ? contact?.displayName : formatFullName(contact, true)
            )
          : '',
      ];
      formattedData.push(doc);
    }
    return formattedData;
  };

  const exportPdf = async () => {
    setShouldOpenPdfModal(true);
    const FILENAME = `Students.pdf`;
    const TITLE = `Students`;

    // Landscape export, 2×4 inches
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    const PDF_COLUMNS_HEADER = [
      { content: 'Name', dataKey: 'displayName' },
      { content: 'Room', dataKey: 'rooms' },
      { content: 'Status', dataKey: 'enrollmentStatus' },
      { content: 'Schedule', dataKey: 'schedule', colSpan: 7, styles: { halign: 'center' } },
      { content: 'Age', dataKey: 'age' },
      { content: 'Birthday', dataKey: 'dob' },
      { content: 'Allergies', dataKey: 'allergies' },
      { content: 'Contacts', dataKey: 'combinedFamily' },
    ];

    const BODY_DATA = await formatData(students);

    doc.addImage(wsLogo, 'PNG', 10, 20, 40, 5);
    doc.setFontSize(14);
    doc.setFont(undefined, 'normal', 'bold');
    doc.text(TITLE, 10, 36);
    autoTable(doc, {
      columnStyles: {
        1: { cellWidth: 20 },
      },
      head: [PDF_COLUMNS_HEADER],
      body: BODY_DATA,
      startY: 45,
      headStyles: { fillColor: [41, 128, 185] },
      alternateRowStyles: { fillColor: [245] },
      theme: 'grid',
    });

    setShouldOpenPdfModal(false);
    doc.setProperties({
      title: FILENAME,
    });
    doc.autoPrint();
    doc.output('dataurlnewwindow', { FILENAME });
    doc.save(FILENAME);
  };

  return (
    <Segment basic clearing className="bootstrap-iso no-top-margin no-top-padding" data-testid="students-table">
      <Grid stackable>
        <Grid.Row className="no-bottom-padding ml-0">
          <Grid.Column computer={8} mobile={16} tablet={16} textAlign="left">
            <ShowRecordsCount state={state} />
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={16} textAlign="right">
            <ReportToolbar
              onExportPDF={exportPdf}
              onExportCSV={() => {
                exportDataTable(
                  csvData,
                  {
                    header: true,
                    columns: csvColumns,
                  },
                  `Students${dayjs().format('MM-DD-YYYY')}.csv`
                );
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <DataTable
            hasDataCountTitle={false}
            data={students}
            columns={tableColumns}
            noResults={
              <tr>
                <td colSpan={99} className="pb-4 text-center">
                  <NoResults
                    title={t('studentList.emptyTitle')}
                    subTitle={t('studentList.emptySubTitle')}
                    data-testid="no-students"
                  />
                </td>
              </tr>
            }
            onClickRow={(row) => dispatchStudentSelected(row.id)}
          />
        </Grid.Column>
      </Grid>
      <Modal
        onClose={() => setShouldOpenPdfModal(false)}
        open={shouldOpenPdfModal}
        closeIcon
        size="small"
        closeOnDimmerClick={false}
        data-testid="pdf-modal"
      >
        <Modal.Content className="pdf-message-modal">
          <Header size="large" data-testid="print-pdf-modal-title">
            {t('Print PDF')}
          </Header>
          <p>{t('We are generating a report. This might take a few seconds. Please wait...')}</p>
        </Modal.Content>
      </Modal>
    </Segment>
  );
};

const StudentAddedBannerWrapper = ({ setState }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const newStudentAdded = useAddedStudent();

  if (!newStudentAdded?.id) return null;

  const { displayName } = newStudentAdded;

  const dispatchSelectedStudent = () => {
    dispatch({ type: 'STUDENT_SELECTED', student: newStudentAdded });
    setState((prev) => ({ ...prev, isEditOpen: true }));
  };

  const onCloseBanner = () => {
    dispatch(studentAddCleared());
  };

  return (
    <Segment basic clearing className={styles.studentAddedBannerWrap}>
      <ShowSuccess
        onDismiss={onCloseBanner}
        hideHeader
        content={
          <div>
            <Trans i18nKey="You have added {{displayName}} as a new student." displayName={displayName}>
              You have added <strong>{{ displayName }}</strong> as a new student.
            </Trans>
            &nbsp;
            <span className="pseudo-link" onClick={dispatchSelectedStudent}>
              {t('View student info')}
            </span>
          </div>
        }
      />
    </Segment>
  );
};

const SlidingForms = ({ state, setState }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedStudent } = useStudents(state);

  const { core411ShowProviderApiStudentEditSlide } = useFlags();

  console.log('core411ShowProviderApiStudentEditSlide', core411ShowProviderApiStudentEditSlide);

  let studentName = selectedStudent?.displayName
    ? selectedStudent.displayName
    : `${selectedStudent?.firstName} ${selectedStudent?.lastName}`;

  // Concatenate nickname if it exists.
  if (selectedStudent?.nickName?.length) studentName += ` (${selectedStudent?.nickName})`;
  return (
    <div>
      {core411ShowProviderApiStudentEditSlide ? (
        <SlidingStudentDetailProviderApi
          title={studentName}
          subTitle={isStudentArchived(selectedStudent) ? '(' + t('Archived') + ')' : ''}
          image={selectedStudent?.picture}
          isOpen={state.isEditOpen}
          onClose={() => {
            dispatch(studentSelectionCleared());
            setState((prev) => ({ ...prev, isEditOpen: false }));
          }}
        />
      ) : (
        <SlidingStudentDetail
          title={studentName}
          subTitle={isStudentArchived(selectedStudent) ? '(' + t('Archived') + ')' : ''}
          image={selectedStudent?.picture}
          isOpen={state.isEditOpen}
          onClose={() => {
            dispatch(studentSelectionCleared());
            setState((prev) => ({ ...prev, isEditOpen: false }));
          }}
        />
      )}
    </div>
  );
};

const StudentsTable = ({ filter }) => {
  const enrollmentStatus =
    filter === 'all' ? '' : ['enrolled', 'unenrolled', 'pending'].includes(filter) ? filter : 'enrolled';

  const [state, setState] = useState({
    activeTab: ['all', 'enrolled', 'unenrolled', 'pending'].includes(filter) ? filter : 'enrolled',
    isAddOpen: false,
    isEditOpen: false,
    searchText: '',
    filters: {
      room: '',
      enrollmentStatus: enrollmentStatus,
      age: '',
      schedule: [],
      allergies: '',
      enrollmentSource: '',
      hideArchivedStudents: true,
      programs: '',
    },
    newStudentId: null,
    count: {
      all: 0,
      enrolled: 0,
      unenrolled: 0,
      pending: 0,
    },
  });
  const { listFiltered } = useStudents(state);

  return (
    <>
      <PageTitle state={state} setState={setState} />
      <StudentAddedBannerWrapper setState={setState} />
      <Segment basic clearing>
        <Grid columns={1} stackable>
          <Grid.Column tablet={16} mobile={16} computer={16}>
            <StudentsFilters state={state} setState={setState} />
          </Grid.Column>
        </Grid>
      </Segment>
      <StudentsTableContainer state={state} students={listFiltered} setState={setState} />
      <SlidingForms state={state} setState={setState} />
    </>
  );
};

export default StudentsTable;
