import { Trans, useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Icon, Modal } from 'semantic-ui-react';
import { WsSuccess } from '../../../icons';
import { RouteNameEnum, useRoutes } from '../../../navigation';

import { ModalProps } from '../../../common';

type StudentAddedModalProps = ModalProps & {
  student: any;
};

export default function EnrollmentSentModal({ student, isOpen, onClose }: StudentAddedModalProps) {
  const { t } = useTranslation();
  const { gotoRouteByName } = useRoutes();

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose} closeOnDimmerClick={false} closeOnEscape={false}>
      <Modal.Header>
        <div className="ws-icon-header" data-testid="enrollment-sent-modal-title">
          <WsSuccess className="success-icon" />
          {t('enrollments.enrollmentSentModalTitle')}
        </div>
      </Modal.Header>
      <Modal.Content>
        <Trans
          i18nKey="enrollments.enrollmentSentModalContent"
          values={{ studentName: student?.displayName ?? '' }}
          components={{ strong: <strong /> }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t('enrollments.enrollmentSentModalNoButton')}
          onClick={() => {
            if (onClose) onClose();
            gotoRouteByName(RouteNameEnum.STUDENTS);
          }}
          data-testid="enrollment-sent-no-btn"
        />
        <Button
          primary
          onClick={() => {
            if (onClose) onClose();
            gotoRouteByName(RouteNameEnum.INVOICES);
          }}
          data-testid="create-invoice-btn"
        >
          {t('enrollments.enrollmentSentModalYesButton')}
          <Icon name="arrow alternate circle right outline" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
