import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Form, Modal } from 'semantic-ui-react';

import { useNetworks } from '../../networks';

import InlineError from '../Messages/InlineError';
import ShowError from '../Messages/ShowError';
import { Dropdown } from '../Shared/Dropdown';

import GeoDataEditForm from '../../networks/components/GeoDataEditForm';

export default function NetworkEditModal({ organization, location, network, isOpen, onClose, onSave }) {
  const { networks, isLoading } = useNetworks();
  const networkOptions = networks?.map((network) => ({
    key: network.id,
    text: network.name,
    value: network.id,
  }));
  const { t } = useTranslation();
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [geoData, setGeoData] = useState(network?.geoData ?? []);
  const [errors, setErrors] = useState({});

  if (!organization || !location) return null;

  if (!!network?.id && network.id !== selectedNetwork?.id) {
    setSelectedNetwork(network);
    setGeoData(network?.geoData ?? []);
    return null;
  }

  const headerText = network?.id ? t('Edit Network for') : t('Add Network for');

  return (
    <Modal onClose={onCloseLocal} open={isOpen} closeIcon size="small" closeOnDimmerClick={false}>
      <Modal.Header>{`${headerText} ${location.name}`}</Modal.Header>
      <Modal.Content>
        <ShowError errors={errors} />

        <Form onSubmit={onSubmit} loading={isLoading}>
          <Form.Field error={!!errors.networks}>
            <label className="required">{t('Networks')}</label>
            <Dropdown
              required
              disabled={!!network?.id}
              name={'networks'}
              value={selectedNetwork?.id ?? ''}
              placeholder={t('Select Network')}
              label={t('Networks')}
              onChange={onChangeNetwork}
              options={networkOptions ?? []}
              error={!!errors.networks}
            />
            {errors.networks && <InlineError text={errors.networks} />}
          </Form.Field>

          <GeoDataEditForm geoData={geoData} onChange={onGeoDataChange} onDelete={onGeoDataDelete} />
          <Form.Field>
            <Button primary disabled={false} content={t('Save')} />
            <Button basic content={t('common.cancel')} onClick={onCloseLocal} />
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  );

  function onChangeNetwork(e, { value }) {
    const _network = networks?.find((n) => n.id === value);
    if (_network) {
      setSelectedNetwork(_network);
      setGeoData(_network.geoData ?? []);
    } else {
      setSelectedNetwork(null);
      setGeoData([]);
    }
  }

  function onGeoDataChange(index, value) {
    setGeoData(geoData.map((data, i) => (index === i ? value : data)));
  }

  function onGeoDataDelete(index) {
    setGeoData(geoData.filter((data, i) => index !== i));
  }

  function onSubmit(e) {
    e.preventDefault();
    const network = networks?.find((n) => n.id === selectedNetwork?.id);

    const locationNetwork = {
      id: network.id,
      name: network.name,
      geoData: geoData,
    };
    clearState();
    if (onSave) onSave(locationNetwork);
  }

  function onCloseLocal(e) {
    e.preventDefault();
    clearState();
    if (onClose) onClose(e);
  }
  function clearState() {
    setSelectedNetwork(null);
    setGeoData([]);
    setErrors({});
  }
}
